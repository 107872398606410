<ion-content>
  <ion-row>
    <ion-col size="12">
      <div class="check-container">
        <fa-icon class="check-icon" [icon]="faCheckCircle"></fa-icon>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <div>
        <p class="title">Thank you for creating your AMO account!</p>
      </div>
      <div class="text-content">
        <p class="card-text">
          A member of our team will be in touch soon. In the meantime, if you would like to complete your profile to better guide the conversation with your AMO
          team member, <ion-text class="settings-link" (click)="relyDismiss()">Click Here</ion-text>
        </p>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
