import { createSelector } from '@ngrx/store';
import { State } from '../reducers';

export const selectAuthState = (state: State) => state.auth;

// is this user authenticated
export const isAuthenticated = createSelector(
    selectAuthState,
    auth => auth.is_authenticated
);
// get system Permissions
export const getSystemPermissions = createSelector(
    selectAuthState,
    auth => auth.systemPermissions
);
// get system Permissions
export const getIsEmployee = createSelector(
    selectAuthState,
    auth => auth.isEmployee
);