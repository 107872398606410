<ion-list lines="none">
  <ion-item button (click)="toggleApplicationAddInterface()">
    <ion-label>Add Application to Chain</ion-label>
  </ion-item>
  <ion-item
    [disabled]="selectedApplications.length === 0"
    button
    (click)="bulkApplicationStateUpdate('Accepted')"
  >
    <ion-label
      >({{ selectedApplications.length }}) Accept Applications</ion-label
    >
  </ion-item>
  <ion-item
    [disabled]="selectedApplications.length === 0"
    button
    (click)="bulkApplicationStateUpdate('Reserved')"
  >
    <ion-label
      >({{ selectedApplications.length }}) Reserve Applications</ion-label
    >
  </ion-item>
  <ion-item
    [disabled]="selectedApplications.length === 0"
    button
    (click)="bulkApplicationStateUpdate('Enrolled')"
  >
    <ion-label
      >({{ selectedApplications.length }}) Enroll Applications</ion-label
    >
  </ion-item>
  <ion-item
    [disabled]="selectedApplications.length === 0"
    button
    (click)="bulkApplicationStateUpdate('Cancelled')"
  >
    <ion-label
      >({{ selectedApplications.length }}) Cancel and Detach
      Applications</ion-label
    >
  </ion-item>
  <ion-item
    [disabled]="selectedApplications.length === 0"
    button
    (click)="removeSelectionsFromChain()"
  >
    <ion-label>({{ selectedApplications.length }}) Remove from Chain</ion-label>
  </ion-item>
  <ion-item button (click)="disassembleChain()">
    <ion-label>Disassemble Chain</ion-label>
  </ion-item>
</ion-list>
