import { Component, OnInit, Input } from "@angular/core";
import { faClone, faTrash } from "@fortawesome/pro-regular-svg-icons";
// <i class="fa-light fa-trash-can"></i>
import { ToolsService } from "src/app/core/services/tools/tools.service";
import { ProgramsService } from "src/app/core/services/programs/programs.service";
import {
  AlertController,
  NavController,
  PopoverController
} from "@ionic/angular";
import { ToastController } from "@ionic/angular";

import { State } from "src/app/core/ngrx/reducers";
import { select, Store } from "@ngrx/store";
import { userSelectors } from "src/app/core/ngrx/selectors";
import { SmartToastService } from "../../services/smartToast.service";
import { NavigationService } from "src/app/core/services/navigation/navigation.service";
import { ApplicationService } from "src/app/core/services/application/application.service";
import { Router } from '@angular/router'

@Component({
  selector: "app-program-actions",
  templateUrl: "./program-actions.component.html",
  styleUrls: ["./program-actions.component.scss"]
})
export class ProgramActionsComponent implements OnInit {
  faClone = faClone;
  faTrash = faTrash;
  @Input() programid;
  @Input() programType;

  user$ = this.store.pipe(select(userSelectors.userProfile));
  user: any;

  showProgramDelete: boolean = false;
  showProgramDuplicate: boolean = false;

  constructor(
    private store: Store<State>,
    private navCtrl: NavController,
    private toolService: ToolsService,
    private popCtrl: PopoverController,
    private alertController: AlertController,
    private toastController: ToastController,
    private smartToastService: SmartToastService,
    private programsService: ProgramsService,
    private NavService: NavigationService,
    private applicationService: ApplicationService,
    private router : Router
  ) {
    // user
    this.user$.subscribe((res) => {
      this.user = { ...res };
      if (this.user && this.user.permissions) {
        this.showProgramDelete =
          this.user.permissions.includes("program:delete") ||
          this.user.permissions.includes("superuser");
        //
        this.showProgramDuplicate =
          this.user.permissions.includes("program:write") ||
          this.user.permissions.includes("superuser");
      }
    });
  }

  async presentToast(message, type) {
    const toast = await this.toastController.create({
      message,
      color: type,
      duration: 10000,
      buttons: [
        {
          text: "Close",
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          }
        }
      ],
      position: "top"
    });
    toast.present();
  }

  private async confirmationAlert(message: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>((resolve) => {
      resolveFunction = resolve;
    });
    const alert = await this.alertController.create({
      header: "Confirmation",
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: "No",
          handler: () => resolveFunction(false)
        },
        {
          text: "Yes",
          handler: () => resolveFunction(true)
        }
      ]
    });
    await alert.present();
    return promise;
  }

  ngOnInit() {}

  public async duplicateProgram() {
    this.popCtrl.dismiss();
    this.toolService.duplicateProgram(this.programid).subscribe(
      (res: any) => {
        if (res.newProgramId) {
          this.smartToastService.show(res.message, "primary", 2000);
          setTimeout(
            () =>
              this.router.navigate([
                '/programs/details/', res.newProgramId
              ]),
            1500
          );
        } else {
          this.smartToastService.show(
            res.message || "Duplicate Program Failed",
            "danger",
            2000
          );
        }
      },
      (err) => {
        console.log(err);
        this.smartToastService.show(
          err.message || "Duplicate Program Failed",
          "danger",
          2000
        );
      }
    );
  }

  async deleteProgram() {
    const confirm = await this.confirmationAlert(
      "Are you sure you want to delete this Program"
    );
    this.popCtrl.dismiss();

    if (confirm === true) {
      this.programsService.deleteProgram(this.programid).subscribe(
        (res: any) => {
          // console.log(res);
          this.smartToastService.show(
            "Successfully Deleted Program ID " + this.programid,
            "primary",
            10000
          );
          this.NavService.main("programs.list");
        },
        (err) => {
          console.log(err);
          this.smartToastService.show(
            err.message || "Deleteing Program Failed",
            "danger",
            2000
          );
        }
      );
    }
  }

  public async errorAlert() {
    const alert = await this.alertController.create({
      header: "Attention",
      message: "You do not have permissions needed to complete this action.",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary"
          // handler: (blah) => {
          //   console.log('Confirm Cancel: blah');
          // }
        }
      ]
    });

    await alert.present();
  }
}
