import { createSelector } from "@ngrx/store";
import { State } from "../reducers";

export const selectApplicationState = (state: State) => state.application;

export const applicationObject = createSelector(
  selectApplicationState,
  (application) => application.applicationObject
);

export const adminList = createSelector(
  selectApplicationState,
  (list) => list.adminList
);

export const submittedRequirementList = createSelector(
  selectApplicationState,
  (list) => list.submittedRequirementList
);

// enable application trainee information save button
export const getApplicationTraineeShowSave = createSelector(
  selectApplicationState,
  (_UI) => _UI.application_TraineeShowSave
);

export const applicationSave = createSelector(
  selectApplicationState,
  (application) => application.applicationSave
);
