import { ElementRef, QueryList } from '@angular/core';

export const isNil = (x: any): boolean => {
  try {
    if (x === null || x === undefined) {
      return true;
    } else if (x === 'null' || x === 'undefined') {
      return true;
    } else if (x === '' || (typeof x === 'object' && Object.keys(x).length === 0) || (Array.isArray(x) && x.length === 0)) {
      return true;
    } else if ((typeof x === 'object' && Object.keys(x).length === 0) || (typeof x === 'string' && x.trim().length === 0)) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return true;
  }
};

export const validCV = (s: string) => {
  return isNil(s) ? false : s.indexOf('_Temporary') !== -1 ? false : true;
};

/**
 *
 * @param date Date to be converted to UTC time
 * @description Returns UTC valid date
 *
 */
export const getUTCDate = (date: Date): Date => {
  const utcYear = new Date(date).getUTCFullYear();
  const utcMonth = new Date(date).getUTCMonth();
  const utcDay = new Date(date).getUTCDate();
  const utcNewDate = new Date(utcYear, utcMonth, utcDay);
  return utcNewDate;
};

export const slugify = (name: String) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return name
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
};

function isObject(object) {
  return object != null && typeof object === 'object';
}

export const isPositiveInteger = (str: string): Boolean => {
  if (typeof str !== 'string') {
    return false;
  }

  const num = Number(str);

  if (Number.isInteger(num) && num > 0) {
    return true;
  }

  return false;
};

/**
 *
 * @param start number start year
 * @param end number end year
 * @returns array of string years between two set of years
 */
export const rangeOfYears = (start: number, end: number): string[] => {
  const endDate = end || new Date().getFullYear();
  let years = [];

  for (var i = start; i <= endDate; i++) {
    years.push(String(start));
    start++;
  }
  return years;
};

/**
 * @param queryList - QueryList of elements to disable
 * @description - function to disable all AMO inputs, pass in from viewChildren template call
 */
export const disableAmoUserInputs = (queryList: QueryList<ElementRef>) => {
  // this is the list after the top level element
  const unpackAndDisable = (initElement: HTMLElement) => {
    // check if it is an AMO tag, if not iterate and try again
    if (initElement.tagName.includes('AMO')) {
      disableAMO(initElement);
    } else {
      const children: HTMLCollection = initElement.children;
      for (let i = 0; i < children.length; i++) {
        const child = children[i] as HTMLElement;
        unpackAndDisable(child);
      }
    }
  };

  const disableAMO = (element: HTMLElement) => {
    if (element.tagName.includes('AMO')) {
      element.setAttribute('disabled', 'true');
    }
  };
  // queryList passed in is always containing ElementRefs
  queryList.forEach((elementRef: ElementRef) => {
    const children = elementRef.nativeElement;
    unpackAndDisable(children);
  });
};

/**
 * @description validate string date
 * @param startdate string date
 * @returns boolean
 */
export const validateDateSting = (startdate: string) => {
  let dateRegex = /^(January|February|March|April|May|June|July|August|September|October|November|December)-([0-2][0-9]|3[0-1])-([0-9]{4})$/;
  return dateRegex.test(startdate);
};
