import { on, Action, createReducer } from "@ngrx/store";
import { PaymentActions } from "../actions";

export const paymentFeatureKey = "payment";

export interface PaymentState {
  paymentObject: {};
}

export const initialState: PaymentState = {
  paymentObject: {}
};

const PaymentReducer = createReducer(
  initialState,
  on(PaymentActions.setPayment, (state, args) => ({
    ...state,
    paymentObject: args.paymentObject
  }))
);

export function reducer(state: PaymentState | undefined, action: Action) {
  return PaymentReducer(state, action);
}
