<ion-list lines="none">
  <ion-item *ngIf="showProgramDuplicate" button (click)="duplicateProgram()">
    <fa-icon slot="start" [icon]="faClone"></fa-icon>
    <ion-label>Duplicate Program</ion-label>
  </ion-item>
  <ion-item *ngIf="showProgramDelete" button (click)="deleteProgram()">
    <fa-icon slot="start" [icon]="faTrash"></fa-icon>
    <ion-label>Delete Program</ion-label>
  </ion-item>
  <ion-item *ngIf="!showProgramDelete && !showProgramDuplicate">
    <ion-label>No available actions</ion-label>
  </ion-item>
</ion-list>
