import { createSelector } from '@ngrx/store';
import { State } from '../reducers';

export const selectUserState = (state: State) => state.user;

// if user has read only experience
export const isReadOnly = createSelector(selectUserState, user => user.isReadOnly);

export const isApplicationReadOnly = createSelector(selectUserState, user => user.isApplicationReadOnly);

export const isProgramReadOnly = createSelector(selectUserState, user => user.isProgramReadOnly);

export const isHostReadOnly = createSelector(selectUserState, user => user.isHostReadOnly);

export const isTraineeReadOnly = createSelector(selectUserState, user => user.isTraineeReadOnly);

export const isPaymentReadOnly = createSelector(selectUserState, user => user.isPaymentReadOnly);

export const isManagerReadOnly = createSelector(selectUserState, user => user.isManagerReadOnly);

export const isSuperUser = createSelector(selectUserState, user => user.isSuperUser);

// is the management role
export const isManagementType = createSelector(selectUserState, user => user.isManagementType);

export const userProfile = createSelector(selectUserState, user => user.userProfile);

export const canDocumentUpload = createSelector(selectUserState, user => user.canDocumentUpload);

export const canDocumentView = createSelector(selectUserState, user => user.canDocumentView);

export const canProgramWrite = createSelector(selectUserState, user => user.canProgramWrite);

export const canOrderEdit = createSelector(selectUserState, user => user.canOrderEdit);
