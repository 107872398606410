import { datadogRum } from '@datadog/browser-rum';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { Injectable } from '@angular/core';
import { textChangeRangeIsUnchanged } from 'typescript';

export default interface EventAction {
  // pageview?: string;
  customDimensions?: any;
  evCategory?: string;
  evAction?: string;
  evLabel?: string;
  evValue?: string;
  evAdminId?: string;
  evAdminType?: string;
  evAdminRole?: string;
  evDepartment?: string;
  ddAction?: string;
  ddContext?: any;
  stopwatchStart?: number;
  profile?: any;
}

@Injectable({
  providedIn: 'root',
})
export class SendEventActionsService {
  constructor(private angulartics2GoogleTagManager: Angulartics2GoogleTagManager) {}

  /**
   *
   * @param object - { pageview: String, eventAction: String, eventCategory: String, eventValue: String, ddAction: String, customDimensions: Object }
   */
  async sendEventActions(object: EventAction) {
    if (object.customDimensions) {
      this.angulartics2GoogleTagManager.pushLayer(object.customDimensions);
    }

    // if (object.pageview) {
    //   setTimeout(() => {
    //     this.angulartics2GoogleTagManager.pageTrack(object.pageview);
    //   }, 1000);
    // }

    // send Analytics (Angulartics) event
    // only action is required, but send category, label, and value if available
    if (object.evAction) {
      setTimeout(() => {
        this.angulartics2GoogleTagManager.eventTrack(object.evAction, {
          category: object.evCategory,
          label: object.evLabel,
          value: object.evValue,
        });
      }, 100);
    }

    // send DataDog RUM action
    if (object.ddAction) {
      object.ddContext = object.ddContext || {};
      object.ddContext.postInitTime = performance.now() - object.stopwatchStart;
      if (object.profile) {
        object.ddContext.id = object.profile.user ? object.profile.user.id : null;
        object.ddContext.name = object.profile.user ? object.profile.user.name : null;
        object.ddContext.user = object.profile.user;
      }

      setTimeout(() => {
        datadogRum.addUserAction(object.ddAction, object.ddContext);
      }, 100);
    }
  }
}
