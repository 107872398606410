import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AuthService } from "@Services/auth/auth.service";
import { ModalService, Warning } from "@shared/services";
import { SmartToastService } from "@shared/services/smartToast.service";
import { Auth } from "aws-amplify";

@Component({
  selector: "app-auth-update-phone-modal",
  templateUrl: "./auth-update-phone-modal.component.html",
  styleUrls: ["./auth-update-phone-modal.component.scss"]
})
export class AuthUpdatePhoneModalComponent implements OnInit {
  @Input() type: string;
  @Input() user: any;

  phone: string;
  newPhone: string;

  itemdata = JSON.stringify({
    data: []
  });

  componentsettings = JSON.stringify({
    features: {
      light: true
    },
    buttons: [
      {
        type: "Primary",
        action: "someEventEmitter",
        text: "Save"
      },
      {
        type: "Flat Primary",
        action: "someEventEmitter",
        text: "Cancel"
      }
    ],
    textFields: [
      {
        id: "a12345",
        type: "input",
        labeltext: "Site name:",
        placeholder: "Enter text",
        inputwidth: "inputwidth-50"
      }
    ]
  });

  constructor(
    private smartToastService: SmartToastService,
    private modalCtrl: ModalController,
    private warning: Warning,
    private modalService: ModalService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getAuthUser();
  }

  async getAuthUser() {
    const user = await Auth.currentUserInfo();
    this.phone = user.attributes.phone_number;
  }

  openPhoneVerifyModal() {
    this.authService
      .updateAdminAttributes({ mobilephone: this.newPhone })
      .subscribe((response) => {
        if (response.success) {
          this.dismiss(true);
          this.modalService
            .authConfirmEmailModal({
              phone: this.newPhone,
              oldPhone: this.phone,
              user: this.user,
              type: "phone"
            })
            .then((status: any) => {
              if (status.verified) {
                this.smartToastService.show(
                  "Successfully Updated Phone",
                  "success",
                  10000
                );
              } else if (!status) {
                this.smartToastService.show(
                  "We could not verify your phone number.",
                  "warning",
                  10000
                );
                this.newPhone = null;
              }
            });
        } else {
          this.warning.create(
            "There was an issue with updating your mobile phone. Please contact support for help resolving this issue."
          );
        }
      });
  }

  getPhoneInput(ev: CustomEvent) {
    this.newPhone = ev.detail.value.trim();
  }

  dismiss(fetch: boolean) {
    this.modalCtrl.dismiss(fetch);
  }
}
