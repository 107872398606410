import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingService } from 'src/app/core/services/loading/loading.service';
import { ApplicationService } from 'src/app/core/services/application/application.service';
import { Warning } from 'src/app/shared/services/warning.service';
import { faExclamationCircle, faCheckCircle, faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
interface ImmunizationStatusItem {
  status: string;
  name: string;
  content: string;
}

@Component({
  selector: 'app-document-viewer-modal',
  templateUrl: './document-viewer-modal.component.html',
  styleUrls: ['./document-viewer-modal.component.scss'],
})
export class DocumentViewerModalComponent implements OnInit {
  constructor(private modalCtrl: ModalController, private loading: LoadingService, private applicationService: ApplicationService, private warning: Warning) {}

  @Input() url: any;
  @Input() userid: any;
  @Input() isImmunization?: boolean = false;
  @Input() applicationid?: string;

  //new design flag bydefault false
  @Input() newDesign: boolean = true;

  documentFormat = 'url';

  showDocument = false; // shows document on page
  currentDocument = ''; // name of document shown in iframe
  isMultiDoc = false;
  selectedIndex = 0;

  icons: any = {
    exclamation: faExclamationCircle,
    checkcircle: faCheckCircle,
    down: faCaretDown,
    up: faCaretUp,
  };

  iconsIndication = {
    'Not Submitted': {
      iconOne: 0, //checkcircle
      iconTwo: 0, //exclamation
    },
    'Partial Completion': {
      iconOne: 0, //checkcircle
      iconTwo: 1, //exclamation
    },
    Complete: {
      iconOne: 1, //checkcircle
      iconTwo: 0, //exclamation
    },
  };
  openeditem = null;

  immunizationItemList: ImmunizationStatusItem[] = [];

  ngOnInit() {
    if (Array.isArray(this.url) == false) {
      this.documentOpener(this.url);
      this.isMultiDoc = false;
    } else {
      this.isMultiDoc = true;
      this.showDocument = false;
      this.selectedIndex = 0;
      this.viewImmunization(this.url[0].Key || this.url[0].key || null);
      this.url.forEach(element => {
        if (element.Key) {
          element = { ...element, Key: decodeURI(element.Key) };
        }
        if (element.key) {
          element = { ...element, key: decodeURI(element.key) };
        }
      });
      //console.log(this.url);
    }

    if (this.isImmunization && this.applicationid) {
      this.applicationService.getImmunizationMapList(this.applicationid).subscribe(response => {
        this.immunizationItemList = [...this.immunizationItemList, ...response];
      });
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

  documentOpener(url) {
    if (url) {
      if (url.includes('.doc?') || url.includes('.docx?')) {
        this.documentFormat = 'office';
      }
      this.currentDocument = url;
      this.showDocument = true;
    }
  }

  //input: folder path
  //output: https doc path
  viewImmunization(documentKey: any) {
    this.showDocument = false;
    // if we have a document name from aws
    if (documentKey) {
      this.applicationService.managerhostingViewdocument(documentKey).subscribe((res: any) => {
        if (res.url) {
          // present a loading screen
          this.loading.presentLazyLoadingCtrl('Loading', 1500);
          // console.log(res.url);
          this.documentOpener(res.url);
        } else {
          // if there was an error with url and aws can't get the image
          this.warning.create(
            `There  was an error loading the document. Contact us at <a href="mailto:rotations@amopportunities.org">rotations@amopportunities.org</a>`,
          );
        }
      });
    } else {
      this.warning.create(
        `There  was an error loading the document. Contact us at <a href="mailto:rotations@amopportunities.org">rotations@amopportunities.org</a>`,
      );
    }
  }

  clearImmunizationDocuments(file: any, index: number) {
    let originalfilename = file;
    this.warning.confirmation('Are you sure you want to clear this document?').then(confirm => {
      if (confirm) {
        if (originalfilename && this.userid) {
          this.applicationService
            .applicationArchiveDocument({
              userid: this.userid,
              originalfilename,
              itemno: -1,
            })
            .subscribe(
              res => {
                this.url.splice(index, 1);
              },
              error => console.log(error),
            );
        }
      }
    });
  }

  dropdown(indx) {
    //console.log(indx);
    if (this.openeditem == indx) {
      this.openeditem = null;
    } else {
      this.openeditem = indx;
    }
  }

  optionsChange(indx, type: string, status: any) {
    if (type == 'iconOne') {
      const newIconOne = this.iconsIndication[status][type] == 0 ? 1 : 0;
      const newIconStatus = this.findNewState({
        iconOne: newIconOne,
        iconTwo: newIconOne == 1 && this.iconsIndication[status]['iconTwo'] == 1 ? 0 : this.iconsIndication[status]['iconTwo'],
      });
      this.immunizationItemList[indx].status = newIconStatus;
    } else {
      //exclamation
      const newIconTwo = this.iconsIndication[status][type] == 0 ? 1 : 0;
      const newIconStatus = this.findNewState({
        iconOne: this.iconsIndication[status]['iconOne'] == 1 && newIconTwo == 1 ? 0 : this.iconsIndication[status]['iconOne'],
        iconTwo: newIconTwo,
      });
      this.immunizationItemList[indx].status = newIconStatus;
    }
  }

  findNewState(receivedval) {
    let newStatus;
    //  receivedval = {
    //       iconOne : 0, //checkcircle
    //       iconTwo : 1, //exclamation
    //  }
    for (var indicate in this.iconsIndication) {
      const prop = this.iconsIndication[indicate];
      if (prop.iconOne == receivedval.iconOne && prop.iconTwo == receivedval.iconTwo) {
        newStatus = indicate;
        break;
      }
    }
    return newStatus;
  }

  saveImmunization() {
    this.modalCtrl.dismiss(this.immunizationItemList);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
