import { createSelector } from "@ngrx/store";
import { State } from "../reducers";

export const selectTraineeState = (state: State) => state.trainee;

export const traineeObject = createSelector(
  selectTraineeState,
  trainee => trainee.traineeObject
);
// get user item 1 cv resume
export const getDocumentResume = createSelector(
  selectTraineeState,
  trainee => trainee.document_ItemOne
);
// get user item 10 toefl
export const getDocumentToefl = createSelector(
  selectTraineeState,
  trainee => trainee.document_ItemTen
);
// get user item 4 toefl
export const getDocumentSchoolRecords = createSelector(
  selectTraineeState,
  trainee => trainee.document_ItemFour
);