import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { PipesComponentsModule } from 'src/app/pipes/pipes.modules';

import { InstitutionActionsComponent } from '../components/institution-actions/institution-actions.component';
import { InstitutionsActionsCsvExportComponent } from './institutions-actions-csv-export/institutions-actions-csv-export.component';

@NgModule({
  declarations: [InstitutionActionsComponent, InstitutionsActionsCsvExportComponent],
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule, FontAwesomeModule, PipesComponentsModule],
  exports: [InstitutionActionsComponent, InstitutionsActionsCsvExportComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InstitutionActionsComponentModule {}
