import { Action, on, createReducer } from "@ngrx/store";
import { AuthActions } from "../actions";

export interface AuthState {
    is_authenticated: boolean;
    systemPermissions: string;
    isEmployee: boolean;
}

export const authFeatureKey = 'auth';

export const initialState: AuthState = {
    is_authenticated: false,
    systemPermissions: '',
    isEmployee: false
};

const AuthReducer = createReducer(
    initialState,
    on(AuthActions.setIsAuthenticated, (state, args) => ({
        ...state,
        is_authenticated: args.is_authenticated
    })),
    on(AuthActions.setLogout, (state, args) => ({
        ...state,
        is_authenticated: false
    })),
    on(AuthActions.setSystemPermissions, (state, args) => ({
        ...state,
        systemPermissions: args.systemPermissions
    })),
    on(AuthActions.setIsEmployee, (state, args) => ({
        ...state,
        isEmployee: args.isEmployee
    })),
);

export function reducer(state: AuthState | undefined, action: Action) {
    return AuthReducer(state, action);
}