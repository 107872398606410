import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { PipesComponentsModule } from 'src/app/pipes/pipes.modules';
import { AngularMaterialModule } from '../angular.material.module';
import { AgChartsModule } from 'ag-charts-angular';
import { OverlayModule } from '@angular/cdk/overlay';

import { SharedSidebarComponent } from './shared-sidebar/shared-sidebar.component';
import { SuccessfulActionComponent } from './successful-action/successful-action.component';
import { ListsHelpComponent } from './lists-help/lists-help.component';
import { ApplicationActionsComponent } from './application-actions/application-actions.component';
import { ApplicationPostponeReasonsComponent } from './application-postpone-reasons/application-postpone-reasons.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ApplicationDocumentReviewListComponent } from './application-document-review-list/application-document-review-list.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DocumentViewerModalComponent } from './document-viewer-modal/document-viewer-modal.component';
import { ChartsModalComponent } from './charts-modal/charts-modal.component';
import { ApplicationHoldActionsComponent } from './application-hold-actions/application-hold-actions.component';
import { ActionsCsvExportComponent } from './application-actions-csv-export/application-actions-csv-export.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { InstitutionActionsComponentModule } from './institution-action-dropdown.module';
import { ServiceWorkerComponent } from './service-worker/service-worker.component';
import { ProgramActionsComponent } from './program-actions/program-actions.component';
import { SharedButtonComponent } from './shared-button/shared-button.component';
import { SharedTimeUtcComponent } from './shared-time-utc/shared-time-utc.component';
import { SharedAddCouponComponent } from './../components/add-coupon/add-coupon.component';
import { ActivityLogComponent } from './../components/activity-log/activity-log.component';
import { ActivityLogActionsComponent } from './../components/activity-log-actions/activity-log-actions.component';
import { UploadDocHostComponent } from './../components/upload-doc-host/upload-doc-host.component';
import { AmoPositiveNumberDirective } from '@shared/directives/amo-positive-number.directive';
import { AuthUpdateModalComponent } from './auth-update-modal/auth-update-modal.component';
import { AuthPsUpdateModalComponent } from './auth-ps-update-modal/auth-ps-update-modal.component';
import { AuthUpdatePhoneModalComponent } from './auth-update-phone-modal/auth-update-phone-modal.component';
import { AuthVerifyCredsModalComponent } from './auth-verify-creds-modal/auth-verify-creds-modal.component';
import { ApplicationChainActionsComponent } from './application-chain-actions/application-chain-actions.component';
import { DateTimePickerComponent } from '../components/date-time-picker/date-time-picker.component';
import { GeoCoordinatesComponent } from '../components/geo-coordinates/geo-coordinates.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SearchBarComponent } from '../components/search-bar/search-bar.component';
import { SearchBarFilterComposedComponent } from '../components/search-bar/search-bar-filter-composed/search-bar-filter-composed.component';
import { SearchBarFilterSelectKeyComponent } from '../components/search-bar/search-bar-filter-select-key/search-bar-filter-select-key.component';
import { SearchBarFilterSelectMatchComponent } from '../components/search-bar/search-bar-filter-select-match/search-bar-filter-select-match.component';
import { SearchBarFilterSelectValueComponent } from '../components/search-bar/search-bar-filter-select-value/search-bar-filter-select-value.component';
import { SearchBarFilterSelectValueAnd2Component } from '../components/search-bar/search-bar-filter-select-value-and2/search-bar-filter-select-value-and2.component';
import { SearchBarFilterSelectValueOr2Component } from '../components/search-bar/search-bar-filter-select-value-or2/search-bar-filter-select-value-or2.component';
import { SearchBarInputComponent } from '../components/search-bar/search-bar-input/search-bar-input.component';
import { SearchBarSelectionComponent } from '../components/search-bar/search-bar-selection/search-bar-selection.component';
import { CheckboxWithLabelComponent } from '../components/checkbox-with-label/checkbox-with-label.component';
import { RequirementsStatusLegendComponent } from './requirements-status-legend/requirements-status-legend.component';
import { RequirementStatusActionsComponent } from './requirement-status-actions/requirement-status-actions.component';
import { SharedTextBoxComponent } from '../components/text-box/text-box.component';
import { SharedSingleSelectComponent } from '../components/single-select/single-select.component';
import { AmoAddressAutoCompleteInputComponent } from './amo-address-auto-complete-input/amo-address-auto-complete-input.component';
import { ProvisionalBlockerModalComponent } from './provisional-blocker-modal/provisional-blocker-modal.component';
import { CustomHeaderComponent, RangeCalendarComponent } from './range-calendar/range-calendar.component';
import { OrderSelectionModalPopover } from './order-selection-modal/order-selection-modal.component';

@NgModule({
  declarations: [
    SharedSidebarComponent,
    SuccessfulActionComponent,
    ListsHelpComponent,
    ApplicationActionsComponent,
    ApplicationPostponeReasonsComponent,
    ApplicationChainActionsComponent,
    ImageUploadComponent,
    ApplicationDocumentReviewListComponent,
    DocumentViewerModalComponent,
    ChartsModalComponent,
    ApplicationHoldActionsComponent,
    ActionsCsvExportComponent,
    UploadDocumentComponent,
    ServiceWorkerComponent,
    ProgramActionsComponent,
    SharedButtonComponent,
    SharedAddCouponComponent,
    ActivityLogComponent,
    ActivityLogActionsComponent,
    UploadDocHostComponent,
    AmoPositiveNumberDirective,
    SharedTimeUtcComponent,
    AuthUpdateModalComponent,
    AuthPsUpdateModalComponent,
    AuthUpdatePhoneModalComponent,
    AuthVerifyCredsModalComponent,
    DateTimePickerComponent,
    GeoCoordinatesComponent,
    SearchBarComponent,
    SearchBarFilterComposedComponent,
    SearchBarFilterSelectKeyComponent,
    SearchBarFilterSelectMatchComponent,
    SearchBarFilterSelectValueComponent,
    SearchBarFilterSelectValueAnd2Component,
    SearchBarFilterSelectValueOr2Component,
    SearchBarInputComponent,
    SearchBarSelectionComponent,
    CheckboxWithLabelComponent,
    RequirementsStatusLegendComponent,
    RequirementStatusActionsComponent,
    SharedTextBoxComponent,
    SharedTextBoxComponent,
    SharedSingleSelectComponent,
    AmoAddressAutoCompleteInputComponent,
    ProvisionalBlockerModalComponent,
    RangeCalendarComponent,
    CustomHeaderComponent,
    OrderSelectionModalPopover,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AngularMaterialModule,
    FontAwesomeModule,
    PipesComponentsModule,
    NgxDocViewerModule,
    AgChartsModule,
    InstitutionActionsComponentModule,
    OverlayModule,
    GoogleMapsModule,
  ],
  exports: [
    SharedSidebarComponent,
    SuccessfulActionComponent,
    ListsHelpComponent,
    ApplicationActionsComponent,
    ApplicationPostponeReasonsComponent,
    ApplicationChainActionsComponent,
    ImageUploadComponent,
    ApplicationDocumentReviewListComponent,
    DocumentViewerModalComponent,
    ChartsModalComponent,
    ActionsCsvExportComponent,
    UploadDocumentComponent,
    ServiceWorkerComponent,
    ProgramActionsComponent,
    SharedButtonComponent,
    SharedAddCouponComponent,
    ActivityLogComponent,
    UploadDocHostComponent,
    AmoPositiveNumberDirective,
    SharedTimeUtcComponent,
    AuthUpdateModalComponent,
    AuthPsUpdateModalComponent,
    AuthUpdatePhoneModalComponent,
    AuthVerifyCredsModalComponent,
    DateTimePickerComponent,
    GeoCoordinatesComponent,
    SearchBarComponent,
    SearchBarFilterComposedComponent,
    SearchBarFilterSelectKeyComponent,
    SearchBarFilterSelectMatchComponent,
    SearchBarFilterSelectValueComponent,
    SearchBarFilterSelectValueAnd2Component,
    SearchBarFilterSelectValueOr2Component,
    SearchBarInputComponent,
    SearchBarSelectionComponent,
    CheckboxWithLabelComponent,
    RequirementsStatusLegendComponent,
    RequirementStatusActionsComponent,
    SharedTextBoxComponent,
    SharedTextBoxComponent,
    SharedSingleSelectComponent,
    AmoAddressAutoCompleteInputComponent,
    ProvisionalBlockerModalComponent,
    RangeCalendarComponent,
    CustomHeaderComponent,
    OrderSelectionModalPopover,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule {}
