export const environment = {
  production: true,
  testMode: false,
  env: 'production',
  api: `https://api.amopportunities.org`,
  app: `https://app.amopportunities.org/`,
  graphqlURL: `https://api.amopportunities.org/graphql`,
  websocketURL: `wss://devapi.amopportunities.org/graphql`,
  s3: 'https://amo-east1-enc.s3-accelerate.amazonaws.com/',
  s3static: 'https://amo-static-enc.s3.amazonaws.com/',
  immunizations: 'https://54mkffx395.execute-api.us-east-1.amazonaws.com/v0/download/immunization',
  guide: 'https://s3.amazonaws.com/amo-static-enc/d/Pre-Departure_Guide.pdf',
  cv: 'https://s3.amazonaws.com/amo-static-enc/d/AMO_Sample_CV.pdf',
  uploadaws: 'https://54mkffx395.execute-api.us-east-1.amazonaws.com/v11052018/upload',
  prodsearch: 'https://9dpi50t78g.execute-api.us-east-1.amazonaws.com/v0/cachesearch',
  bookingprice: 99900,
  countryList: `https://s3.amazonaws.com/amo-static-enc/json/countries.json`,
  countryCodeList: `https://app.amopportunities.org/img/flags/country.json`,
  helloSignAPI: `https://kwusau0m49.execute-api.us-east-1.amazonaws.com/v1/`,
  helloSignClientId: 'd7cfcd905e1125412161e1acd981b1b5',
  contentUrlJSON: `https://d6fj0ztu4j10m.cloudfront.net/text.json`,
  logRocketStage: 'production',
  englishOnlyUpload: '244d3ab2d593',
  baseHref: 'https://hosting.amopportunities.org/',
  ACCESS_SITE: 'https://access.amopportunities.org/',
  logProgramView: 'https://x29y8g6jk1.execute-api.us-east-1.amazonaws.com/v0/logsearch',
  captchaKey: '6LeG774UAAAAABePqpdydvOz9xppWF_0aAHjCM2m',
  API_GATEWAY_COGNITO_AUTH: `https://auth.amopportunities.org`, // `https://u1pn0i2tke.execute-api.us-west-2.amazonaws.com/prod`,
  COGNTIO_APP_CLIENT_ID: `5kkc8h1r37h9rudmg64vshan4c`,
  COGNITO_USER_POOL: `us-west-2_iXnl0ym3o`,
  COGNITO_REGION: 'us-west-2',
  COGNITO_FLOW_TYPE: 'CUSTOM_AUTH',
  ADMIN_VERSION: '',
  API_GATEWAY_PAYMENTS: `https://pay.amopportunities.org`,
};
