import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, from, throwError, of } from 'rxjs';
import { v4 as uuid4 } from 'uuid';
import { SendEventActionsService } from 'src/app/core/services/events/sendEventActions.service';
import { parseString, toJson, Parser } from 'xml2js';

export interface uploadFileReturn {
  success: boolean;
  parsedXML: XmlResponse;
  filename?: string;
  message?: string;
}

export interface XmlResponse {}

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient, private sendEventActionService: SendEventActionsService) {}

  public progress = {
    percent: 0,
    status: '',
    multi: [],
  };

  awsUploadPermissions(file: any, object: any): Observable<any> {
    return this.http.get(`${environment.api}/managements/upload?mimeType=${file}&Key=${encodeURIComponent(object.key)}`);
  }

  awsDynamicUploadPermissions(file: any, object: any, staticCheck?: boolean): Observable<any> {
    if (staticCheck) {
      return this.http.get(`${environment.api}/configs/staticwritepolicy?mimeType=${file}&Key=${encodeURIComponent(object.key)}&acl=${object.acl}`);
    }
    return this.http.get(`${environment.api}/managements/upload?mimeType=${file}&Key=${encodeURIComponent(object.key)}&acl=${object.acl}`);
  }

  /**
   * @description Get AWS Bucket Permissions
   * @method GET
   * @param userid  current userid
   * @param filetype MIME file type (accepted are pdf and jpeg)
   */
  awsUserUploadPermissions(userid: string, filetype: string): Observable<any> {
    return this.http.get(`${environment.api}/users/upload?mimeType=${filetype}&userid=${userid}`);
  }

  awsViewStaticImages(programid: string) {
    return this.http.get(`${environment.api}/configs/static?prefix=programs/${programid}/images/`);
  }

  listProgramImage(programid: string) {
    return this.http.get(`${environment.api}/managements/liststaticimages`);
  }

  /**
   * @description send rum action for document upload
   * @param item
   * @param file
   * @param label
   * @param context
   */
  sendRumAction(item: string, file: File, label: string, context: any = {}): void {
    this.sendEventActionService.sendEventActions({
      evAction: `admin_document_upload_service_${label}`,
      evCategory: 'ApplicationDetail',
      ddAction: `admin_document_upload_service_${label}`,
      ddContext: {
        ctxEvent: label,
        ctxUploadName: file.name,
        ctxUploadItem: item,
        ctxUploadSize: file.size,
        ctxUploadType: file.type,
        ...context,
      },
      customDimensions: {},
    });
  }

  /**
   * @description set xhr listeners for document upload
   * @param xhr
   * @param type
   * @param file
   * @param callback
   */
  setXhrEvents(xhr: XMLHttpRequest, type: string, file: File, callback: Function) {
    const docTrackId = uuid4();
    let startTimestamp = 0;
    xhr.onloadstart = e => {
      startTimestamp = e.timeStamp;
      this.sendRumAction(type, file, 'onloadstart', {
        docTrackId,
        timestamp: startTimestamp,
      });
    };
    xhr.upload.onprogress = e => {
      const percent = Math.round((100 * e.loaded) / e.total) / 100;
      if (percent > this.progress.percent + 0.2 || percent === 1) {
        this.sendRumAction(type, file, 'onprogress', {
          docTrackId,
          percent,
          timestamp: e.timeStamp,
        });
      }
      this.progress.percent = percent;
    };
    xhr.upload.onloadend = e => {
      this.progress.status = 'processing document...';
    };
    xhr.upload.onerror = e => {
      this.sendRumAction(type, file, 'upload.onerror', { docTrackId, status: xhr.status, response: xhr.responseText || 'Network request failed' });
    };
    xhr.onload = async e => {
      this.sendRumAction(type, file, 'onload', {
        docTrackId,
        timestamp: e.timeStamp,
        uploadTime: e.timeStamp - startTimestamp,
        startTime: startTimestamp,
        response: xhr.response,
      });
      this.progress.status = 'onload';
      const xmlResponse = await this.configureXmlResponse(e);
      callback(null, xmlResponse);
    };
    xhr.ontimeout = e => {
      this.sendRumAction(type, file, 'ontimeout', { docTrackId, status: xhr.status, response: xhr.responseText || 'Network request failed' });
      callback(e);
    };
    xhr.onerror = e => {
      this.sendRumAction(type, file, 'onerror', { docTrackId, status: xhr.status, response: xhr.responseText || 'Network request failed' });
      callback(e);
    };
  }

  handleDocumentUpload(files: FileList, programDocumentsObject: any): Observable<any> {
    const item = 'handleDocumentUpload';
    const tasks = Array.from(files).map(file => {
      const filenameString = String(file.name);
      const filetype = String(file.type);
      const noSpaces = filenameString.replace(/ /g, '_');
      const fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');

      this.awsUploadPermissions(filetype, programDocumentsObject).subscribe(
        (res: any) => {
          try {
            const s3Params = { ...res };
            const formData: FormData = new FormData();
            const xhr: XMLHttpRequest = new XMLHttpRequest();

            // tslint:disable-next-line:max-line-length
            formData.append('key', programDocumentsObject.key + fileName);
            formData.append('acl', 'private');
            formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
            formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
            formData.append('success_action_status', '201');
            formData.append('Policy', s3Params.s3Policy);
            formData.append('Signature', s3Params.s3Signature);
            formData.append('file', file, fileName);
            xhr.open('POST', environment.s3, true);

            this.setXhrEvents(xhr, item, file, (err, response) => {
              if (err) {
                return throwError(new Error(`Additional Documents Upload Error: ${err.message}`));
              }
              return from(new Promise(resolve => resolve('success')));
            });
            xhr.send(formData);
          } catch (err) {
            return throwError(new Error(`Additional Documents Upload Error: ${err.message}`));
          }
        },
        err => {
          return throwError(new Error(`Additional Documents Permissions Error: ${err.message}`));
        },
      );
    });
    if (tasks.indexOf(undefined) !== -1) {
      return from(new Promise(resolve => resolve('success')));
    } else {
      return from(tasks);
    }
  }

  handleCoreDocumentUpload(files: FileList, user: any, type: string): Promise<any> {
    const item = 'handleCoreDocumentUpload';
    return new Promise((resolve, reject) => {
      const tasks = Array.from(files).map(file => {
        const filenameString = String(file.name);
        const filetype = String(file.type);
        const noSpaces = filenameString.replace(/ /g, '_');
        const fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');

        const uploadPath = String('userdocuments/' + user.id + '/');

        const fileExt = fileName.slice(fileName.lastIndexOf('.') + 1);

        const userCleanName = String(user.name.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, ''));

        var userSafeName = String(userCleanName.replace(/ /g, '_'));

        const keyDir = String('userdocuments/' + user.id + '/item_' + type + '_' + userSafeName + '.' + fileExt);

        this.awsUploadPermissions(filetype, { key: uploadPath })
          .toPromise()
          .then(res => {
            try {
              const s3Params = { ...res };
              const formData: FormData = new FormData();
              const xhr: XMLHttpRequest = new XMLHttpRequest();

              // tslint:disable-next-line:max-line-length
              formData.append('key', keyDir);
              formData.append('acl', 'private');
              formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
              formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
              formData.append('success_action_status', '201');
              formData.append('Policy', s3Params.s3Policy);
              formData.append('Signature', s3Params.s3Signature);
              formData.append('file', file, fileName);
              xhr.open('POST', environment.s3, true);

              this.setXhrEvents(xhr, item, file, (err, response) => {
                if (err) {
                  reject({ success: false, message: err.message });
                }
                resolve({
                  success: true,
                  parsedXML: response,
                  filename: fileName,
                });
              });
              xhr.send(formData);
            } catch (err) {
              reject(new Error(`Documents Upload Error: ${err.message}`));
            }
          })
          .catch(err => {
            reject(new Error(`Documents Upload Error: ${err.message}`));
          });
      });
    });
  }

  handleApplicationHealthInsuranceUpload(files: FileList, application: any, uploadDestination: string, previousDocuments: any = []) {
    return new Promise((resolve, reject) => {
      const item = 'handleApplicationHealthInsuranceUpload';
      uploadDestination = `userdocuments/${application.userid}/applications/${application.id}/healthinsurance/`;

      const tasks = Array.from(files).map(file => {
        const filenameString = String(file.name);
        const filetype = String(file.type);
        const noSpaces = filenameString.replace(/ /g, '_');
        let fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');

        // check if filename matches other files in the healthinsurance folder
        if (previousDocuments && previousDocuments.length > 0) {
          const fileNameCompare = fileName.split('.').slice(0, -1).join('.');
          const numOfSameFileName: number = previousDocuments.reduce((acc, curr) => {
            if (curr.Key.includes(fileNameCompare)) acc++;
            return acc;
          }, 0);
          // append the number of matches to the new file so it won't overwrite the older one
          // fileName =
          //   numOfSameFileName > 0
          //     ? fileName + `(${numOfSameFileName})`
          //     : fileName;
          if (numOfSameFileName > 0) {
            let arr = fileName.split('.');
            arr[arr.length - 2] = `${arr[arr.length - 2]}(${numOfSameFileName})`;
            fileName = arr.join('.'); //testfile33202215th(2).pdf
          }
        }

        const keyDir = uploadDestination;
        this.awsUploadPermissions(file.type, { key: keyDir })
          .toPromise()
          .then(res => {
            try {
              const s3Params = { ...res };
              const formData: FormData = new FormData();
              const xhr: XMLHttpRequest = new XMLHttpRequest();

              // tslint:disable-next-line:max-line-length
              formData.append('key', keyDir + fileName);
              formData.append('acl', 'private');
              formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
              formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
              formData.append('success_action_status', '201');
              formData.append('Policy', s3Params.s3Policy);
              formData.append('Signature', s3Params.s3Signature);
              formData.append('file', file, fileName);
              xhr.open('POST', environment.s3, true);

              this.setXhrEvents(xhr, item, file, (err, response) => {
                if (err) {
                  reject({ success: false, message: err.message });
                }
                resolve({
                  success: true,
                  parsedXML: response,
                  filename: fileName,
                });
              });
              xhr.send(formData);
            } catch (err) {
              reject(new Error(`Documents Upload Error: ${err.message}`));
            }
          })
          .catch(err => {
            reject(new Error(`Documents Upload Error: ${err.message}`));
          });
      });
      // end of map
    });
    // end of promise
  }

  handleImmunizationsDocumentUpload(files: FileList, user: any, previousDocuments?: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const item = 'handleImmunizationsDocumentUpload';
      const tasks = Array.from(files).map(file => {
        const filenameString = String(file.name);
        const filetype = String(file.type);
        const noSpaces = filenameString.replace(/ /g, '_');
        let fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');

        // check if filename matches other files in the immue folder
        if (previousDocuments && previousDocuments.length > 0) {
          const fileNameCompare = fileName.split('.').slice(0, -1).join('.');
          const numOfSameFileName: number = previousDocuments.reduce((acc, curr) => {
            if (curr.Key.includes(fileNameCompare)) acc++;
            return acc;
          }, 0);
          // append the number of matches to the new file so it won't overwrite the older one
          // fileName =
          //   numOfSameFileName > 0
          //     ? fileName + `(${numOfSameFileName})`
          //     : fileName;
          if (numOfSameFileName > 0) {
            let arr = fileName.split('.');
            arr[arr.length - 2] = `${arr[arr.length - 2]}(${numOfSameFileName})`;
            fileName = arr.join('.'); //testfile33202215th(2).pdf
          }
        }

        const uploadPath = String('userdocuments/' + user.id);

        const keyDir = String('userdocuments/' + user.id + '/immunizations/filled_immunization_' + fileName);

        this.awsUserUploadPermissions(user.id, filetype)
          .toPromise()
          .then(res => {
            try {
              const s3Params = { ...res };
              const formData: FormData = new FormData();
              const xhr: XMLHttpRequest = new XMLHttpRequest();

              // tslint:disable-next-line:max-line-length
              formData.append('key', keyDir);
              formData.append('acl', 'private');
              formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
              formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
              formData.append('success_action_status', '201');
              formData.append('Policy', s3Params.s3Policy);
              formData.append('Signature', s3Params.s3Signature);
              formData.append('file', file, fileName);
              xhr.open('POST', environment.s3, true);

              this.setXhrEvents(xhr, item, file, (err, response) => {
                if (err) {
                  reject({ success: false, message: err.message });
                }
                resolve({
                  success: true,
                });
              });
              xhr.send(formData);
            } catch (err) {
              reject(new Error(`Documents Upload Error: ${err.message}`));
            }
          })
          .catch(err => {
            reject(new Error(`Documents Upload Error: ${err.message}`));
          });
      });
    });
  }

  handleApplicationRequirementUpload(files: FileList, requirementName: string, uploadDestination: string, existingRequirementDocuments: any = []) {
    return new Promise((resolve, reject) => {
      const item = 'handleApplicationRequirementUpload';
      //console.log("received upload service", existingRequirementDocuments)
      // check if filename matches other files in the requirements folder
      let foundProgramReqFilepaths = [];
      let path = uploadDestination;
      if (existingRequirementDocuments && existingRequirementDocuments.length > 0) {
        //"userdocuments/7ae4dfe7-ed93-45a6-9f79-ad4efbef3e0f/requirements/628df14b-c8d4-45fe-a485-6b8338c16b7d/program/100000134/COVID19_7_Seven_Day_Quarantine_for_International_Travelers_or_Negative_COVID_Test_24_Hours_Post_Travel/"
        foundProgramReqFilepaths = existingRequirementDocuments.filter(object => {
          //requirements/e0619c46-724a-46cc-a89f-b7890ee2812e/program/100000036/
          if (object.Key.includes(path)) {
            return object;
          }
        });
        //console.log("::::",existingRequirementDocuments, foundProgramReqFilepaths);
      }
      //console.log(existingRequirementDocuments, fileName);
      const tasks = Array.from(files).map(file => {
        // define file name of current file being upladed
        // var filenameString = String(requirementName);
        var filenameString = String(file.name);
        // remove all spaces from the file name being uploaded
        var noSpaces = filenameString.replace(/ /g, '_');
        // remove all special char from the file name
        var fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');

        if (foundProgramReqFilepaths && foundProgramReqFilepaths.length > 0) {
          const fileNameCompare = `filled_requirements_${fileName}`.split('.').slice(0, -1).join('.');
          const numOfSameFileName: number = foundProgramReqFilepaths.reduce((acc, curr) => {
            const splitUrl = curr.Key.split(path);
            const requirementFilePath = splitUrl[1];
            if (requirementFilePath && requirementFilePath.includes(fileNameCompare)) acc++;
            return acc;
          }, 0);
          if (numOfSameFileName > 0) {
            let arr = fileName.split('.');
            arr[arr.length - 2] = `${arr[arr.length - 2]}(${numOfSameFileName})`;
            fileName = arr.join('.'); //testfile33202215th(2).pdf
          }
        }
        const keyDir = uploadDestination;

        this.awsUploadPermissions(file.type, { key: keyDir })
          .toPromise()
          .then(res => {
            try {
              const s3Params = { ...res };
              const formData: FormData = new FormData();
              const xhr: XMLHttpRequest = new XMLHttpRequest();
              const fileNameReq = `filled_requirements_${fileName}`;

              // tslint:disable-next-line:max-line-length
              formData.append('key', keyDir + fileNameReq);
              formData.append('acl', 'private');
              formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
              formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
              formData.append('success_action_status', '201');
              formData.append('Policy', s3Params.s3Policy);
              formData.append('Signature', s3Params.s3Signature);
              formData.append('file', file, fileNameReq);
              xhr.open('POST', environment.s3, true);

              this.setXhrEvents(xhr, item, file, (err, response) => {
                if (err) {
                  reject({ success: false, message: err.message });
                }
                resolve({
                  success: true,
                  parsedXML: response,
                  filename: fileName,
                });
              });
              xhr.send(formData);
            } catch (err) {
              reject(new Error(`Documents Upload Error: ${err.message}`));
            }
          })
          .catch(err => {
            reject(new Error(`Documents Upload Error: ${err.message}`));
          });
      });
      // end of map
    });
    // end of promise
  }

  viewDocument(documentKey: string): Observable<any> {
    return this.http.get(`${environment.api}/managements/document?key=${documentKey}&ngsw-bypass=true`);
  }

  /**
   *
   * @param files ImageList
   * @param programObject program details
   * @returns Promise
   */
  handleProgramImageListUpload(files: any[], programObject) {
    return new Promise<uploadFileReturn>((resolve, reject) => {
      const item = 'handleProgramImageListUpload';
      const tasks = Array.from(files).map(file => {
        // define file name of current file being upladed
        const filenameString = String(file.name);
        const filetype = String(file.type);
        const noSpaces = filenameString.replace(/ /g, '_');
        const fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');

        this.awsUploadPermissions(file.type, programObject)
          .toPromise()
          .then(res => {
            try {
              const s3Params = { ...res };
              const formData: FormData = new FormData();
              const xhr: XMLHttpRequest = new XMLHttpRequest();

              // tslint:disable-next-line:max-line-length
              formData.append('key', programObject.key + fileName);
              formData.append('acl', 'private');
              formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
              formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
              formData.append('success_action_status', '201');
              formData.append('Policy', s3Params.s3Policy);
              formData.append('Signature', s3Params.s3Signature);
              formData.append('file', file, fileName);
              xhr.open('POST', environment.s3, true);

              this.setXhrEvents(xhr, item, file, (err, response) => {
                if (err) {
                  reject({ success: false, message: err.message });
                }
                resolve({
                  success: true,
                  parsedXML: response,
                  filename: fileName,
                });
              });
              xhr.send(formData);
            } catch (err) {
              reject(new Error(`Documents Upload Error: ${err.message}`));
            }
          })
          .catch(err => {
            reject(new Error(`Documents Upload Error: ${err.message}`));
          });
      });
      // end of map
    });
    // end of promise
  }

  handleDynamicPathUpload(files: any[], filePath: any, awsPolicy: boolean) {
    return new Promise<uploadFileReturn>((resolve, reject) => {
      const tasks = Array.from(files).map(file => {
        // define file name of current file being uploaded
        let fileNameCheck;
        if (!filePath.fileName) {
          const filenameString = String(file.name);
          const filetype = String(file.type);
          const noSpaces = filenameString.replace(/ /g, '_');
          let fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');
          fileNameCheck = fileName;
        } else {
          fileNameCheck = filePath.fileName;
        }

        const url = awsPolicy ? environment.s3static : environment.s3;

        this.awsDynamicUploadPermissions(file.type, filePath, awsPolicy)
          .toPromise()
          .then(res => {
            try {
              const s3Params = { ...res };
              const formData: FormData = new FormData();
              const xhr: XMLHttpRequest = new XMLHttpRequest();

              // tslint:disable-next-line:max-line-length
              formData.append('key', filePath.key + fileNameCheck);
              formData.append('acl', filePath.acl);
              formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
              formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
              formData.append('success_action_status', '201');
              formData.append('Policy', s3Params.s3Policy);
              formData.append('Signature', s3Params.s3Signature);
              formData.append('file', file, filePath.fileName);
              xhr.open('POST', url, true);
              xhr.onload = () => {
                const jsonResponse = xhr.response;
              };
              xhr.onreadystatechange = async e => {
                if (xhr.readyState === 4) {
                  const xmlResponse = await this.configureXmlResponse(e);
                  resolve({
                    success: true,
                    parsedXML: xmlResponse,
                    filename: filePath.fileName,
                  });
                }
              };
              xhr.send(formData);
            } catch (err) {
              reject(new Error(`Document Upload Error: ${err.message}`));
            }
          })
          .catch(err => {
            reject(new Error(`Document Upload Error: ${err.message}`));
          });
      });
      // end of map
    });
    // end of promise
  }

  handleImageUpload(file: File, programImageObject: any): Observable<any> {
    const filenameString = String(file.name);
    const filetype = String(file.type);
    const noSpaces = filenameString.replace(/ /g, '_');
    const fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');

    return of(
      this.awsUploadPermissions(filetype, programImageObject).subscribe((res: any) => {
        const s3Params = { ...res };
        const formData: FormData = new FormData();
        const xhr: XMLHttpRequest = new XMLHttpRequest();

        // tslint:disable-next-line:max-line-length
        formData.append('key', programImageObject.key + fileName);
        formData.append('acl', 'private');
        formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
        formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
        formData.append('success_action_status', '201');
        formData.append('Policy', s3Params.s3Policy);
        formData.append('Signature', s3Params.s3Signature);
        formData.append('file', file, fileName);
        xhr.open('POST', environment.s3, true);
        xhr.onload = () => {
          const jsonResponse = xhr.response;
        };
        xhr.send(formData);
        return of({ type: 'success', filename: fileName });
      }),
    );
  }

  async configureXmlResponse(response: any) {
    const xmlObject = await this.convertXmlToJson(response.target.responseXML);

    const cleanETag = xmlObject['PostResponse'].ETag.replace(/[^\w\s]/gi, '');

    const parsedObject = {
      Bucket: xmlObject['PostResponse']['Bucket'],
      ETag: cleanETag,
      Location: xmlObject['PostResponse']['Location'],
      Key: xmlObject['PostResponse']['Key'],
    };

    return parsedObject;
  }

  /**
   * @description method that handles user profile image upload
   * @method POST
   * @param files - FileList, the specific property type not an array of File objects
   * @param userid - current users id
   */
  handleUserProfileImageUpload(
    // files: FileList,
    file: File,
    userid: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      //
      if (!userid) {
        reject('No user id found');
      }
      //
      //const tasks = Array.from(files).map(async file => {
      const filenameString = String(file.name);
      const filetype = String(file.type);
      const noSpaces = filenameString.replace(/ /g, '_');
      const fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');
      // console.log(filenameString, filetype, noSpaces,fileName );

      this.http
        .get(`${environment.api}/users/upload?mimeType=${filetype}&userid=${userid}`)
        .toPromise()
        .then(resultPermissions => {
          try {
            const s3Params = { ...resultPermissions };
            const formData: FormData = new FormData();
            const xhr: XMLHttpRequest = new XMLHttpRequest();

            // tslint:disable-next-line:max-line-length
            formData.append('key', `userdocuments/${userid}/images/${fileName}`);
            formData.append('acl', 'private');
            formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
            formData.append('AWSAccessKeyId', s3Params['AWSAccessKeyId']);
            formData.append('success_action_status', '201');
            formData.append('Policy', s3Params['s3Policy']);
            formData.append('Signature', s3Params['s3Signature']);
            formData.append('file', file, fileName);

            xhr.open('POST', environment.s3, true);

            xhr.onreadystatechange = async (e: any) => {
              if (xhr.readyState === 4) {
                const xmlObject = await this.configureXmlResponse(e.target.responseXML);

                const cleanETag = xmlObject['PostResponse'].ETag.replace(/[^\w\s]/gi, '');

                xmlObject['PostResponse'].ETag = cleanETag;

                resolve({
                  success: true,
                  parsedXML: {
                    ...xmlObject['PostResponse'],
                  },
                });
              }
            };

            xhr.send(formData);
          } catch (err) {
            reject(`Additional Documents Upload Error: ${err.message}`);
          }
        })
        .catch(err => {
          reject(`Additional Documents Upload Error: ${err.message}`);
        });
      //});
    });
  }

  handleHostManagerUpload(files: FileList, user: any, type: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const tasks = Array.from(files).map(file => {
        const filenameString = String(file.name);
        const filetype = String(file.type);
        const noSpaces = filenameString.replace(/ /g, '_');
        const fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');

        const uploadPath = String('hostdocuments/' + user.id + '/');

        const fileExt = fileName.substr(fileName.lastIndexOf('.') + 1);

        const userCleanName = String(user.name.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, ''));

        var userSafeName = String(userCleanName.replace(/ /g, '_'));

        const keyDir = String('hostdocuments/' + user.id + '/' + type + '_' + userSafeName + '.' + fileExt);

        this.awsUploadPermissions(filetype, { key: uploadPath })
          .toPromise()
          .then(res => {
            try {
              const s3Params = { ...res };
              const formData: FormData = new FormData();
              const xhr: XMLHttpRequest = new XMLHttpRequest();

              // tslint:disable-next-line:max-line-length
              formData.append('key', keyDir);
              formData.append('acl', 'private');
              formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
              formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
              formData.append('success_action_status', '201');
              formData.append('Policy', s3Params.s3Policy);
              formData.append('Signature', s3Params.s3Signature);
              formData.append('file', file, fileName);
              xhr.open('POST', environment.s3, true);

              xhr.onreadystatechange = async e => {
                if (xhr.readyState === 4) {
                  const xmlResponse = await this.configureXmlResponse(e);
                  resolve({
                    success: true,
                    parsedXML: xmlResponse,
                    filename: fileName,
                  });
                }
              };
              xhr.send(formData);
            } catch (err) {
              console.log('err', err);

              reject(new Error(`Documents Upload Error: ${err.message}`));
            }
          })
          .catch(err => {
            reject(new Error(`Documents Upload Error: ${err.message}`));
          });
      });
    });
  }

  handleCreateDocument(files: FileList): Promise<any> {
    return new Promise((resolve, reject) => {
      const tasks = Array.from(files).map(file => {
        const filenameString = String(file.name);
        const filetype = String(file.type);
        const noSpaces = filenameString.replace(/ /g, '_');
        const fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');

        const uploadPath = String('documents/inventory/');

        this.awsUploadPermissions(filetype, { key: uploadPath })
          .toPromise()
          .then(res => {
            try {
              const s3Params = { ...res };
              const formData: FormData = new FormData();
              const xhr: XMLHttpRequest = new XMLHttpRequest();

              // tslint:disable-next-line:max-line-length
              formData.append('key', uploadPath + fileName);
              formData.append('acl', 'private');
              formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
              formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
              formData.append('success_action_status', '201');
              formData.append('Policy', s3Params.s3Policy);
              formData.append('Signature', s3Params.s3Signature);
              formData.append('file', file, fileName);
              xhr.open('POST', environment.s3, true);

              xhr.onreadystatechange = async e => {
                if (xhr.readyState === 4) {
                  const xmlResponse = await this.configureXmlResponse(e);
                  resolve({
                    success: true,
                    parsedXML: xmlResponse,
                    filename: fileName,
                  });
                }
              };
              xhr.send(formData);
            } catch (err) {
              reject(new Error(`Documents Upload Error: ${err.message}`));
            }
          })
          .catch(err => {
            reject(new Error(`Documents Upload Error: ${err.message}`));
          });
      });
    });
  }

  handleCsvManagerImportUpload(files: FileList, path: any): Observable<uploadFileReturn> {
    let resolve: (value: uploadFileReturn) => void;
    let reject: (err: any) => void;

    const prom: Promise<uploadFileReturn> = new Promise((resolveProm, rejectProm) => {
      resolve = resolveProm;
      reject = rejectProm;
    });

    const tasks: void[] = Array.from(files).map(file => {
      const filenameString = String(file.name);
      const filetype = String(file.type);
      const noSpaces = filenameString.replace(/ /g, '_');
      const fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/\u202F\u00A0]/gi, '');

      this.awsUploadPermissions(filetype, {
        key: path.key + fileName,
      }).subscribe(
        (res: any) => {
          try {
            const s3Params = { ...res };
            const formData: FormData = new FormData();
            const xhr: XMLHttpRequest = new XMLHttpRequest();

            // tslint:disable-next-line:max-line-length
            formData.append('key', path.key + fileName);
            formData.append('acl', 'private');
            formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
            formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
            formData.append('success_action_status', '201');
            formData.append('Policy', s3Params.s3Policy);
            formData.append('Signature', s3Params.s3Signature);
            formData.append('file', file, fileName);
            xhr.open('POST', environment.s3, true);

            xhr.onreadystatechange = async e => {
              if (xhr.readyState === 4) {
                const xmlResponse = await this.configureXmlResponse(e);
                resolve({
                  success: true,
                  parsedXML: xmlResponse,
                  filename: fileName,
                });
              }
            };

            xhr.send(formData);
          } catch (err) {
            return throwError(new Error(`CSV Manager Import Upload Error: ${err.message}`));
          }
        },
        err => {
          return throwError(new Error(`CSV Manager Import Permissions Error: ${err.message}`));
        },
      );
    });

    return from(prom);
  }

  convertXmlToJson(xml: Document): Promise<any> {
    const xmlSerializer = new XMLSerializer();
    const xmlString = xmlSerializer.serializeToString(xml);
    return new Promise((resolve, reject) => {
      parseString(xmlString, (err, result) => {
        if (err) {
          reject(err);
        } else {
          for (let key in result.PostResponse) {
            result.PostResponse[key] = result.PostResponse[key][0];
          }
          resolve(result);
        }
      });
    });
  }

  // ---------------------------------------------------------------------------------------------------------------------------------//
}
