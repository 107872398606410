import { Component } from '@angular/core';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-provisional-blocker-modal',
  templateUrl: './provisional-blocker-modal.component.html',
  styleUrls: ['./provisional-blocker-modal.component.scss'],
})
export class ProvisionalBlockerModalComponent {
  faCheckCircle = faCheckCircle;

  constructor(private modalCtrl: ModalController) {}

  relyDismiss() {
    this.modalCtrl.dismiss();
  }
}
