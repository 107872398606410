import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusicons',
})
export class StatusiconsPipe implements PipeTransform {
  constructor() {}
  transform(value: any, type: string = '', iconsObjDef = null): any {
    //console.log("statusicons",value, type);
    // iconsObjDef = {
    //     Missing: {
    //       iconOne : 0, //checkcircle
    //       iconTwo : 0, //exclamation
    //     },
    //     Partial: {
    //       iconOne : 0, //checkcircle
    //       iconTwo : 1, //exclamation
    //     },
    //     Complete: {
    //       iconOne : 1, //checkcircle
    //       iconTwo : 0, //exclamation
    //     }
    //   }
    if (iconsObjDef && value && type) {
      const colorObj = iconsObjDef[value][type];
      if (colorObj == 0) {
        return 'gray';
      } else {
        //1
        if (type == 'iconOne') {
          return 'green';
        } else {
          //
          return 'orange';
        }
      }
    }
    return 'null-status';
  }
}
