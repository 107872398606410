import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-application-document-review-list',
  templateUrl: './application-document-review-list.component.html',
  styleUrls: ['./application-document-review-list.component.scss'],
})
export class ApplicationDocumentReviewListComponent implements OnInit {

  @Input() applicationid: string;

  constructor() { }

  ngOnInit() {}

}
