import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class InstitutionsService {
  public enrollmentSubject = new Subject<any>();

  constructor(private http: HttpClient) {}

  updateInstitution(form: any, institutionsId: string): Observable<any> {
    return this.http.put(
      `${environment.api}/departments/${institutionsId}/update`,
      form
    );
  }

  /**
   *
   * @param institutionsId string, institutionsId/user id
   * @description get institution details from AMOEMPLOYEE endpoint
   */
  amoGetInstitutionsDetails(institutionsId: string): Observable<any> {
    return this.http.get(
      `${environment.api}/departments/${institutionsId}/show`
    );
  }
  // /departments/[departmentid]/show
  // amoGetInstitutionsDetails(traineeId: string): Observable<any> {
  //   return this.http.get(`${environment.api}/users/${traineeId}`);
  // }

  // amoTraineeImmunizationDocuments(traineeId: string): Observable<any> {
  //   return this.http.get(
  //     `${environment.api}/users/${traineeId}/immunizationdocuments`
  //   );
  // }

  /**
   *
   * @param data
   * @returns observable was manager created
   * @description create new manager
   */
  amoCreateManager(data: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/create`, data);
  }

  /**
   *
   * @param data
   * @returns observable was manager created
   * @description create new manager
   */
  amoCreateInstitute(data: any): Observable<any> {
    return this.http.post(`${environment.api}/departments/create`, data);
  }

  /**
   *
   * @param data - { departmentid: string}
   * @returns observable was manager created - { success: boolean, haspayouts: boolean, message: string }
   * @description returns boolean haspayouts
   */
  institutionHasPayouts(data: any): Observable<any> {
    return this.http.post(`${environment.api}/departments/haspayouts`, data);
  }

  deleteInstitution(institutionId: string): Observable<any> {
    return this.http.delete(
      `${environment.api}/departments/${institutionId}/destroy`
    );
  }

  /**
   * Query for managers for auto complete selection interface
   * @param body
   */
  institutionSelectionQuery(body: any): Observable<any> {
    if (body) {
      return this.http.post(
        `${environment.api}/managements/finddepartmentquery`,
        body
      );
    }
  }

  exportGridToCsv(queryObject: any, columnHeaders: object[]): Observable<any> {
    return this.http.post(
      `${environment.api}/departments/exportinstitutionscsv`,
      {
        query: JSON.stringify(queryObject),
        columns: JSON.stringify(columnHeaders)
      }
    );
  }

  validateHubspotDepartment(departmentid: string, hubspotcompanyid: string): Observable<any> {
    return this.http.post(`${environment.api}/departments/${departmentid}/validateHubspotDepartment?ngsw-bypass=true`, { hubspotcompanyid });
  }
}
