import { Injectable } from "@angular/core";

import {
  HttpClientXsrfModule,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpXsrfTokenExtractor
} from "@angular/common/http";

import { environment } from "../../../environments/environment";
import { NavController } from "@ionic/angular";
import { CookieService } from "ngx-cookie-service";
import { from, Observable, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { AuthService } from "../services/auth/auth.service";
import { LoadingService } from "../services/loading/loading.service";

/** Auth Interceptor */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private cookie: CookieService,
    private navCtrl: NavController,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const promise = new Promise(resolve =>
      resolve(this.cookie.get("amoactkn"))
    );
    return from(promise).pipe(
      mergeMap(token => {
        const clonedReq = this.formatReq(req, token);
        return next.handle(clonedReq).pipe(
          catchError((error: any) => {
            if (error.status === 403) {
              if (error.error) {
                if (error.error.error) {
                  // window.location.reload(true);
                  return throwError(error);
                } else if (error.error.code) {
                  // window.location.reload(true);
                  return throwError(error);
                } else {
                  return throwError(error);
                }
              } else {
                return throwError(error);
              }
            } else if (error.status === 401) {
              this.authService.logout();
              this.navCtrl.navigateRoot(["/login"]);
              return throwError(error);
            } else {
              return throwError(error);
            }
          })
        );
      })
    );
  }
  // https://54mkffx395.execute-api.us-east-1.amazonaws.com/v0/download/immunization
  formatReq(request: HttpRequest<any>, token: any) {
    if (request.url.indexOf(`${environment.immunizations}`) !== -1) {
      let clone: HttpRequest<any>;
      let newHeaders = request.headers.delete("XSRF-TOKEN");
      let newHeaders2 = newHeaders.delete("adminappserviceversion");

      clone = request.clone({
        headers: newHeaders2,
        withCredentials: false
      });
      return clone;
    } else if (request.url.indexOf("document/documentexist") !== -1) {
      request.headers.delete("XSRF-TOKEN");
      //
      let clone: HttpRequest<any>;
      clone = request.clone({
        setHeaders: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        withCredentials: false
      });
      return clone;
    } else if (
      request.url.indexOf(`${environment.API_GATEWAY_PAYMENTS}`) !== -1
    ) {
      //
      let clone: HttpRequest<any>;
      let newHeaders4 = request.headers.set("Authorization", `Bearer ${token}`);

      clone = request.clone({
        headers: newHeaders4,
        withCredentials: true
      });

      return clone;
    } else if (
      request.url.indexOf("54mkffx395.execute-api.us-east-1.amazonaws.com") !==
      -1
    ) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        headers: new HttpHeaders(),
        withCredentials: false
      });
      return clone;
    } else if (request.url.indexOf(`${environment.countryCodeList}`) !== -1) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        headers: request.headers.delete("XSRF-TOKEN"),
        withCredentials: false
      });
      return clone;
    } else if (request.url.indexOf(environment.s3) !== -1) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        headers: new HttpHeaders(),
        withCredentials: false
      });
      return clone;
    } else if (request.url === environment.countryList) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        headers: new HttpHeaders(),
        withCredentials: false
      });
      return clone;
    } else if (request.url.indexOf("kwusau0m49.execute-api") !== -1) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        headers: new HttpHeaders(),
        withCredentials: false
      });
      return clone;
    } else if (request.url.indexOf("https://ipinfo.io") !== -1) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        headers: new HttpHeaders(),
        withCredentials: false
      });
      return clone;
    } else if (
      request.url.indexOf(
        "https://amo-static-enc.s3.amazonaws.com/text.json"
      ) !== -1
    ) {
      let clone: HttpRequest<any>;
      let newHeaders = request.headers.delete("XSRF-TOKEN");
      let newHeaders2 = newHeaders.delete("adminappserviceversion");

      clone = request.clone({
        headers: newHeaders2,
        withCredentials: false
      });
      return clone;
    } else if (request.url.indexOf("/no-search-result") !== -1) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        headers: new HttpHeaders(),
        withCredentials: false
      });
      return clone;
    } else if (request.url.indexOf(`${environment.contentUrlJSON}`) !== -1) {
      let clone: HttpRequest<any>;
      let newHeaders = request.headers.delete("XSRF-TOKEN");
      let newHeaders2 = newHeaders.delete("adminappserviceversion");

      clone = request.clone({
        headers: newHeaders2,
        withCredentials: true
      });
      return clone;
    } else if (token) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        setHeaders: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        withCredentials: true
      });
      return clone;
    } else {
      // public request delete xsrf token
      // set withcreds to false
      request.headers.delete("XSRF-TOKEN");
      //
      let clone: HttpRequest<any>;
      clone = request.clone({
        setHeaders: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        withCredentials: true
      });
      return clone;
    }
  }
}

/** XSRF Interceptor */
@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

  private excludeXsrfUrls: string[] = [
    "https://u1pn0i2tke.execute-api.us-west-2.amazonaws.com"
  ];

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headerName = "XSRF-TOKEN";
    const token = this.tokenExtractor.getToken() as string;
    HttpClientXsrfModule.withOptions({
      headerName: "XSRF-TOKEN",
      cookieName: "XSRF-TOKEN"
    });
    if (
      token !== null &&
      !req.headers.has(headerName) &&
      req.url !== environment.contentUrlJSON &&
      this.excludeXsrfUrls.some((url: string) => req.url.indexOf(url) === -1)
    ) {
      const cloneReq = this.addXsrf(req, headerName, token);
      return next.handle(cloneReq);
    } else {
      return next.handle(req);
    }
  }

  private addXsrf(req: HttpRequest<any>, headerName: string, token: string) {
    const clone = req.clone({
      headers: req.headers.set(headerName, token)
    });
    return clone;
  }
}

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        AdminAppServiceVersion: environment.ADMIN_VERSION
      }
    });
    return next.handle(req);
  }
}

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private requests: Array<HttpRequest<any>> = [];

  constructor(private loaderService: LoadingService) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    this.requests.splice(i, 1);
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(req);
    this.loaderService.isLoading.next(true);
    return Observable.create(observer => {
      const subscription = next.handle(req).subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        err => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      // teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
