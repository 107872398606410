<ion-content class="background-white">
  <ngx-img
    (onSelect)="onSelect($event)"
    (onReset)="reset()"
    [config]="options"
  ></ngx-img>
</ion-content>
<ion-footer class="ion-text-center">
  <ion-button (click)="upload()">
    <ion-icon slot="start" name="add"></ion-icon>
    Upload
  </ion-button>
</ion-footer>
