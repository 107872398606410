import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
@Component({
  selector: 'app-requirement-status-actions',
  templateUrl: './requirement-status-actions.component.html',
  styleUrls: ['./requirement-status-actions.component.scss'],
})
export class RequirementStatusActionsComponent {
  constructor(private popoverCtrl: PopoverController) {}

  faExclamationCircle = faExclamationCircle;
  faCheckCircle = faCheckCircle;

  sendEvaluation(status: String): void {
    this.popoverCtrl.dismiss({ evaluation: status });
  }
}
