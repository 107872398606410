import { on, Action, createReducer } from '@ngrx/store';
import { InstitutionsActions } from '../actions';

export const institutionFeatureKey = 'institutions';

export interface InstitutionsState {
  institutionsObject: {};
  manager_PermissionsList: Array<any>;
  manager_ShowAddComponent: boolean;
  edit_InstitutionHost: any;
  add_InstitutionHostToolbar: boolean;
}

export const initialState: InstitutionsState = {
  institutionsObject: {},
  //  manager_PermissionsList: [
  //    {
  //      value: "superuser",
  //      name: "Super User",
  //      checked: false
  //    },
  //    {
  //      value:
  //        "coach manager application:read application:write application:cancel applications:list application:actions users:list user:read user:write program:read program:list",
  //      name: " AMO Coach",
  //      checked: false
  //    },
  //    {
  //      value:
  //        "coordinator manager application:read application:write applications:list applications:export application:actions users:list user:read program:read program:write program:list program:export host:create host:write management:permissions:hosting:write",
  //      name: "AMO Coordinator",
  //      checked: false
  //    },
  //    {
  //      value:
  //        "advisor manager application:read application:write application:cancel applications:list application:actions users:list user:read user:write program:read program:list",
  //      name: "AMO Advisor",
  //      checked: false
  //    },
  //    // {
  //    //   value: "manager application:read applications:list applications:export users:list user:read user:write program:read program:list management:permissions:hosting:write management:permissions:recruitment:write management:visitorinvites",
  //    //   name: "AMO Partnerships",
  //    //   checked: false
  //    // },
  //    // {
  //    //   value: "manager application:read applications:list applications:export users:list user:read program:read program:list",
  //    //   name: "AMO Marketing",
  //    //   checked: false
  //    // },
  //    // {
  //    //   value: "manager application:read applications:list users:list user:read program:read program:list",
  //    //   name: "AMO Technology",
  //    //   checked: false
  //    // },
  //    {
  //      value:
  //        "hospitaladministrator application:read program:read program:hideprices view:paidapplicants user:read program:list",
  //      name: "Hospital Administrator Host (Read Only)",
  //      checked: false
  //    },
  //    {
  //      value:
  //        "visitorrecruitment application:read applications:list user:read users:list user:write management:visitorinvites program:list document:upload application:document:view",
  //      name: "Visitor Recruitment Role",
  //      checked: false
  //    }
  //    // {
  //    //   value: "program:read",
  //    //   name: "Program Read",
  //    //   checked: false
  //    // },
  //    // {
  //    //   value: "program:write program:read",
  //    //   name: "Program Read/Write",
  //    //   checked: false
  //    // },
  //    // {
  //    //   value: "application:read",
  //    //   name: "Application Read",
  //    //   checked: false
  //    // },
  //    // {
  //    //   value: "application:read application:write",
  //    //   name: "Application Read/Write",
  //    //   checked: false
  //    // },
  //    // {
  //    //   value: "program:read application:read",
  //    //   name: "Program Read and Application Read",
  //    //   checked: false
  //    // },
  //    // {
  //    //   value: "program:write program:read application:read application:write",
  //    //   name: "Program Read/Write and Application Read/Write",
  //    //   checked: false
  //    // }
  //  ],

  manager_PermissionsList: [
    {
      value: 'superuser',
      text: 'Superuser',
      disabled: false,
      stringValue: 'superuser',
      info: {
        applicationPermission: 'edit',
        userPermission: 'edit',
        programPermission: 'edit',
        hostPermission: 'edit',
        userVisitorInvites: true,
        applicationViewOnly: false,
      },
    },
    {
      value: 'admin',
      text: 'AMO Admin',
      disabled: false,
      stringValue:
        'admin application:read application:write applications:list applications:export application:cancel application:actions application:override users:list user:read user:write program:read program:write program:list program:export hosts:list host:write management:permissions:hosting:write management:permissions:recruitment:write management:visitorinvites application:document:view document:upload host:read',
      info: {
        applicationPermission: 'edit',
        userPermission: 'edit',
        programPermission: 'edit',
        hostPermission: 'edit',
        userVisitorInvites: true,
        applicationViewOnly: false,
      },
    },
    {
      value: 'manager',
      text: 'AMO Manager',
      disabled: false,
      stringValue:
        'manager application:read application:write applications:list application:actions application:cancel users:list user:read user:write program:read program:write program:list program:export application:document:view hosts:list host:read',
      info: {
        applicationPermission: 'edit',
        userPermission: 'edit',
        programPermission: 'edit',
        hostPermission: 'edit',
        userVisitorInvites: true,
        applicationViewOnly: false,
      },
    },
    {
      value: 'coach',
      text: 'AMO Coach',
      disabled: false,
      stringValue:
        'coach manager application:read application:write application:cancel applications:list application:actions users:list user:read user:write program:read program:list document:upload application:document:view hosts:list host:read',
      info: {
        applicationPermission: 'edit',
        userPermission: 'edit',
        programPermission: 'read',
        hostPermission: 'none',
        userVisitorInvites: false,
        applicationViewOnly: false,
      },
    },
    {
      value: 'coordinator',
      text: 'AMO Coordinator',
      disabled: false,
      stringValue:
        'coordinator manager application:read application:write applications:list applications:export application:actions users:list user:read program:read program:write program:list program:export host:create host:write management:permissions:hosting:write document:upload application:document:view hosts:list host:read',
      info: {
        applicationPermission: 'edit',
        userPermission: 'read',
        programPermission: 'edit',
        hostPermission: 'edit',
        userVisitorInvites: false,
        applicationViewOnly: false,
      },
    },
    {
      value: 'advisor',
      text: 'AMO Advisor',
      disabled: false,
      stringValue:
        'advisor manager application:read application:write application:cancel applications:list application:actions users:list user:read user:write program:read program:list document:upload hosts:list host:read',
      info: {
        applicationPermission: 'edit',
        userPermission: 'edit',
        programPermission: 'read',
        hostPermission: 'none',
        userVisitorInvites: false,
        applicationViewOnly: false,
      },
    },
    {
      value: 'partnerships',
      text: 'AMO Partnership',
      disabled: false,
      stringValue:
        'manager application:read applications:list applications:export users:list user:read user:write program:read program:list management:permissions:hosting:write management:permissions:recruitment:write management:visitorinvites document:upload application:document:view hosts:list host:read',
      info: {
        applicationPermission: 'read',
        userPermission: 'edit',
        programPermission: 'read',
        hostPermission: 'none',
        userVisitorInvites: true,
        applicationViewOnly: false,
      },
    },
    {
      value: 'marketing',
      text: 'AMO Marketing',
      disabled: false,
      stringValue: 'manager application:read applications:list applications:export users:list user:read program:read program:list hosts:list host:read',
      info: {
        applicationPermission: 'read',
        userPermission: 'read',
        programPermission: 'read',
        hostPermission: 'none',
        userVisitorInvites: false,
        applicationViewOnly: false,
      },
    },
    {
      value: 'techology',
      text: 'AMO Techology',
      disabled: false,
      stringValue: 'manager application:read applications:list users:list user:read program:read program:list application:document:view hosts:list host:read',
      info: {
        applicationPermission: 'read',
        userPermission: 'read',
        programPermission: 'read',
        hostPermission: 'none',
        userVisitorInvites: false,
        applicationViewOnly: false,
      },
    },
    {
      value: 'visitorrecruitment',
      text: 'Visitor Recruitment',
      disabled: false,
      stringValue:
        'visitorrecruitment application:read applications:list user:write user:read users:list users:export management:visitorinvites program:list document:upload application:document:view',
      info: {
        applicationPermission: 'read',
        userPermission: 'edit',
        programPermission: 'none',
        hostPermission: 'none',
        userVisitorInvites: true,
        applicationViewOnly: false,
      },
    },
    {
      value: 'visitorrecruitment:custom',
      text: 'Visitor Recruitment (Custom)',
      disabled: false,
      stringValue: '',
      info: {
        applicationPermission: 'read',
        userPermission: 'edit',
        programPermission: 'none',
        hostPermission: 'none',
        userVisitorInvites: true,
        applicationViewOnly: false,
      },
    },
    {
      value: 'hospitaladministrator',
      text: 'Hospital Administrator (Read Only)',
      disabled: false,
      stringValue:
        'hospitaladministrator application:read applications:list user:read program:read program:list program:hideprices hosts:list view:paidapplicants grid:hospitaladministrator application:document:view',
      info: {
        applicationPermission: 'read',
        userPermission: 'none',
        programPermission: 'read',
        hostPermission: 'read',
        userVisitorInvites: false,
        applicationViewOnly: true,
      },
    },
    {
      value: 'hospitaladministrator:custom',
      text: 'Hospital Administrator (Custom)',
      disabled: false,
      stringValue: '',
      info: {
        applicationPermission: 'read',
        userPermission: 'none',
        programPermission: 'read',
        hostPermission: 'read',
        userVisitorInvites: false,
        applicationViewOnly: true,
      },
    },
  ],
  manager_ShowAddComponent: false,
  edit_InstitutionHost: false,
  add_InstitutionHostToolbar: false,
};

const InstitutionsReducer = createReducer(
  initialState,
  on(InstitutionsActions.setInstitutions, (state, args) => ({
    ...state,
    institutionsObject: args.institutionsObject,
  })),
  on(InstitutionsActions.setManagerPermissionsList, (state, args) => ({
    ...state,
    manager_PermissionsList: args.manager_PermissionsList,
  })),
  on(InstitutionsActions.setShowManagerAddComponent, (state, args) => ({
    ...state,
    manager_ShowAddComponent: args.manager_ShowAddComponent,
  })),
  on(InstitutionsActions.setEditInstituteHost, (state, args) => ({
    ...state,
    edit_InstitutionHost: args.edit_InstitutionHost,
  })),
  on(InstitutionsActions.setAddInstitutionHostToolbar, (state, args) => ({
    ...state,
    add_InstitutionHostToolbar: args.add_InstitutionHostToolbar,
  })),
);

export function reducer(state: InstitutionsState | undefined, action: Action) {
  return InstitutionsReducer(state, action);
}
