import { Component, OnInit, Input } from "@angular/core";
import {
  faCheckCircle,
  faCheckDouble,
  faTrash,
  faTimesCircle
} from "@fortawesome/pro-regular-svg-icons";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons";
import { ToolsService } from "src/app/core/services/tools/tools.service";
import {
  AlertController,
  NavController,
  PopoverController
} from "@ionic/angular";
import { ToastController } from "@ionic/angular";
import { ApplicationService } from "src/app/core/services/application/application.service";
import { State } from "src/app/core/ngrx/reducers";
import { select, Store } from "@ngrx/store";
import {
  applicationSelectors,
  userSelectors
} from "src/app/core/ngrx/selectors";
import {
  ActivatedRoute,
  NavigationExtras,
  ParamMap,
  Params,
  Router
} from "@angular/router";
import { Location } from "@angular/common";
import { SmartToastService } from "../../services/smartToast.service";
import { InstitutionsService } from "src/app/core/services/institutions/institutions.service";

@Component({
  selector: "app-institution-actions",
  templateUrl: "./institution-actions.component.html",
  styleUrls: ["./institution-actions.component.scss"]
})
export class InstitutionActionsComponent implements OnInit {
  faCheckCircle = faCheckCircle;
  faCheckDouble = faCheckDouble;
  faTrash = faTrash;
  faTimesCircle = faTimesCircle;
  faThumbtack = faThumbtack;

  @Input() institutionid;
  @Input() type;

  user$ = this.store.pipe(select(userSelectors.userProfile));
  user: any;

  managerType$ = this.store.pipe(select(userSelectors.isManagementType));

  constructor(
    private router: Router,
    private location: Location,
    private store: Store<State>,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private toolService: ToolsService,
    private popCtrl: PopoverController,
    private alertController: AlertController,
    private toastController: ToastController,
    private applicationService: ApplicationService,
    private smartToastService: SmartToastService,
    private institutionsService: InstitutionsService
  ) {}

  async presentToast(message, type) {
    const toast = await this.toastController.create({
      message,
      color: type,
      duration: 10000,
      buttons: [
        {
          text: "Close",
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          }
        }
      ],
      position: "top"
    });
    toast.present();
  }

  private async confirmationAlert(message: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>((resolve) => {
      resolveFunction = resolve;
    });
    const alert = await this.alertController.create({
      header: "Confirmation",
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: "No",
          handler: () => resolveFunction(false)
        },
        {
          text: "Yes",
          handler: () => resolveFunction(true)
        }
      ]
    });
    await alert.present();
    return promise;
  }

  public async errorAlert() {
    const alert = await this.alertController.create({
      header: "Attention",
      message: "You do not have permissions needed to complete this action.",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary"
        }
      ]
    });

    await alert.present();
  }

  ngOnInit() {}

  public async newManager() {
    this.popCtrl.dismiss();

    this.navCtrl.navigateForward(
      "/institutions/details/managers?id=" +
        this.institutionid +
        "&newmanager=true"
    );
  }

  public async newHost() {
    this.popCtrl.dismiss();

    this.navCtrl.navigateForward(
      "/institutions/details/hosts/add?id=" +
        this.institutionid +
        "&newhost=true"
    );
  }

  public async deleteInstitution() {
    const confirm = await this.confirmationAlert(
      "Are you sure you want to delete this application?"
    );
    if (confirm) {
      if (this.institutionid) {
        this.institutionsService
          .deleteInstitution(this.institutionid)
          .subscribe(
            (res) => {
              this.presentToast("Institution Deleted", "success");
              this.popCtrl.dismiss();
              this.navCtrl.navigateForward("/institutions");
            },
            (err) => this.errorAlert()
          );
      }
    }
  }
  // https://stackoverflow.com/questions/40983055/how-to-reload-the-current-route-with-the-angular-2-router
  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
