<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <div>
          <div class="ion-padding-staging">
            <ion-list-header class="ion-text-center">
              <ion-label>Update Phone</ion-label>
            </ion-list-header>
            <div class="ion-margin-start ion-margin-end form-input">
              <amo-input
                [(ngModel)]="phone"
                ngDefaultControl
                type="phone"
                name="phone"
                label="Current mobile phone"
                labelcolor="labelgreen"
                size="inputwidth-100"
                inputname="phone"
                disabled="true"
              >
              </amo-input>
            </div>
            <div
              class="ion-padding-start ion-padding-bottom ion-margin-top ion-margin-bottom ion-padding-end"
            >
              <ion-label class="input-label-text" color="dark-teal"
                >New mobile phone</ion-label
              >
              <div class="">
                <amo-phonepicker-input
                  (onInputChange)="getPhoneInput($event)"
                  [componentsettings]="componentsettings"
                  [itemdata]="itemdata | propStringify"
                >
                </amo-phonepicker-input>
              </div>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar>
    <div class="btn-flex">
      <div>
        <ion-button
          fill="outline"
          color="secondary"
          size="medium"
          type="button"
          class="default-btn"
          (click)="dismiss(false)"
        >
          Cancel
        </ion-button>
      </div>
      <div>
        <ion-button
          (click)="openPhoneVerifyModal()"
          [disabled]="!newPhone"
          fill="solid"
          color="secondary"
          size="medium"
          type="button"
          class="default-btn"
        >
          Submit
        </ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>
