import { on, Action, createReducer } from '@ngrx/store';
import { UserActions } from '../actions';

export const userFeatureKey = 'user';

export interface UserState {
  isReadOnly: boolean; // is user not able to make edits/create
  isApplicationReadOnly: boolean;
  isProgramReadOnly: boolean;
  isHostReadOnly: boolean;
  isTraineeReadOnly: boolean;
  isPaymentReadOnly: boolean;
  isManagerReadOnly: boolean;
  isSuperUser: boolean;
  canProgramWrite: boolean;
  canDocumentUpload: boolean; // can upload documents for trainee/applications
  canDocumentView: boolean; // can view documents from trainee/applications
  isManagementType: string; // managers role of hosting, recruitment, or amo employee
  userProfile: {};
  canOrderEdit: boolean;
}

export const initialState: UserState = {
  isReadOnly: false,
  isManagementType: '',
  userProfile: {},
  isApplicationReadOnly: false,
  isProgramReadOnly: false,
  isHostReadOnly: false,
  isTraineeReadOnly: false,
  isPaymentReadOnly: false,
  isManagerReadOnly: false,
  isSuperUser: false,
  canDocumentUpload: false,
  canDocumentView: false,
  canProgramWrite: false,
  canOrderEdit: false,
};

const UserReducer = createReducer(
  initialState,
  on(UserActions.setReadOnly, (state, args) => ({
    ...state,
    isReadOnly: args.isReadOnly,
  })),
  on(UserActions.setApplicationReadOnly, (state, args) => ({
    ...state,
    isApplicationReadOnly: args.isApplicationReadOnly,
  })),
  on(UserActions.setProgramReadOnly, (state, args) => ({
    ...state,
    isProgramReadOnly: args.isProgramReadOnly,
  })),
  on(UserActions.setHostReadOnly, (state, args) => ({
    ...state,
    isHostReadOnly: args.isHostReadOnly,
  })),
  on(UserActions.setTraineeReadOnly, (state, args) => ({
    ...state,
    isTraineeReadOnly: args.isTraineeReadOnly,
  })),
  on(UserActions.setPaymentReadOnly, (state, args) => ({
    ...state,
    isPaymentReadOnly: args.isPaymentReadOnly,
  })),
  on(UserActions.setManagerReadOnly, (state, args) => ({
    ...state,
    isManagerReadOnly: args.isManagerReadOnly,
  })),
  on(UserActions.setSuperUser, (state, args) => ({
    ...state,
    isSuperUser: args.isSuperUser,
  })),
  on(UserActions.setManagementType, (state, args) => ({
    ...state,
    isManagementType: args.isManagementType,
  })),
  on(UserActions.setUser, (state, args) => ({
    ...state,
    userProfile: args.userProfile,
  })),
  on(UserActions.setDocumentUpload, (state, args) => ({
    ...state,
    canDocumentUpload: args.canDocumentUpload,
  })),
  on(UserActions.setDocumentView, (state, args) => ({
    ...state,
    canDocumentView: args.canDocumentView,
  })),
  on(UserActions.setProgramWrite, (state, args) => ({
    ...state,
    canProgramWrite: args.canProgramWrite,
  })),
  on(UserActions.setOrderEdit, (state, args) => ({
    ...state,
    canOrderEdit: args.canOrderEdit,
  })),
);

export function reducer(state: UserState | undefined, action: Action) {
  return UserReducer(state, action);
}
