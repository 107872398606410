import { createAction, props } from "@ngrx/store";

export const setInstitutions = createAction(
  //setInstitutions
  "[institutions] Set institutions",
  props<{ institutionsObject: object }>()
);
// new manager permissions list
export const setManagerPermissionsList = createAction(
  //setInstitutions
  "[institutions] Set managers permissions list",
  props<{ manager_PermissionsList: Array<any> }>()
);
// dispaly manager-add component
export const setShowManagerAddComponent = createAction(
  //setInstitutions
  "[institutions] Set Show Manager Add Component",
  props<{ manager_ShowAddComponent: boolean }>()
);
// host object for edit
export const setEditInstituteHost = createAction(
  //setInstitutions
  "[institutions] Set Edit Institute Host",
  props<{ edit_InstitutionHost: any }>()
);
// dispaly toolbar w/ button for institution host information host add component
export const setAddInstitutionHostToolbar = createAction(
  "[institutions] Set Add Institution Host Toolbar",
  props<{ add_InstitutionHostToolbar: boolean }>()
);