import { createSelector } from "@ngrx/store";
import { State } from "../reducers";

export const selectInstitutionsState = (state: State) => state.institutions;

export const institutionsObject = createSelector(
  selectInstitutionsState,
  institutions => institutions.institutionsObject
);
//
export const getManagersPermissionsList = createSelector(
  selectInstitutionsState,
  institutions => institutions.manager_PermissionsList
);
//
export const getShowManagerAddComponent = createSelector(
  selectInstitutionsState,
  institutions => institutions.manager_ShowAddComponent
);
// get editable host
export const getEditInstituteHost = createSelector(
  selectInstitutionsState,
  institutions => institutions.edit_InstitutionHost
);
// get enable create host toolbat
export const getAddInstituteHostToolbar = createSelector(
  selectInstitutionsState,
  institutions => institutions.add_InstitutionHostToolbar
);