<ion-list lines="none">
  <ion-item button (click)="processSwitch()">
    <ion-label>
      <fa-icon slot="start" [icon]="faCheckCircle"></fa-icon>

      Process Switch</ion-label
    >
  </ion-item>
  <ion-item button (click)="cancelSwitch()">
    <ion-label>
      <fa-icon slot="start" [icon]="faBan"></fa-icon>

      Cancel Hold</ion-label
    >
  </ion-item>
  <ion-item button (click)="removeSwitch()">
    <ion-label>
      <fa-icon slot="start" [icon]="faTrash"></fa-icon>

      Remove Hold
    </ion-label>
  </ion-item>
</ion-list>
