import { createAction, props } from "@ngrx/store";

export const setApplication = createAction(
  "[Application] Set Application",
  props<{ applicationObject: object }>()
);

export const setAdminList = createAction(
  "[Application] Set Admin List",
  props<{ adminList: any[] }>()
);

export const setSubmittedApplicationRequirementList = createAction(
  "[Application] Set Submitted Application Requirement List",
  props<{ submittedRequirementList: any[] }>()
);

export const setApplicationTraineeShowSave = createAction(
  "[Application] Set Application Trainee Information Show Save",
  props<{ application_TraineeShowSave: boolean }>()
);

export const setSave = createAction(
  "[Application] Set Application Save",
  props<{ applicationSave: object }>()
);
