import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AuthService } from "@Services/auth/auth.service";
import { ModalService, Warning } from "@shared/services";
import { SmartToastService } from "@shared/services/smartToast.service";
import { Auth } from "aws-amplify";

@Component({
  selector: "app-auth-update-modal",
  templateUrl: "./auth-update-modal.component.html",
  styleUrls: ["./auth-update-modal.component.scss"]
})
export class AuthUpdateModalComponent implements OnInit {
  @Input() type: string;
  @Input() user: any;

  email: string;
  newEmail: string;

  constructor(
    private smartToastService: SmartToastService,
    private modalCtrl: ModalController,
    private warning: Warning,
    private modalService: ModalService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getAuthUser();
  }

  async getAuthUser() {
    const user = await Auth.currentUserInfo();

    this.email = user.attributes.email;
  }

  openConfirmationModal() {
    // update the user database values then update the user cognito values
    this.authService
      .updateAdminAttributes({ email: this.newEmail })
      .subscribe((response) => {
        if (response.success) {
          this.modalService
            .authConfirmEmailModal({
              email: this.newEmail,
              user: this.user,
              type: "email"
            })
            .then((status: any) => {
              if (status.verified) {
                this.smartToastService.show(
                  "Successfully Updated Email",
                  "success",
                  10000
                );
                this.dismiss(true);
              } else if (!status) {
                this.smartToastService.show(
                  "We could not verify your email address.",
                  "warning",
                  10000
                );
                this.newEmail = null;
              }
            });
        } else {
          this.warning.create(
            "There was an issue with updating your email. Please contact support for help resolving this issue."
          );
        }
      });
  }

  async updateAuthUser() {
    if (this.newEmail) {
      const user = await Auth.currentAuthenticatedUser();
      let result = await Auth.updateUserAttributes(user, {
        email: this.newEmail
      });

      if (result == "SUCCESS") {
        this.smartToastService.show(
          "Successfully Update Email",
          "success",
          10000
        );
        this.dismiss(true);
      } else {
        this.warning.create("Invalid Email");
        this.newEmail = null;
      }
    }
  }

  dismiss(fetch: boolean) {
    this.modalCtrl.dismiss(fetch);
  }
}
