import { createAction, props } from "@ngrx/store";

export const setIsAuthenticated = createAction(
    '[Auth] Set is Authenticated',
    props<{ is_authenticated: boolean }>()
);

export const setLogout = createAction(
    '[Auth] Set Is Not Authenticated',
    props<{ is_authenticated: boolean }>()
);
// system permissions
export const setSystemPermissions = createAction(
    '[Auth] Set System Permissions',
    props<{ systemPermissions: string }>()
);
// set is employee
export const setIsEmployee = createAction(
    '[Auth] Set Is Employee',
    props<{ isEmployee: boolean }>()
);