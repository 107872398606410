import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core";
import { faEnvelope, faMobile } from "@fortawesome/pro-solid-svg-icons";
import { ModalController } from "@ionic/angular";
import { Warning } from "@shared/services";
import { SmartToastService } from "@shared/services/smartToast.service";
import { Auth } from "aws-amplify";

@Component({
  selector: "app-auth-verify-creds-modal",
  templateUrl: "./auth-verify-creds-modal.component.html",
  styleUrls: ["./auth-verify-creds-modal.component.scss"]
})
export class AuthVerifyCredsModalComponent implements OnInit {
  @Input() user: any;
  @Input() email: string;
  @Input() phone: string;
  @Input() oldPhone: string;
  @Input() type: string;

  faEnvelope = faEnvelope;
  faMobile = faMobile;

  code: string;

  constructor(
    private modalCtrl: ModalController,
    private warning: Warning,
    private smartToastService: SmartToastService
  ) {}

  ngOnInit() {
    if (this.type !== "confirm_signup") {
      this.updateAuthUser();
    }
  }

  async sendCodeToEmail() {
    Auth.verifyCurrentUserAttribute("email")
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  }

  async sendCodeToPhone() {
    Auth.verifyCurrentUserAttribute("phone_number")
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  }

  async sendConfirmCode() {
    try {
      await Auth.resendSignUp(this.email);
    } catch (error) {
      this.warning.create(error);
    }
  }

  async updateAuthUser() {
    const user = await Auth.currentAuthenticatedUser();

    if (this.type == "email") {
      Auth.updateUserAttributes(user, {
        email: this.email
      });
    } else if (this.type == "phone") {
      try {
        Auth.updateUserAttributes(user, {
          phone_number: this.phone
        }).then(() => {
          Auth.verifyCurrentUserAttribute("phone_number");
        });
      } catch (error) {
        this.warning.create(error);
        if (this.oldPhone) {
          Auth.updateUserAttributes(user, { phone_number: this.oldPhone });
        }
      }
    }
  }

  resendCode() {
    if (this.type === "email") {
      this.sendCodeToEmail();
    } else if (this.type === "phone") {
      this.sendCodeToPhone();
    } else if (this.type === "confirm_signup") {
      this.sendConfirmCode();
    }
    this.smartToastService.show(
      "A new verification code has been sent",
      "success",
      10000
    );
  }

  configureVerifyFunction() {
    if (this.type === "confirm_signup") {
      this.verifySignup();
    } else {
      this.verifyCodeFromEmail();
    }
  }

  async verifyCodeFromEmail() {
    if (this.code) {
      const attribute = this.type === "email" ? "email" : "phone_number";
      Auth.verifyCurrentUserAttributeSubmit(attribute, this.code)
        .then(() => {
          this.dismiss(true);
        })
        .catch((e) => {
          console.log("failed with error", e);
          this.warning.create("Invalid Code", "Unsuccessful Action");
          // this.dismiss(false);
        });
    }
  }

  async verifySignup() {
    if (this.code && this.type === "confirm_signup") {
      try {
        await Auth.confirmSignUp(this.email, this.code).then((response) => {
          this.dismiss(true);
        });
      } catch (error) {
        this.warning.create(error);
      }
    }
  }

  changeEmail() {
    this.dismiss(false);
  }

  dismiss(status: boolean) {
    this.modalCtrl.dismiss({ verified: status });
  }
}
