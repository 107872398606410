import { createAction, props } from '@ngrx/store';

export const setReadOnly = createAction('[User] Set Read Only', props<{ isReadOnly: boolean }>());

export const setApplicationReadOnly = createAction('[User] Set Application Read Only', props<{ isApplicationReadOnly: boolean }>());

export const setProgramReadOnly = createAction('[User] Set Program Read Only', props<{ isProgramReadOnly: boolean }>());

export const setHostReadOnly = createAction('[User] Set Host Read Only', props<{ isHostReadOnly: boolean }>());

export const setTraineeReadOnly = createAction('[User] Set Trainee Read Only', props<{ isTraineeReadOnly: boolean }>());

export const setPaymentReadOnly = createAction('[User] Set Payment Read Only', props<{ isPaymentReadOnly: boolean }>());

export const setManagerReadOnly = createAction('[User] Set Manager Read Only', props<{ isManagerReadOnly: boolean }>());

export const setSuperUser = createAction('[User] Set SuperUser', props<{ isSuperUser: boolean }>());

export const setManagementType = createAction('[User] Set Management Role', props<{ isManagementType: string }>());

export const setUser = createAction('[User] Set User Profile', props<{ userProfile: object }>());

export const setDocumentUpload = createAction('[User] Set Document Upload', props<{ canDocumentUpload: boolean }>());

export const setDocumentView = createAction('[User] Set Document View', props<{ canDocumentView: boolean }>());

export const setProgramWrite = createAction('[User] Set Program Write', props<{ canProgramWrite: boolean }>());

export const setOrderEdit = createAction('[User] Set Order Edit', props<{ canOrderEdit: boolean }>());
