<ion-content>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-row class="main-wrapper">
    <ion-col size="2" class="p-0 side-nav-div" *ngIf="isMultiDoc">
      <ion-list class="p-0 doc-list">
        <ion-item lines="full" class="side-menu-item" *ngFor="let item of url; let i = index" [class.active-doc]="selectedIndex === i">
          <ion-label>
            <a class="anchor-link teal-color" (click)="viewImmunization(item.Key || item.key); selectedIndex = i">
              {{ item.Key?.split('/').pop() || item.key?.split('/').pop() }}
              <br />
              {{ item.LastModified | date : 'MM/dd/yyyy h:mm a' : 'UTC' }}
            </a>
            <br />
            <a *ngIf="item.Key" class="clear-button anchor-link teal-color" (click)="clearImmunizationDocuments(item.Key, i)"> Clear File </a>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-col>

    <ion-col [size]="isImmunization ? 8 : isMultiDoc ? 10 : 12" class="ion-no-padding">
      <div *ngIf="showDocument" class="dashboard-document-container doc-container">
        <ngx-doc-viewer [viewerUrl]="currentDocument" [url]="currentDocument" [viewer]="documentFormat" style="width: 100%; height: 95%"> </ngx-doc-viewer>
      </div>
    </ion-col>

    <ion-col size="2" class="p-0" *ngIf="isMultiDoc && newDesign === true" class="side-nav-div">
      <div class="header-div">
        <div>
          <p class="title-txt">Immunization List</p>
          <p class="sub">
            <fa-icon [icon]="icons.checkcircle"></fa-icon>
            <fa-icon [icon]="icons.exclamation"></fa-icon>
            Missing Requirement
          </p>
          <p class="sub">
            <!-- remove -->
            <fa-icon [icon]="icons.checkcircle"></fa-icon>
            <fa-icon [icon]="icons.exclamation" class="partial"></fa-icon>
            Partial Completion
          </p>
          <p class="sub">
            <fa-icon [icon]="icons.checkcircle" class="complete"></fa-icon>
            <!-- remove -->
            <fa-icon [icon]="icons.exclamation"></fa-icon>
            Complete
          </p>
        </div>
        <p class="sub-info">The AMO Form should be completed, signed, and stamped by the trainee’s physician.</p>
      </div>
      <div class="middle-div">
        <ion-list class="p-0 doc-list">
          <ng-container *ngFor="let item of immunizationItemList; let i = index">
            <ion-item slot="header" lines="full" class="side-menu-item immune-list-items" [class.active-doctemp]="selectedIndex === i">
              <div slot="start" class="status-icons">
                <fa-icon
                  [icon]="icons.checkcircle"
                  (click)="optionsChange(i, 'iconOne', item.status)"
                  class="iconOne"
                  [ngClass]="item.status | statusicons : 'iconOne' : iconsIndication"
                ></fa-icon>
                <fa-icon
                  [icon]="icons.exclamation"
                  (click)="optionsChange(i, 'iconTwo', item.status)"
                  class="iconTwo"
                  [ngClass]="item.status | statusicons : 'iconTwo' : iconsIndication"
                ></fa-icon>
              </div>

              <small class="anchor-link teal-color">
                {{ item.name }}
                <!-- <br />
                        {{ item.LastModified | date : 'MM/dd/yyyy h:mm a' : 'UTC' }} -->
              </small>
              <!-- <br />
                    <a *ngIf="item.Key && clear == true" class="clear-button anchor-link teal-color" (click)="clearImmunizationDocuments(item.Key, i)">
                        Clear File
                    </a> -->

              <fa-icon (click)="dropdown(i)" [icon]="openeditem == i ? icons.up : icons.down" slot="end" class="ddi"></fa-icon>
              <!-- </ion-label> -->
            </ion-item>
            <div class="ion-padding item-txt-content" slot="content" [style.display]="openeditem == i ? 'block' : 'none'">
              {{ item.content }}
            </div>
          </ng-container>
        </ion-list>

        <ion-accordion-group class="p-0 doc-list ion-hide">
          <ion-accordion *ngFor="let item of immunizationItemList; let i = index">
            <ion-item slot="header" class="side-menu-item immune-list-items" value="i" [class.active-doctemp]="selectedIndex === i">
              <!-- <div slot="start" class="status-icons">
                    <fa-icon [icon]="icons.checkcircle" (click)="optionsChange(i, 'iconOne', item.status)" class="iconOne"
                    [ngClass]="item.status | statusicons : 'iconOne' : iconsIndication"
                    ></fa-icon> 
                    <fa-icon [icon]="icons.exclamation" (click)="optionsChange(i, 'iconTwo', item.status)" class="iconTwo"
                    [ngClass]="item.status | statusicons : 'iconTwo' : iconsIndication"
                    ></fa-icon>
                </div> -->

              <p class="anchor-link teal-color">
                {{ item.content }}
                <!-- <br />
                    {{ item.LastModified | date : 'MM/dd/yyyy h:mm a' : 'UTC' }} -->
              </p>
              <!-- <br />
                <a *ngIf="item.Key && clear == true" class="clear-button anchor-link teal-color" (click)="clearImmunizationDocuments(item.Key, i)">
                    Clear File
                </a> -->

              <fa-icon (click)="dropdown(i)" [icon]="icons.down" slot="end" class="ddi"></fa-icon>
            </ion-item>
            <div class="ion-padding" slot="content">
              {{ item.content }}
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </div>
      <div class="bottom-div">
        <div>
          <ion-button (click)="saveImmunization()" fill="solid" color="secondary">Save</ion-button>
        </div>
        <div>
          <ion-button (click)="closeModal()" fill="outline" color="secondary">Cancel</ion-button>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
