import { Component, OnInit } from '@angular/core';
import { ToolsService } from 'src/app/core/services/tools/tools.service';

@Component({
  selector: 'app-lists-help',
  templateUrl: './lists-help.component.html',
  styleUrls: ['./lists-help.component.scss'],
})
export class ListsHelpComponent implements OnInit {

  constructor(
    public toolService: ToolsService
  ) { }

  ngOnInit() {}

}
