import { Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: "app-program-bundle-actions-dropdown",
  templateUrl: "./program-bundle-actions-dropdown.component.html",
  styleUrls: ["./program-bundle-actions-dropdown.component.scss"]
})
export class ProgramBundleActionsDropdownComponent implements OnInit {
  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit() {}

  export(exportMethod: string) {
    this.popoverCtrl.dismiss(exportMethod);
  }
}
