import { Component, Input, OnInit } from '@angular/core';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-activity-log-actions',
  templateUrl: './activity-log-actions.component.html',
  styleUrls: ['./activity-log-actions.component.scss'],
})
export class ActivityLogActionsComponent implements OnInit {
  showAll: boolean = true;
  actionTypes: any = {
    // profileAccount: true,
    // upload: true,
    // application: true,
    // payment: true,

    user: true, //Trainee Activity Log
    application: true, //Application Activity Log
    department: true, //Institution Activity Log
    program: true, //Program Activity Log
    payment: true, //Payment activity log
    host: true, //Host Activity Log
  };
  adminUsers: any;
  activeItem: string;
  prefAdmin: any;
  adminProps: any;
  adminFilteredList: any;
  adminFilterKey: any;
  adminSelected: string[] = [];

  faSearch = faSearch;

  readOnly: boolean = false;
  @Input() action: any;
  @Input() range: any;
  @Input() open: string;
  @Input() adminList: any;

  constructor(private popoverCtrl: PopoverController) {}

  ngOnInit() {
    if (this.open == 'logActions') {
      this.actionTypes = this.action;
      this.setShowAll();
    } else if (this.open == 'adminActions') {
      //console.log(this.adminUsers);

      this.adminProps = this.adminList.map(b => {
        return { optionText: b.name, optionValue: b.id };
      });

      this.adminUsers = JSON.stringify({
        id: 'admins',
        optionItems: this.adminProps,
      });

      this.prefAdmin = this.action;

      this.adminSelected = this.action;
      this.adminFilteredList = this.adminProps;

      this.adminFilteredList.forEach(admin => {
        if (this.adminSelected.includes(admin.optionValue)) {
          admin.value = true;
        } else {
          admin.value = false;
        }
      });

      //console.log(this.prefAdmin,"received to modal");
    } else if (this.open == 'dateActions') {
      this.activeItem = this.action;
    }
  }

  actionOpts(type: string) {
    if (type == 'showAll') {
      if (this.showAll == true) {
        Object.keys(this.actionTypes).map(e => (this.actionTypes[e] = true));
      } else {
        Object.keys(this.actionTypes).map(e => (this.actionTypes[e] = false));
      }
    } else {
      this.setShowAll();
    }
    setTimeout(() => this.popoverCtrl.dismiss(this.actionTypes), 1500);
  }

  setShowAll() {
    this.showAll = Object.values(this.actionTypes).every(e => e == true); //on all true or not
  }

  /* 
  this fnc sends to the activity-log.component.ts it formats as an object to 
  accommodate daterange for mat-calendar. Custom Range is only received from EventEmitter
  rage-calendar.component.ts
  */
  dateAction(selection: any, customRange = null) {
    this.activeItem = selection;

    selection = {
      type: selection,
      range: customRange,
    };

    // clear override, when hitting clear it sends Custom Range with null, when this happens return to "None"
    if (selection.type === 'Custom Range' && !selection.range) {
      selection = {
        type: 'None',
        range: null,
      };
    }

    setTimeout(() => this.popoverCtrl.dismiss(selection), 1500);
  }

  // adminsEvent(ev: CustomEvent) {
  //   if (ev.detail.actionType === 'selection') {
  //     this.prefAdmin = this.getArrayObj(ev.detail.value);
  //     //console.log(this.prefAdmin);
  //     setTimeout(() => this.popoverCtrl.dismiss(this.prefAdmin), 1500);
  //   } else if (ev.detail.actionType === 'search') {
  //     const filterKey = ev.detail.value.toLowerCase();
  //     if (filterKey !== null && filterKey.length > 0) {
  //       const filteredList = [...this.adminProps]
  //         .filter(items => {
  //           if (items.optionText.toLowerCase().indexOf(filterKey) > -1) {
  //             return items;
  //           }
  //         })
  //         .sort((a, b) => a.optionText.localeCompare(b.optionText));

  //       this.adminUsers = JSON.stringify({
  //         id: 'admins',
  //         optionItems: filteredList,
  //       });
  //     }
  //   } else if (ev.detail.actionType === 'reset') {
  //     this.adminUsers = JSON.stringify({
  //       id: 'admins',
  //       optionItems: this.adminProps,
  //     });
  //   }
  // }

  // passing an event to (ngModelChange) only returns input string
  adminFilter(ev: string) {
    // the 'reset' from old function is just empty string, select handled by the checkboxes
    if (ev) {
      const filterKey = ev;
      const filteredList = [...this.adminProps]
        .filter(items => {
          if (items.optionText.toLowerCase().indexOf(filterKey) > -1) {
            return items;
          }
        })
        .sort((a, b) => a.optionText.localeCompare(b.optionText));

      this.adminFilteredList = filteredList;
    } else {
      this.adminFilteredList = this.adminProps;
    }
  }

  adminSelect(ev: CustomEvent) {
    // the id property is where the text for the selection is stored
    const target = ev.target as HTMLInputElement;

    const selectedAdmin = this.adminFilteredList.find(obj => {
      return obj.optionText === target.name;
    });

    if (ev.detail.checked) {
      // add to arr sent and flip the checkbox
      this.adminSelected.push(selectedAdmin.optionValue);
      this.flipValue(selectedAdmin.optionValue, ev.detail.checked);
    } else {
      // remove the element from the array and turn off the checkbox
      const index = this.adminSelected.indexOf(selectedAdmin.optionValue);
      this.adminSelected.splice(index, 1);
      this.flipValue(selectedAdmin.optionValue, ev.detail.checked);
    }
    setTimeout(() => this.popoverCtrl.dismiss(this.adminSelected), 1500);
  }

  flipValue(id: string, checked: boolean) {
    for (const admin of this.adminFilteredList) {
      if (admin.optionValue === id) {
        admin.value = checked;
        return;
      }
    }
  }

  adminsMenuClosed(ev: CustomEvent) {
    // if (ev.detail && ev.detail.click) {
    //   // const admins = this.adminList.map((b) => {
    //   //   return { optionText: b.name, optionValue: b.id };
    //   // });
    //   this.adminUsers = JSON.stringify({
    //     id: "admins",
    //     optionItems: this.adminProps
    //   });
    // }
  }

  getArrayObj(obj) {
    if (Array.isArray(obj)) {
      return obj;
    } else {
      if (typeof obj == 'string' && obj.length > 0) {
        try {
          return JSON.parse(obj).map(item => item.text);
        } catch (e) {
          //bad json format
          return obj;
        }
      } else {
        return obj;
      }
    }
  }
}
