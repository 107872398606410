// import Amplify, {
//     Auth
// } from 'aws-amplify';
import { environment } from 'src/environments/environment';

// Amplify.configure({
//     Auth: {
//         region: environment.COGNITO_REGION,
//         userPoolId: environment.COGNITO_USER_POOL,
//         userPoolWebClientId: environment.COGNTIO_APP_CLIENT_ID,
//         authenticationFlowType: environment.COGNITO_FLOW_TYPE
//     },
// });
const awsconfig = {
  region: environment.COGNITO_REGION,
  userPoolId: environment.COGNITO_USER_POOL,
  userPoolWebClientId: environment.COGNTIO_APP_CLIENT_ID,
  authenticationFlowType: environment.COGNITO_FLOW_TYPE,
  mandatorySignIn: false,
};
export default awsconfig;
