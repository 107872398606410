import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router, // private location: Location, // private locationStrategy: LocationStrategy
  ) {}

  private baseUrl = location.origin;

  /**
   *
   * @param page primary page action Type: String; example: 'program.create'
   * @param params carries ids needed details page Type: Object; example: {programid: 12345}
   * @param query additional queries Type: NavigationExtras
   * @param user user object for additional routing logic
   * @description main routing service for app
   *
   */
  public main(page: string, params?: Object, query?: any, user?: Object): void {
    if (page) {
      const pageSplit = page.split('.');

      switch (pageSplit[0]) {
        case 'login':
          this.goToLoginPage();
          break;
        case 'application':
          this.goToApplicationPages(pageSplit, params, query, user);
          break;
        case 'new-application':
          this.goToApplicationPages(pageSplit, params, query, user);
          break;
        case 'applicationchain':
          this.goToApplicationChainPages(pageSplit, params, query, user);
          break;
        case 'payments':
          this.goToPaymentPages(pageSplit, params, query, user);
          break;
        case 'institutions':
          this.goToInstitutionPages(pageSplit, params, query, user);
          break;
        case 'programs':
          this.goToProgramPages(pageSplit, params, query, user);
          break;
        case 'program-bundles':
          this.goToProgramBundlesPages(pageSplit, params, query, user);
          break;
        case 'program-availability':
          this.goToProgramAvailabilityPages(pageSplit, params, query, user);
        case 'visitors':
          this.goToVisitorPages(pageSplit, params, query, user);
          break;
        case 'dashboard':
          this.goToDashboardPage(pageSplit, params, query, user);
          break;
        case 'documents':
          this.goToDocumentPages(pageSplit, params, query, user);
          break;
        case 'managers':
          this.goToManagerPages(pageSplit, params, query, user);
          break;
        case 'trainees':
          this.goToTraineePages(pageSplit, params, query, user);
          break;
        case 'hosts':
          this.goToHostPages(pageSplit, params, query, user);
          break;
        case 'reviews':
          this.goToReviewPages(pageSplit, params, query, user);
          break;
        case 'requirements':
          this.goToRequirementsPages(pageSplit, params, query, user);
          break;
        case 'coupons':
          this.goToCouponPages(pageSplit, params, query, user);
          break;
        case 'settings':
          this.goToSettingsPages(pageSplit, params, query, user);
          break;
        case 'review-templates':
          this.goToReviewTemplatePages(pageSplit, params, query, user);
          break;
        default:
          this.goToLoginPage();
          break;
      }
    }
  }

  private goToApplicationPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'new-application';
    } else if (path[1] === 'create') {
      url = 'new-application/create-application';
    } else if (params.applicationid && path.length === 3) {
      url = `${path[0]}/${path[1]}/${params.applicationid}/${path[2]}`;
    }
    this.navigationAction(url);
  }

  private goToPaymentPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'payments';
    } else if (path[1].includes('create')) {
      url = 'payments/payment-create';
    } else if (params.paymentid) {
      url = `payments/details/${params.paymentid}`;
    }
    this.navigationAction(url, query);
  }

  private goToInstitutionPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'institutions';
    } else if (path[1] === 'details') {
      if (path[2] && path[2].includes('program') && params.institutionid && params.hostid) {
        url = `institutions/details/program-create/${params.institutionid}`;
        this.navigationAction(url, {
          id: params.institutionid,
          hostid: params.hostid,
        });
        return;
      } else if (params.institutionid) {
        url = `institutions/details/institutions-information/${params.institutionid}`;
        this.navigationAction(url, { id: params.institutionid });
      }
    } else if (path[1] === 'create') {
      url = 'institutions/details/add';
    }
    this.navigationAction(url, query);
  }

  private goToProgramPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'programs';
    } else if (path[1].includes('bulkupdate')) {
      url = `${path[0]}/bulk-update`;
    } else if (path[1].includes('create')) {
      url = `${path[0]}/program-create`;
    } else if (params.programid) {
      url = `programs/details/${params.programid}`;
    }
    this.navigationAction(url);
  }

  private goToProgramBundlesPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'program-bundles';
    } else if (path[1].includes('create')) {
      url = `${path[0]}/program-bundles-create`;
    } else if (params.programbundleid) {
      url = `program-bundles/details/${params.programbundleid}`;
    }
    this.navigationAction(url);
  }

  private goToProgramAvailabilityPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'program-availability';
    }
    this.navigationAction(url);
  }

  private goToVisitorPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'visitors';
    }
    this.navigationAction(url);
  }

  private goToDashboardPage(path: string[], params, query, user) {
    let url = 'dashboard';
    this.navigationAction(url);
  }

  private goToDocumentPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'documents';
    } else if (path[1].includes('create')) {
      url = 'documents/document-create';
    } else if (params.documentid) {
      url = `documents/details/${params.documentid}`;
    }
    this.navigationAction(url);
  }

  private goToManagerPages(path: string[], params, query, user) {
    let url = '';
    if (path[1].includes('create')) {
      url = 'manager';
    } else if (path[1].includes('employee-edit')) {
      url = `/managers/details/${params.managerid}`;
    } else if (path[1].includes('edit')) {
      url = `/institutions/details/managers/edit`;
    } else if (path[1] === 'list') {
      url = 'managers';
    } else if (path[1].includes('import')) {
      url = `/managers/csv-manager-department-upload`;
    }
    this.navigationAction(url, query);
  }

  private goToTraineePages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'trainees';
    } else if (path[1].includes('create')) {
      url = 'trainees';
    } else if (path[1] === 'invite-visitors') {
      url = 'invite-visitors';
    } else if (params.traineeid && path[1] === 'details' && path.length === 3) {
      url = `${path[0]}/${path[1]}/${params.traineeid}/${path[2]}`;
    }
    this.navigationAction(url);
  }

  private goToHostPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'hosts';
    } else if (path[1].includes('program-create')) {
      url = `hosts/details/program-create`;
      this.navigationAction(url, {
        id: params.institutionid,
        hostid: params.hostid,
      });
      return;
    } else if (path[1].includes('create') && params.institutionid) {
      url = `institutions/details/hosts/add?id=${params.institutionid}`;
    } else if (params.institutionid && params.hostid && path[1] === 'details') {
      // url = `institutions/details/hosts/edit`;
      url = `hosts/details/edit`;
      this.navigationAction(url, {
        id: params.institutionid,
        hostid: params.hostid,
      });
      return;
    }
    this.navigationAction(url);
  }

  private goToReviewPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'reviews';
    } else if (path[1].includes('create')) {
      url = `${path[0]}/reviews-create`;
    }
    this.navigationAction(url);
  }

  private goToReviewTemplatePages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'review-templates';
    } else if (path[1].includes('create')) {
      url = `${path[0]}/review-template-create`;
    }
    this.navigationAction(url);
  }

  private goToRequirementsPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'requirements';
    } else if (path[1].includes('create')) {
      url = `${path[0]}/requirements-create`;
    } else if (params.requirementid) {
      url = `requirements/details/${params.requirementid}`;
    }
    this.navigationAction(url);
  }

  private goToCouponPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'coupons';
    } else if (path[1].includes('create')) {
      url = `${path[0]}/coupons-create`;
    } else if (params.couponid) {
      url = `coupons/details/${params.couponid}`;
    }
    this.navigationAction(url);
  }

  private goToSettingsPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'profile') {
      url = 'settings/profile';
    }
    this.navigationAction(url);
  }

  goToApplicationChainPages(path: string[], params, query, user) {
    let url = '';
    if (path[1] === 'list') {
      url = 'new-application/chains';
    } else if (path[1].includes('create')) {
      url = `${path[0]}/new-application/chains/create`;
    }
    this.navigationAction(url);
  }

  private goToLoginPage() {
    const url = 'login';
    this.navigationAction(url);
  }

  private navigationAction(url: string, query?: any) {
    if (query) {
      this.router.navigate([url], { queryParams: query });
    } else {
      this.router.navigate([url]);
    }
  }
}
