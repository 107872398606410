import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class AdminService {
  constructor(private http: HttpClient) {}

  /**
   *
   *
   * @param {string} id Manager DB id
   * @returns JSON data about associated manager
   * @memberof AdminService
   */
  managerDetails(id: string) {
    return this.http.get(`${environment.api}/managements/${id}/show`);
  }

  /**
   *
   *
   * @param {*} form manager JSON with updated manager permissions
   * @returns response from saving manager permissions
   * @memberof AdminService
   */
  saveManagerPermission(form: any) {
    return this.http.post(
      `${environment.api}/managements/updatemanagerpermissions`,
      form
    );
  }

  checkDocumentExists(url: string) {
    return this.http.get(
      `${environment.api}/document/documentexist?url=${url}`
    );
  }

  adminDeleteStaticProgramImage(params: any): Observable<any> {
    const encodeKey = encodeURI(params.key);
    return this.http.get(
      `${environment.api}/configs/staticobjectremove?key=${encodeKey}`
    );
  }
}
