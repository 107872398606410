<ion-list lines="none" class="transparent">
  <ion-item class="transparent">
    <ion-chip color="primary" mode="ios" outline="true">
      <ion-icon name="checkmark-circle"></ion-icon>
      <ion-label>Accepted</ion-label>
    </ion-chip>
  </ion-item>
  <ion-item class="transparent">
    <ion-chip color="danger" mode="ios" outline="true">
      <ion-icon name="close-circle"></ion-icon>
      <ion-label>Requires resubmission</ion-label>
    </ion-chip>
  </ion-item>
</ion-list>