<ion-header [translucent]="true"> 
  <ion-toolbar> 
    <ion-title>Postponement Reasons</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <div>
          <ion-list lines = "none" [ngClass] = "{'non-popover': (this.itemClicked)}" (click) = "changeBackground()">
            <ion-item button [detail] = "true" id = "visa">{{ visaTag }}</ion-item>
            <ion-item button (click) = "onClick('Personal Reasons');">Personal Reasons</ion-item>
            <ion-item button (click) = "onClick('Program Issue');">Program Issue</ion-item>
            <ion-item button (click) = "onClick('Force Majeure');">Force Majeure</ion-item>
            <ion-item button (click) = "onClick('University Related');">University Related</ion-item>
            <ion-item button (click) = "onClick('Testing Schedule Change');">Testing Schedule Change</ion-item>
            <ion-item button (click) = "onClick('Active Duty/Reserve');">Active Duty/Reserve</ion-item>

            <ion-item lines = "none" color = "undefined">
              <ion-label position="stacked">Other: </ion-label>
              <ion-input 
                class="program-edit-input program-edit-margin"
                placeholder="Enter Reason"
                (ionChange)="ionChange($event)"
              >
              </ion-input> 
            </ion-item>

            
            <ion-popover trigger="visa" dismissOnSelect="true" side="end">
              <ng-template>
                <ion-content>
                  <ion-list lines = "none" [ngClass] = "popover">
                    <ion-item button (click) = "onClick('Visa First Denial')" id = first>First Denial</ion-item>
                    <ion-item button (click) = "onClick('Visa Second Denial')" id = second>Second Denial</ion-item>
                    <ion-item button (click) = "onClick('Visa Rejected Expedited Request')" id = rejected>Rejected Expedited Request</ion-item>
                    <ion-item button (click) = "onClick('Visa Other Appointment Date Conflict')" id = appointment>Other Appointment Date Conflict</ion-item>
                    <ion-item button (click) = "onClick('Visa Administrative Processing')" id = administrative>Administrative Processing</ion-item>
                  </ion-list>
                </ion-content>
              </ng-template>
            </ion-popover>
          </ion-list>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar>
      <ion-button slot="start" color="secondary" size="medium" type="button" (click)="cancel()"> Cancel </ion-button>
      <ion-button slot= "end" color="secondary" size="medium" type="button" (click)="confirm()"> Confirm </ion-button>
  </ion-toolbar>
</ion-footer>
