import {
  ActionReducer,
  ActionReducerMap,
  Action,
  ActionType,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from "@ngrx/store";
import { environment } from "../../../../environments/environment";
import { InjectionToken } from "@angular/core";
import * as fromAuth from "./auth.reducer";
import * as fromUser from "./user.reducers";
import * as fromApplication from "./application.reducer";
import * as fromTrainee from "./trainee.reducer";
import * as fromPayment from "./payment.reducer";
import * as fromInstitutions from "./institutions.reducer";

export interface State {
  [fromAuth.authFeatureKey]: fromAuth.AuthState;
  [fromUser.userFeatureKey]: fromUser.UserState;
  [fromApplication.applicationFeatureKey]: fromApplication.ApplicationState;
  [fromTrainee.traineeFeatureKey]: fromTrainee.TraineeState;
  [fromPayment.paymentFeatureKey]: fromPayment.PaymentState;
  [fromInstitutions.institutionFeatureKey]: fromInstitutions.InstitutionsState;
}

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>("Root reducer token", {
  factory: () => ({
    [fromAuth.authFeatureKey]: fromAuth.reducer,
    [fromUser.userFeatureKey]: fromUser.reducer,
    [fromApplication.applicationFeatureKey]: fromApplication.reducer,
    [fromTrainee.traineeFeatureKey]: fromTrainee.reducer,
    [fromPayment.paymentFeatureKey]: fromPayment.reducer,
    [fromInstitutions.institutionFeatureKey]: fromInstitutions.reducer
  })
});

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    return result;
  };
}

export function clearState(reducer: ActionReducer<State>) {
  return (state: State, action: ActionType<any>) => {
    if (action.type === "[Auth] Set Is Not Authenticated") {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<State>> = !environment.production
  ? [logger, clearState]
  : [clearState];
