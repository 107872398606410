import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-application",
  templateUrl: "./application.page.html",
  styleUrls: ["./application.page.scss"]
})
export class ApplicationPage implements OnInit {
  static isDirtyFormInternal = false;

  static get isDirtyForm(): boolean {
    return ApplicationPage.isDirtyFormInternal;
  }
  static set isDirtyForm(newValue: boolean) {
    ApplicationPage.isDirtyFormInternal = newValue;
  }

  constructor() {}

  ngOnInit() {}
}
