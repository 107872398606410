import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { NavigationService } from "src/app/core/services/navigation/navigation.service";
import { isNil } from "../utils";

@Injectable()
export class Warning {
  constructor(
    private alertController: AlertController,
    private navService: NavigationService
  ) {}

  /**
   * @description creates a simple pop up warning
   * @param message a string with the warning message to be shown
   * @param subhead a string with a sub header, optional param
   * @returns a promise that can either be used or ignored
   */
  create = async (message: string, subhead?: string) => {
    // create the warning
    const myWarning = await this.alertController.create({
      header: `Attention`,
      subHeader: isNil(subhead) ? "" : `${subhead}`,
      message: `${message}`,
      buttons: ["Got it."],
      cssClass: "user-warning"
    });
    // present the warning
    await myWarning.present();
    // return a promise when warning is dismissed
    return myWarning.onDidDismiss();
  };

  emailWarning = async (message: string) => {
    // create the warning
    const myWarning = await this.alertController.create({
      header: `Attention`,
      subHeader: ``,
      cssClass: "user-warning",
      message: `${message}`,
      buttons: [
        {
          text: "Login",
          handler: () => {
            this.navService.main("login");
          }
        },
        {
          text: "Got it",
          handler: () => {
            this.alertController.dismiss();
          }
        }
      ]
    });
    // present the warning
    await myWarning.present();
    // return a promise when warning is dismissed
    return myWarning.onDidDismiss();
  };

  migrationWarning = async (message: string): Promise<boolean> => {
    // create the warning
    let resolveFunction: (confirm: boolean) => void;
    let promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });

    const myWarning = await this.alertController.create({
      header: `Reset Password`,
      subHeader: ``,
      cssClass: "user-warning",
      message: `We have a new password policy. Please reset your password.`,
      buttons: [
        {
          text: "Cancel",
          handler: () => resolveFunction(false)
        },
        {
          text: "Reset",
          handler: () => resolveFunction(true)
        }
      ]
    });
    // present the warning
    await myWarning.present();
    // return a promise when warning is dismissed
    return promise;
  };

  confirmation = async (message: string): Promise<boolean> => {
    // create the warning
    let resolveFunction: (confirm: boolean) => void;
    let promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });

    const myWarning = await this.alertController.create({
      header: `Confirmation`,
      subHeader: ``,
      cssClass: "user-warning",
      message: message,
      buttons: [
        {
          text: "No",
          handler: () => resolveFunction(false)
        },
        {
          text: "Yes",
          handler: () => resolveFunction(true)
        }
      ]
    });
    // present the warning
    await myWarning.present();
    // return a promise when warning is dismissed
    return promise;
  };
}
