import { NgModule } from '@angular/core';
import { ImgPipe } from './img/img.pipe';
import { SafePipe } from './html-safe/html-safe.pipe';
import { PropStringifyPipe } from './propStringify/prop-stringify.pipe';
import { PermissionNamePipe } from './permission-name/permission-name.pipe';
import { StatusiconsPipe } from './statusicons/statusicons.pipe';
import { SortByDatePipe } from './sort-by-date/sort-by-date.pipe';

@NgModule({
  declarations: [SafePipe, ImgPipe, PropStringifyPipe, PermissionNamePipe, StatusiconsPipe, SortByDatePipe],
  imports: [],
  exports: [SafePipe, ImgPipe, PropStringifyPipe, PermissionNamePipe, StatusiconsPipe, SortByDatePipe],
})
export class PipesComponentsModule {}
