import { on, Action, createReducer } from "@ngrx/store";
import { TraineeActions } from "../actions";

export const traineeFeatureKey = "trainee";

export interface TraineeState {
  traineeObject: {};
  document_ItemOne: {};
  document_ItemTen: {};
  document_ItemFour: {};
}

export const initialState: TraineeState = {
  traineeObject: {},
  document_ItemOne: {}, // resume
  document_ItemTen: {}, // toefl
  document_ItemFour: {} // school records
};

const TraineeReducer = createReducer(
  initialState,
  on(TraineeActions.setTrainee, (state, args) => ({
    ...state,
    traineeObject: args.traineeObject
  })),
  on(TraineeActions.setDocumentItemOne, (state, args) => ({
    ...state,
    document_ItemOne: args.document_ItemOne
  })),
  on(TraineeActions.setDocumentItemTen, (state, args) => ({
    ...state,
    document_ItemTen: args.document_ItemTen
  })),
  on(TraineeActions.setDocumentItemFour, (state, args) => ({
    ...state,
    document_ItemFour: args.document_ItemFour
  }))
);

export function reducer(state: TraineeState | undefined, action: Action) {
  return TraineeReducer(state, action);
}
