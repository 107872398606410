<!-- inputs up top -->
<div class="calendar-width">
  <div class="date-range-input">
    <input
      name="startDate"
      (change)="inputChange($event, 'start')"
      [ngModel]="start"
      [type]="startType"
      placeholder="Start Date"
      onfocus="(this.type='date')"
    />
    <p>-</p>
    <input name="endDate" (change)="inputChange($event, 'end')" [ngModel]="end" [type]="endType" placeholder="End Date" onfocus="(this.type='date')" />
  </div>
  <mat-calendar #calendar [selected]="selectedRangeValue" (selectedChange)="selectedChange($event)" [headerComponent]="customHeader"> </mat-calendar>
  <div class="button-container">
    <button (click)="clearSelection()">Clear</button>
    <button (click)="applySelection()">Apply</button>
  </div>
</div>
