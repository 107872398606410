import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { ModalController } from '@ionic/angular';
//import { ServiceWorkerComponent } from "../../../../app/shared/components/service-worker/service-worker.component";
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';

// tslint:disable-next-line:class-name
@Injectable({
  providedIn: 'root',
})
export class WorkerService {
  showUpdateBanner = new BehaviorSubject(false);
  constructor(public updates: SwUpdate, public location: Location) {
    if (updates.isEnabled) {
      console.log('Checking for updates is enabled');
      interval(30000).subscribe(() => {
        if (!this.location.path().includes('login')) {
          updates.checkForUpdate().then(() => console.log('Checking for updates'));
        } else {
          console.log('skip on login');
        }
      });
    } else {
      console.log('Checking for updates is not enabled');
    }
  }

  public async checkForUpdates() {
    try {
      const updateReady = await this.updates.checkForUpdate();
      console.log('updateReady', updateReady);

      if (updateReady) {
        this.promptUser();
      }
    } catch (error) {
      console.log('error in checkForUpdates', error);
    }
  }

  async promptUser() {
    console.log('updating to new version');

    this.setShowBanner();
  }

  reload() {
    this.updates.activateUpdate().then(() =>
      setTimeout(() => {
        document.location.reload();
      }, 1000),
    );
  }

  clearShowBanner() {
    this.showUpdateBanner.next(false);
  }
  setShowBanner() {
    this.showUpdateBanner.next(true);
  }
} // end of the tools service
