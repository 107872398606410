<ion-list lines="none">
  <ion-item (click)="newManager()" class="ion-text-center" button>
    <ion-label color="secondary">Add Manager</ion-label>
  </ion-item>
  <ion-item
    *ngIf="type === 'Hosting'"
    (click)="newHost()"
    class="ion-text-center"
    button
  >
    <ion-label color="secondary">Add Host</ion-label>
  </ion-item>
  <ion-item
    class="ion-text-center"
    *ngIf="(managerType$ | async) === 'AMOEMPLOYEE'"
    (click)="deleteInstitution()"
    button
  >
    <ion-label color="secondary">
      <fa-icon [icon]="faTrash"></fa-icon>

      Delete</ion-label
    >
  </ion-item>
</ion-list>
