import { Component, Input, OnInit } from "@angular/core";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { ModalController } from "@ionic/angular";
import { Warning } from "@shared/services";
import { SmartToastService } from "@shared/services/smartToast.service";
import { isNil } from "@shared/utils";
import { regexTools } from "@shared/utils/regex";
import { Auth } from "aws-amplify";

@Component({
  selector: "app-auth-ps-update-modal",
  templateUrl: "./auth-ps-update-modal.component.html",
  styleUrls: ["./auth-ps-update-modal.component.scss"]
})
export class AuthPsUpdateModalComponent implements OnInit {
  @Input() type: string;
  @Input() user: any;

  faCheckCircle = faCheckCircle;

  password: string;
  newPassword: string = "";
  confirmNewPassword: string;

  validPS: boolean = false;

  passwordOptions = {
    oneUpper: false,
    oneLower: false,
    oneNumber: false,
    oneSpecial: false,
    eightChars: false
    // nonEnglish: false,
    // match: false
  };
  updatedPasswordOptions = {
    oneUpper: false,
    oneLower: false,
    oneNumber: false,
    oneSpecial: false,
    eightChars: false
    // nonEnglish: false,
    // match: false
  };

  constructor(
    private modalCtrl: ModalController,
    private warning: Warning,
    private smartToastService: SmartToastService
  ) {}

  ngOnInit() {}

  listener(event) {
    this.newPassword = event;

    // password is at least 8 characters
    this.updatedPasswordOptions.eightChars =
      this.newPassword.length > 8 ? true : false;
    // password has at least 1 uppercase character
    this.updatedPasswordOptions.oneUpper = isNil(
      this.newPassword.match(regexTools.upperCaseRegex)
    )
      ? false
      : this.newPassword.match(regexTools.upperCaseRegex).length > 0
      ? true
      : false;
    // password has at least 1 lowercase character
    this.updatedPasswordOptions.oneLower = isNil(
      this.newPassword.match(regexTools.lowerCaseRegex)
    )
      ? false
      : this.newPassword.match(regexTools.lowerCaseRegex).length > 0
      ? true
      : false;
    // password has at least 1 number
    this.updatedPasswordOptions.oneNumber = isNil(
      this.newPassword.match(regexTools.numberCaseRegex)
    )
      ? false
      : this.newPassword.match(regexTools.numberCaseRegex).length > 0
      ? true
      : false;
    // password has at least 1 special character wadawdawdawd  wdawdawdawd qwdawdw
    for (let i = 0; i < this.newPassword.length; i++) {
      const character = this.newPassword[i];
      if (/([\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])$/.test(character)) {
        this.updatedPasswordOptions.oneSpecial = true;
      } else {
        this.updatedPasswordOptions.oneSpecial = false;
      }
    } // end of special check
    // does password have any non english characters
    // this.updatedPasswordOptions.nonEnglish = isNil(
    //   this.newPassword.match(regexTools.nonEnglishRegex)
    // )
    //   ? false
    //   : this.newPassword.match(regexTools.nonEnglishRegex).length > 0
    //   ? true
    //   : false;

    this.updatedPasswordOptions.oneSpecial = isNil(
      this.newPassword.match(/(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])/g)
    )
      ? false
      : this.newPassword.match(regexTools.specialCaseRegex).length > 0
      ? true
      : false;

    this.setBlur();
  }

  setBlur() {
    this.passwordOptions = { ...this.updatedPasswordOptions };
    this.validPS = Object.values(this.passwordOptions).every(Boolean);
  }

  async updateAuthUser() {
    if (this.newPassword !== this.confirmNewPassword) {
      this.warning.create("Confirm the new passwords must match");
      return;
    }

    if (!this.validPS) {
      this.warning.create(
        "Please be sure to follow the allowed password settings"
      );
      return;
    }

    const result = await Auth.currentAuthenticatedUser().then((user) => {
      return Auth.changePassword(user, this.password, this.confirmNewPassword);
    });

    if (result == "SUCCESS") {
      this.smartToastService.show(
        "Successfully Updated Password",
        "success",
        5000
      );
      this.dismiss();
    } else {
      this.warning.create("Invalid Credentials");
      this.confirmNewPassword = null;
      this.newPassword = null;
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
