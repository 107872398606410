import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-application-postpone-reasons',
  templateUrl: './application-postpone-reasons.component.html',
  styleUrls: ['./application-postpone-reasons.component.scss'],
})
export class ApplicationPostponeReasonsComponent implements OnInit {
  @Input() reason: string;

  date: Date;

  selected: any = [];
  visaTag: string = "Visa";
  itemClicked: boolean = false;

  options = [
    "Visa",
    "Personal reasons",
    "Program issue",
    "Force majeure",
    "University related",
    "Testing schedule change",
    "Active Duty/Reserve",
    "Other:"
  ]
  
  visaOptions = [
    "Visa First Denial", 
    "Visa Second Denial",
    "Visa Rejected Expedited Request",
    "Visa Other Appointment Date Conflict",
    "Visa Administrative Processing"
  ]

  constructor(
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  // assigns reason, changes popover text if selected, changes color to indicate activated
  onClick(reason: string,) {
    this.reason = reason;
    this.visaTag = this.visaOptions.includes(this.reason) ? reason : "Visa";
  }

  changeBackground() {
    this.itemClicked = true;
  }

  confirm() {
    this.date = new Date();
    this.send({'reason': this.reason, 'date': this.date});
  }

  send(fetch: any) {
    this.modalCtrl.dismiss(fetch);
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  ionChange(ev: any) {
    this.onClick(ev.detail.value);
  }
}
