import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { AgGridModule } from 'ag-grid-angular';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DocumentFrameComponent } from './components/document-frame/document-frame.component';
import { DocumentMissingComponent } from './components/document-missing/document-missing.component';
// import { ReviewsDetailsComponent } from "./components/reviews-details/reviews-details.component";
// import { ReviewsListComponent } from "./components/reviews-list/reviews-list.component";

@NgModule({
  declarations: [DocumentFrameComponent, DocumentMissingComponent],
  imports: [IonicModule, CommonModule, FontAwesomeModule, RouterModule, NgxDocViewerModule],
  exports: [DocumentFrameComponent, DocumentMissingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DocumentComponentsModule {}
