import { on, Action, createReducer } from "@ngrx/store";
import { ApplicationActions } from "../actions";

export const applicationFeatureKey = "application";

export interface ApplicationState {
  applicationObject: {};
  adminList: any;
  submittedRequirementList: any[];
  application_TraineeShowSave: boolean;
  applicationSave: {};
}

export const initialState: ApplicationState = {
  applicationObject: {},
  adminList: [],
  submittedRequirementList: [],
  application_TraineeShowSave: false,
  applicationSave: {}
};

const ApplicationReducer = createReducer(
  initialState,
  on(ApplicationActions.setApplication, (state, args) => ({
    ...state,
    applicationObject: args.applicationObject
  })),
  on(ApplicationActions.setAdminList, (state, args) => ({
    ...state,
    adminList: [...args.adminList]
  })),
  on(
    ApplicationActions.setSubmittedApplicationRequirementList,
    (state, args) => ({
      ...state,
      submittedRequirementList: [...args.submittedRequirementList]
    })
  ),
  on(ApplicationActions.setApplicationTraineeShowSave, (state, args) => ({
    ...state,
    application_TraineeShowSave: args.application_TraineeShowSave
  })),
  on(ApplicationActions.setSave, (state, args) => ({
    ...state,
    applicationSave: args.applicationSave
  }))
);

export function reducer(state: ApplicationState | undefined, action: Action) {
  return ApplicationReducer(state, action);
}
