import { Component } from '@angular/core';

// import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ModalService } from 'src/app/shared/services';
import { ApplicationService } from '../../application/application.service';
import { LoadingService } from '../../loading/loading.service';

@Component({
    selector: 'child-cell',
    template: `<span><a (click)="invokeParentMethod()" class="anchor">{{linkName}}</a></span>`,
    styles: [
        `.anchor {
            line-height: 0.5;
            cursor: pointer;
            color: var(--ion-color-teal) !important;
        }`
    ]
})
export class GridAnchorRenderer implements ICellRendererAngularComp {
    constructor(
        private applicationService: ApplicationService,
        private loadingService: LoadingService,
        private modalService: ModalService
    ) { }

    public params: any;
    public linkName = '';

    agInit(params: any): void {
        this.params = params;
        if (this.params.name === 'applicationPacket') {
            this.linkName = 'Application Packet';
        } else if (this.params.name === 'resume') {
            this.linkName = this.params.value;
        }

    }

    public invokeParentMethod(): void {
        event.stopPropagation();
        if (this.params.name === 'applicationPacket') {
            this.openApplicationPacket(this.params.data.applicationid);
        } else if (this.params.name === 'resume') {
            this.openResume(this.params.data.applicationid);
        }
    }

    openApplicationPacket(id: string): void {
        this.applicationService.managerhostingdownloadapplicationpacket(id).subscribe((res: any) => {
            window.open(res.url, '_blank');
        });
    }

    openResume(id: string): void {
        const a = document.createElement('a');

        a.addEventListener('click', event => { event.stopPropagation(); });
        this.loadingService.presentLazyLoadingCtrl('Loading...', 1500);
        this.applicationService.managerhostingdownloadapplicationresume(id).subscribe((res: any) => {
            this.modalService.documentViewer(res.url).then(() => {});
        });
    }

    refresh(): boolean {
        return false;
    }
}
