import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { State } from "../../ngrx/reducers";
// import { Store } from "@ngrx/store";

// import { ToastController } from "@ionic/angular";
// import { userSelectors } from "../../ngrx/selectors";

@Injectable({
  providedIn: 'root',
})
export class ActivityLogsService {
  user: any;

  constructor(
    private http: HttpClient, // private store: Store<State>, // private toastController: ToastController
  ) {
    // this.store.select(userSelectors.userProfile).subscribe((user: any) => {
    //   this.user = { ...user };
    // });
  }

  getActivityLogs(payload: any): Observable<any> {
    //{"query":{"action":["user"],"user_id":"031751b0-e339-489b-a3a1-6d60878b3574"}}
    return this.http.post(`${environment.api}/systemlogs/listlogs`, payload);
  }

  getEsActivityLogs(payload: any): Observable<any> {
    console.log('send it');
    return this.http.post(`${environment.api}/systemlogs/listsystemlogs`, payload);
  }
}
