import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { Store } from "@ngrx/store";
import { State } from "../../ngrx/reducers";
import { AuthActions } from "../../ngrx/actions";
import { NavigationService } from "../../services/navigation/navigation.service";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root"
})
export class AuthGuard  {
  private cache$: Observable<any>; // save response for user profile

  constructor(
    private navService: NavigationService,
    private cookie: CookieService,
    private store: Store<State>,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return of(this.cookie.check("amoactkn")).pipe(
      map((e) => {
        if (e) {
          this.store.dispatch(
            AuthActions.setIsAuthenticated({ is_authenticated: true })
          );
          return true;
        } else {
          // user is not authenticated so we kick them out
          this.store.dispatch(
            AuthActions.setIsAuthenticated({ is_authenticated: false })
          );
          let redirectUrl = decodeURIComponent(window.location.href);

          if (
            redirectUrl.includes("signup") === false &&
            redirectUrl.includes("reset-password") === false &&
            redirectUrl.includes("document") === false &&
            redirectUrl.includes("login") === false &&
            redirectUrl !== location.origin &&
            redirectUrl !== location.origin + "/"
          ) {
            this.router.navigate(["/login"], {
              relativeTo: this.activatedRoute,
              queryParams: {
                next: redirectUrl
              },
              replaceUrl: true
            });
          } else {
            this.router.navigate(["/login"]);
          }

          return false;
        }
      }),
      catchError((error: any) => {
        this.store.dispatch(
          AuthActions.setIsAuthenticated({ is_authenticated: false })
        );
        this.navService.main("login");
        return of(false);
      })
    );
  }
}
