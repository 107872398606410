import { Component, OnDestroy } from '@angular/core';
import { faCheckCircle, faCheckDouble, faTrash, faTimesCircle, faDollarSign, faExchange } from '@fortawesome/pro-regular-svg-icons';
import { faThumbtack } from '@fortawesome/pro-solid-svg-icons';
import { ToolsService } from '@Services/tools/tools.service';
import { AlertController, LoadingController, NavController, PopoverController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { ApplicationService } from '@Services/application/application.service';
import { State } from '@Ngrx/reducers';
import { select, Store } from '@ngrx/store';
import { applicationSelectors, userSelectors } from 'src/app/core/ngrx/selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SmartToastService } from '@shared/services/smartToast.service';
import { ApplicationPage } from 'src/app/modules/new-application/pages/application/application.page';
import { FormsService } from '@Services/form/forms.service';
import { FormGroup } from '@angular/forms';
import { ApplicationActions } from '@Ngrx/actions';
import { NavigationService } from '@Services//navigation/navigation.service';
import { Subscription } from 'rxjs';
import User from '@Interfaces/User';
import Application from '@Interfaces/Application';
import DateConfig from '@Interfaces/DateConfig';
import { ModalService } from '@shared/services/modal.service';
import { readJsonConfigFile } from 'typescript';

@Component({
  selector: 'app-application-actions',
  templateUrl: './application-actions.component.html',
  styleUrls: ['./application-actions.component.scss'],
})
export class ApplicationActionsComponent implements OnDestroy {
  faCheckCircle = faCheckCircle;
  faCheckDouble = faCheckDouble;
  faTrash = faTrash;
  faTimesCircle = faTimesCircle;
  faThumbtack = faThumbtack;
  faDollarSign = faDollarSign;
  faExchange = faExchange;

  user$ = this.store.pipe(select(userSelectors.userProfile));

  user: Partial<User>;

  managerType$ = this.store.pipe(select(userSelectors.isManagementType));

  application$ = this.store.pipe(select(applicationSelectors.applicationObject));

  applicationSave$ = this.store.pipe(select(applicationSelectors.applicationSave));

  traineeid: string;

  application: Partial<Application>;

  application_form: FormGroup;

  application_subscription = this.formService.amoEmployeeApplicationEditFrom().subscribe((form: FormGroup) => (this.application_form = form));

  dateConfig: DateConfig = {
    dateReadonly: true,
    dateFormat: 'MM/DD/YYYY', //"MMMM-DD-YYYY",
    startdate: '',
    enddate: '',
    expires: '',
    migrationdocumentdate: '',
    signedcontract: '',
    accepteddate: '',
    reserveddate: '',
    enrolleddate: '',
    preenrollmentorientation_datetime: '',
    predepartureorientation_datetime: '',
  };

  arrSubs: Subscription[] = [];

  // var for postponement
  reason: string;
  date: Date;

  constructor(
    private router: Router,
    private location: Location,
    private store: Store<State>,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private toolService: ToolsService,
    private navigationService: NavigationService,
    private popCtrl: PopoverController,
    private alertController: AlertController,
    private toastController: ToastController,
    private applicationService: ApplicationService,
    private smartToastService: SmartToastService,
    private formService: FormsService,
    private loadingController: LoadingController,
    private modalService: ModalService,
  ) {
    // user
    this.arrSubs.push(
      this.user$.subscribe((user: User) => {
        this.user = { ...user };
      }),
    );

    // application subscribe for initial
    this.arrSubs.push(
      this.application$.subscribe((application: Application) => {
        this.application = { ...application };
        this.traineeid = this.application.userid;
      }),
    );

    // application subscribe to save
    this.arrSubs.push(
      this.applicationSave$.subscribe((application: Application) => {
        if (application && application.id) {
          this.application = { ...application };
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.arrSubs.forEach((subs: Subscription) => {
      subs.unsubscribe();
    });
  }

  async presentToast(message, type) {
    const toast = await this.toastController.create({
      message,
      color: type,
      duration: 10000,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
      position: 'top',
    });
    toast.present();
  }

  private async userAbandonUnsavedChangesInForm(): Promise<boolean> {
    // Setup promise and holder for resolve
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });

    // show alert
    const alert = await this.alertController.create({
      header: 'Confirmation',
      message: 'This action will save your changes. Do you wish to proceed?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'No',
          handler: () => resolveFunction(false),
        },
        {
          text: 'Yes',
          handler: () => resolveFunction(true),
        },
      ],
    });
    await alert.present();
    return promise;
  }

  // return of or input to resolveFunction is should we allow navigation
  private async CheckForPendingFormChangesAndAlertIfNeeded(message: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });

    if (ApplicationPage.isDirtyForm) {
      this.userAbandonUnsavedChangesInForm().then(response => {
        if (response) {
          if (this.application) {
            if (message === 'Are you sure you want to reserve this application?') {
              resolveFunction(true);
            } else {
              this.applicationService.amoUpdateApplication(this.application).subscribe(response => {
                if (response.type === 'danger') {
                  this.smartToastService.show(response.message, response.type, 10000);
                  resolveFunction(false);
                } else {
                  this.store.dispatch(
                    ApplicationActions.setApplication({
                      applicationObject: { ...response.application },
                    }),
                  );

                  this.store.dispatch(
                    ApplicationActions.setSave({
                      applicationSave: { ...response.application },
                    }),
                  );

                  this.smartToastService.show(response.message, response.type, 10000);

                  if (response.submessage) {
                    response.submessage.forEach(item => {
                      this.smartToastService.show(item.message, item.type, 10000);
                    });
                  }
                  resolveFunction(true);
                }
              });
            }
          }
        } else {
          resolveFunction(false);
        }
      });
    } else {
      resolveFunction(true);
    }
    return promise;
  }

  public async acceptApplication() {
    this.popCtrl.dismiss('acceptApplication');

    const confirm = await this.CheckForPendingFormChangesAndAlertIfNeeded('Are you sure you want to accept this application?');
    if (confirm) {
      const loading = await this.loadingController.create({
        message: 'Saving...',
      });
      loading.present();
      this.toolService
        .acceptApplication({
          id: this.application.id,
        })
        .subscribe(
          (res: any) => {
            loading.dismiss();
            this.smartToastService.show(res.message, res.type, 5000);

            if (res.submessage) {
              res.submessage.forEach(item => {
                this.smartToastService.show(item.message, res.type, 5000);
              });
            }

            // trigger show application endpoint
            this.store.dispatch(
              ApplicationActions.setSave({
                applicationSave: { ...res.application },
              }),
            );
            this.applicationService.setApplicationStore(res.application);
            this.reloadCurrentRoute();
          },

          err => {
            loading.dismiss();
            this.errorAlert();
          },
        );
    }
  }

  public async AddPayment() {
    this.popCtrl.dismiss('addPayment');
    //
    this.navigationService.main(
      'payments.create',
      {},
      {
        applicationId: this.application.id,
        userId: this.traineeid,
      },
    );
  }

  public async preApproveApplication() {
    this.popCtrl.dismiss('preApproveApplication');

    const confirm = await this.CheckForPendingFormChangesAndAlertIfNeeded('Are you sure you want to Pre-Approve this application?');
    if (confirm) {
      const loading = await this.loadingController.create({
        message: 'Saving...',
      });
      loading.present();
      this.toolService
        .preApproveApplication({
          id: this.application.id,
        })
        .subscribe(
          (res: any) => {
            loading.dismiss();
            this.smartToastService.show(res.message, res.type, 5000);

            if (res.submessage) {
              res.submessage.forEach(item => {
                this.smartToastService.show(item.message, res.type, 5000);
              });
            }

            // trigger show application endpoint
            this.store.dispatch(
              ApplicationActions.setSave({
                applicationSave: { ...res.application },
              }),
            );
            this.applicationService.setApplicationStore(res.application);

            this.reloadCurrentRoute();
          },
          err => {
            loading.dismiss();
            this.errorAlert();
          },
        );
    }
  }

  public async reserveApplication() {
    this.popCtrl.dismiss('reserveApplication');

    const confirm = await this.CheckForPendingFormChangesAndAlertIfNeeded('Are you sure you want to reserve this application?');
    if (confirm) {
      const loading = await this.loadingController.create({
        message: 'Saving...',
      });
      loading.present();
      this.toolService.reserveApplication(this.application).subscribe(
        (res: any) => {
          loading.dismiss();
          if (res.type === 'danger') {
            this.presentToast(res.message, res.type);
          } else {
            this.smartToastService.show(res.message, res.type, 5000);

            this.store.dispatch(
              ApplicationActions.setSave({
                applicationSave: { ...res.application },
              }),
            );
            if (res.submessage) {
              res.submessage.forEach(item => {
                this.smartToastService.show(item.message, res.type, 5000);
              });
            }

            // trigger show application endpoint
            this.applicationService.setApplicationStore(res.application);

            this.reloadCurrentRoute();
          }
        },
        err => {
          loading.dismiss();
          this.errorAlert();
        },
      );
    }
  }

  public async enrollApplication() {
    this.popCtrl.dismiss('enrollApplication');

    const confirm = await this.CheckForPendingFormChangesAndAlertIfNeeded('Are you sure you want to enroll this application?');
    if (confirm) {
      const loading = await this.loadingController.create({
        message: 'Saving...',
      });
      loading.present();
      this.toolService
        .enrollApplication({
          id: this.application.id,
        })
        .subscribe(
          (res: any) => {
            loading.dismiss();
            if (res.type === 'danger') {
              this.presentToast(res.message, res.type);
            } else {
              this.smartToastService.show(res.message, res.type, 5000);

              this.store.dispatch(
                ApplicationActions.setSave({
                  applicationSave: { ...res.application },
                }),
              );
              if (res.submessage) {
                res.submessage.forEach(item => {
                  this.smartToastService.show(item.message, res.type, 5000);
                });
              }

              // trigger show application endpoint
              this.applicationService.setApplicationStore(res.application);

              this.reloadCurrentRoute();
            }
          },
          err => {
            loading.dismiss();
            this.errorAlert;
          },
        );
    }
  }

  public async postponeApplication() {
    this.popCtrl.dismiss('enrollApplication');

    const confirm = await this.CheckForPendingFormChangesAndAlertIfNeeded('Are you sure you want to postpone this application?');
    if (confirm) {
      // this.modalService.ApplicationPostponeReasonsModal( {type : ""})
      // .then((reason) => {
      //   const postpone_reason = reason;
      const postponementData: any = await this.modalService.ApplicationPostponeReasonsModal({ reason: this.reason, date: this.date });
      if (postponementData) {
        const loading = await this.loadingController.create({
          message: 'Saving...',
        });
        loading.present();
        this.toolService
          .postponeApplication({
            id: this.application.id,
            reason: postponementData.reason,
            date: postponementData.date,
          })
          .subscribe(
            (res: any) => {
              loading.dismiss();
              if (res.type === 'danger') {
                this.presentToast(res.message, res.type);
              } else {
                this.smartToastService.show(res.message, res.type, 5000);

                this.store.dispatch(
                  ApplicationActions.setSave({
                    applicationSave: { ...res.application },
                  }),
                );
                if (res.submessage) {
                  res.submessage.forEach(item => {
                    this.smartToastService.show(item.message, res.type, 5000);
                  });
                }

                // trigger show application endpoint
                this.applicationService.setApplicationStore(res.application);

                this.reloadCurrentRoute();
              }
            },
            err => {
              loading.dismiss();
              this.errorAlert;
            },
          );
        //});
      }
    }
  }

  public async refundApplication() {
    this.popCtrl.dismiss('enrollApplication');

    const confirm = await this.CheckForPendingFormChangesAndAlertIfNeeded('Are you sure you want to refund this application?');
    if (confirm) {
      const loading = await this.loadingController.create({
        message: 'Saving...',
      });
      loading.present();
      this.toolService
        .refundApplication({
          id: this.application.id,
        })
        .subscribe(
          (res: any) => {
            loading.dismiss();
            if (res.type === 'danger') {
              this.presentToast(res.message, res.type);
            } else {
              this.smartToastService.show(res.message, res.type, 5000);

              this.store.dispatch(
                ApplicationActions.setSave({
                  applicationSave: { ...res.application },
                }),
              );
              if (res.submessage) {
                res.submessage.forEach(item => {
                  this.smartToastService.show(item.message, res.type, 5000);
                });
              }

              // trigger show application endpoint
              this.applicationService.setApplicationStore(res.application);

              this.reloadCurrentRoute();
            }
          },
          err => {
            loading.dismiss();
            this.errorAlert;
          },
        );
    }
  }

  public async cancelApplication() {
    this.popCtrl.dismiss('cancelApplication');

    const confirm = await this.CheckForPendingFormChangesAndAlertIfNeeded('Are you sure you want to cancel this application?');
    if (confirm) {
      const loading = await this.loadingController.create({
        message: 'Saving...',
      });
      loading.present();
      this.toolService
        .cancelApplication({
          id: this.application.id,
        })
        .subscribe(
          (res: any) => {
            loading.dismiss();
            this.smartToastService.show(res.message, res.type, 5000);

            if (res.submessage) {
              res.submessage.forEach(item => {
                this.smartToastService.show(item.message, res.type, 5000);
              });
            }

            if (res.emailResponse && res.emailResponse.response && res.emailResponse.response.responseArray) {
              res.emailResponse.response.responseArray.forEach(item => {
                this.smartToastService.show(item.message, res.type, 5000);
              });
            }

            if (res.success || res.type === 'success') {
              // trigger show application endpoint
              this.store.dispatch(
                ApplicationActions.setSave({
                  applicationSave: { ...res.application },
                }),
              );
              this.applicationService.setApplicationStore(res.application);
              setTimeout(() => {
                this.reloadCurrentRoute();
              }, 3000);
            }
          },
          err => {
            loading.dismiss();
            this.errorAlert();
          },
        );
    }
  }

  public async rejectApplication() {
    this.popCtrl.dismiss('rejectApplication');

    const confirm = await this.CheckForPendingFormChangesAndAlertIfNeeded('Are you sure you want to reject this application?');
    if (confirm) {
      const loading = await this.loadingController.create({
        message: 'Saving...',
      });
      loading.present();
      this.toolService
        .rejectApplication({
          id: this.application.id,
        })
        .subscribe(
          (res: any) => {
            loading.dismiss();
            this.presentToast(res.message, res.type);
            window.location.reload();
          },
          err => {
            loading.dismiss();
            this.errorAlert();
          },
        );
    }
  }

  public async errorAlert() {
    const alert = await this.alertController.create({
      header: 'Attention',
      message: 'You do not have permissions needed to complete this action.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          // handler: (blah) => {
          //   console.log('Confirm Cancel: blah');
          // }
        },
      ],
    });

    await alert.present();
  }

  public async assignCoach() {
    const loading = await this.loadingController.create({
      message: 'Saving...',
    });
    loading.present();
    this.applicationService.amoAssignCoach(this.application.id).subscribe(
      (res: any) => {
        loading.dismiss();
        if (res) {
          this.presentToast(res.message, res.coach ? 'success' : 'danger').then(() => {
            if (res.type === 'danger') {
              this.popCtrl.dismiss('assignCoach');
            } else {
              this.popCtrl.dismiss('assignCoach');
              location.reload();
            }
          });
        }
      },
      err => {
        loading.dismiss();
        this.errorAlert();
      },
    );
  }

  public async assignCoordinator() {
    this.popCtrl.dismiss('assignCoordinator');
    const loading = await this.loadingController.create({
      message: 'Saving...',
    });
    loading.present();
    this.applicationService.amoAssignCoordinator(this.application.id).subscribe(
      (res: any) => {
        loading.dismiss();
        if (res) {
          this.presentToast(res.message, res.coordinator ? 'success' : 'danger').then(() => {
            this.popCtrl.dismiss();
            location.reload();
          });
        }
      },
      err => {
        loading.dismiss();
        this.errorAlert();
      },
    );
  }

  public async deleteApplication() {
    this.popCtrl.dismiss('deleteApplication');

    const confirm = await this.CheckForPendingFormChangesAndAlertIfNeeded('Are you sure you want to delete this application?');
    if (confirm) {
      const loading = await this.loadingController.create({
        message: 'Saving...',
      });
      loading.present();
      this.applicationService.amoDeleteApplication(this.application.id).subscribe(
        res => {
          loading.dismiss();
          this.popCtrl.dismiss();
          if (this.user.type === 'AMOEMPLOYEE') {
            this.navCtrl.navigateForward('/new-application');
          } else if (this.user.type === 'Hosting') {
            this.navCtrl.navigateForward('/active-applications');
          } else {
            this.navCtrl.navigateForward('/dashboard');
          }
        },
        err => {
          loading.dismiss();
          this.errorAlert();
        },
      );
    }
  }

  switchApplication() {
    this.popCtrl.dismiss();
    this.router.navigate([`/new-application/details/${this.application.id}/create-hold`]);
  }

  swapApplication() {
    this.popCtrl.dismiss();
    this.router.navigate([`new-application/application-swap`], {
      queryParams: { applicationid1: this.application.id },
    });
  }

  //
  searchMatchingApps() {
    const query = {
      all: true,
      programid: this.application.programid,
      month: this.application.month,
      expired: false,
      startyear: this.application.startyear,
      paid: false,
    };
    const nextQueryURLString = JSON.stringify(query);

    // next url state
    const url = this.router
      .createUrlTree([`/new-application`], {
        relativeTo: this.route,
        queryParams: {
          query: nextQueryURLString,
        },
      })
      .toString();
    //
    // // location replacing state
    this.location.replaceState(url);
    // dismiss popover
    this.popCtrl.dismiss();

    // reload the window
    window.location.reload();
  }

  //
  searchMatchingCancelledApps() {
    // all: false,
    // programid: $scope.application.programid,
    // month: $scope.application.month,
    // expired: true,
    // startyear: $scope.application.startyear,

    const query = {
      all: false,
      programid: this.application.programid,
      month: this.application.month,
      expired: true,
      startyear: this.application.startyear,
    };

    const nextQueryURLString = JSON.stringify(query);

    // next url state
    const url = this.router
      .createUrlTree([`/new-application`], {
        relativeTo: this.route,
        queryParams: {
          query: nextQueryURLString,
        },
      })
      .toString();
    //
    // // location replacing state
    this.location.replaceState(url);
    // dismiss popover
    this.popCtrl.dismiss();

    // reload the window
    window.location.reload();
  }

  // https://stackoverflow.com/questions/40983055/how-to-reload-the-current-route-with-the-angular-2-router
  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl(`/new-application/details/${this.application.id}/application-quick-summary`, { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  setUpApplicationForm(): boolean {
    this.applicationService.setApplicationForm(this.application, this.application_form);

    this.dateConfig.startdate = this.getFormatDate(this.application.startdate);
    this.dateConfig.enddate = this.getFormatDate(this.application.enddate);
    this.dateConfig.expires = this.getFormatDate(this.application.expires);
    this.dateConfig.migrationdocumentdate = this.getFormatDate(this.application.migrationdocumentdate);

    this.dateConfig.signedcontract = this.getFormatDate(this.application.signedcontract);
    this.dateConfig.accepteddate = this.getFormatDate(this.application.accepteddate);
    this.dateConfig.reserveddate = this.getFormatDate(this.application.reserveddate);
    this.dateConfig.enrolleddate = this.getFormatDate(this.application.enrolleddate);

    this.dateConfig.preenrollmentorientation_datetime = this.getFormatDate(
      this.application.preenrollmentorientation != null ? this.application.preenrollmentorientation.datetime : '',
    );

    this.dateConfig.predepartureorientation_datetime = this.getFormatDate(
      this.application.predepartureorientation != null ? this.application.predepartureorientation.datetime : '',
    );

    return true;
  }

  getFormattedDate(date, format: string = 'MM/DD/YYYY') {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    if (format == 'MM/DD/YYYY') {
      //default
      return month + '/' + day + '/' + year;
    } else if (format == 'YYYY-MM-DD') {
      return year + '-' + month + '-' + day;
    }
  }

  getFormatDate(date) {
    try {
      if (date == null || date == '' || date == 'Invalid Date') {
        date = '';
      } else {
        const newDate = new Date(date);
        if (newDate.toString() == 'Invalid Date') {
          return '';
        }
        date = this.getFormattedDate(newDate, 'MM/DD/YYYY');
      }
      return date;
    } catch (e) {
      return '';
    }
  }
}
