import { Component } from '@angular/core';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import { faFileCheck, faFileSignature } from '@fortawesome/pro-solid-svg-icons';
import { faFileExclamation } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-requirements-status-legend',
  templateUrl: './requirements-status-legend.component.html',
  styleUrls: ['./requirements-status-legend.component.scss'],
})
export class RequirementsStatusLegendComponent {
  //icons
  faFile = faFile;
  faFileCheck = faFileCheck;
  faFileExclamation = faFileExclamation;
  faFileSignature = faFileSignature;
}
