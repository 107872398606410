import { createAction, props } from "@ngrx/store";

export const setTrainee = createAction(
  //setTrainee
  "[Trainee] Set Trainee",
  props<{ traineeObject: object }>()
);
// trainee document key item 1 cv/resume
export const setDocumentItemOne = createAction(
  //setTrainee
  "[Trainee] Set Trainee Document CV Resume",
  props<{ document_ItemOne: any }>()
);
// trainee document key item 10 toefl
export const setDocumentItemTen = createAction(
  //setTrainee
  "[Trainee] Set Trainee Document TOEFL",
  props<{ document_ItemTen: any }>()
);
// trainee document key item 4 school records
export const setDocumentItemFour = createAction(
  "[Trainee] Set Trainee Document School Records",
  props<{ document_ItemFour: any }>()
);