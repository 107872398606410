<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <div>
          <ion-list>
            <ion-list-header class="ion-text-center">
              <ion-label>Reset Password</ion-label>
            </ion-list-header>
            <div class="ion-margin-start ion-margin-end form-input">
              <amo-input
                [(ngModel)]="password"
                ngDefaultControl
                type="password"
                name="password"
                label="Current password"
                labelcolor="labelgreen"
                size="inputwidth-100"
                inputname="password"
              >
              </amo-input>
            </div>
            <div class="ion-margin-start ion-margin-end form-input">
              <amo-input
                [(ngModel)]="newPassword"
                ngDefaultControl
                type="password"
                name="newpassword"
                label="New password"
                labelcolor="labelgreen"
                size="inputwidth-100"
                inputname="newpassword"
                (ngModelChange)="listener($event)"
              >
              </amo-input>
            </div>
            <div class="ion-padding-start">
              <ion-label>
                <ion-note class="small-text"> Password requirements:</ion-note>
              </ion-label>
              <ion-item
                class="ps-item"
                [ngClass]="passwordOptions.eightChars ? 'pass' : 'not-pass'"
              >
                <fa-icon [icon]="faCheckCircle" class="check-icon"></fa-icon>
                <ion-label class="ion-text-wrap">
                  <ion-note class="small-text"
                    >Be at least 8 characters long</ion-note
                  >
                </ion-label>
              </ion-item>
              <ion-item
                class="ps-item"
                [ngClass]="passwordOptions.oneUpper ? 'pass' : 'not-pass'"
              >
                <fa-icon [icon]="faCheckCircle" class="check-icon"></fa-icon>
                <ion-label class="ion-text-wrap">
                  <ion-note class="small-text"
                    >At least 1 uppercase letter (A-Z)</ion-note
                  >
                </ion-label>
              </ion-item>
              <ion-item
                class="ps-item"
                [ngClass]="passwordOptions.oneLower ? 'pass' : 'not-pass'"
              >
                <fa-icon [icon]="faCheckCircle" class="check-icon"></fa-icon>
                <ion-label class="ion-text-wrap">
                  <ion-note class="small-text"
                    >At least 1 lowercase letter (a-z)</ion-note
                  >
                </ion-label>
              </ion-item>
              <ion-item
                class="ps-item"
                [ngClass]="passwordOptions.oneNumber ? 'pass' : 'not-pass'"
              >
                <fa-icon [icon]="faCheckCircle" class="check-icon"></fa-icon>
                <ion-label class="ion-text-wrap">
                  <ion-note class="small-text"
                    >At least 1 number (0-9)</ion-note
                  >
                </ion-label>
              </ion-item>
              <ion-item
                class="ps-item"
                [ngClass]="passwordOptions.oneSpecial ? 'pass' : 'not-pass'"
              >
                <fa-icon [icon]="faCheckCircle" class="check-icon"></fa-icon>
                <ion-label class="ion-text-wrap">
                  <ion-note class="small-text"
                    >At least 1 special character (!&#64;#...)</ion-note
                  >
                </ion-label>
              </ion-item>

              <!-- <ion-item>
              <ion-icon
                color="success"
                *ngIf="passwordOptions?.match"
                slot="start"
                name="checkmark-circle"
              >
              </ion-icon>
              <ion-icon
                color="danger"
                *ngIf="!passwordOptions?.match"
                slot="start"
                name="alert"
              ></ion-icon>
              <ion-label class="ion-text-wrap">
                <ion-note>Confirmation must match password</ion-note>
              </ion-label>
            </ion-item> -->
            </div>
            <div class="ion-margin-start ion-margin-end form-input">
              <amo-input
                [(ngModel)]="confirmNewPassword"
                ngDefaultControl
                type="password"
                name="confirmnewpassword"
                label="Confirm new password"
                labelcolor="labelgreen"
                size="inputwidth-100"
                inputname="confirmnewpassword"
              >
              </amo-input>
            </div>
            <!-- <ion-item
              class="ps-item ion-padding-start"
              *ngIf="confirmNewPassword && passwordOptions.match === false"
            >
              <fa-icon
                [icon]="faCheckCircle"
                class="check-icon"
                [ngClass]="passwordOptions.match ? 'pass' : 'not-pass'"
              ></fa-icon>
              <ion-label class="ion-text-wrap">
                <ion-note class="small-text">New passwords must match</ion-note>
              </ion-label>
            </ion-item> -->
          </ion-list>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar>
    <div class="btn-flex">
      <div>
        <ion-button
          fill="outline"
          color="secondary"
          size="medium"
          type="button"
          class="default-btn"
          (click)="dismiss()"
        >
          Cancel
        </ion-button>
      </div>
      <div>
        <ion-button
          (click)="updateAuthUser()"
          fill="solid"
          color="secondary"
          size="medium"
          type="button"
          class="default-btn"
        >
          Submit
        </ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>
