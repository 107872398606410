<div class="parent">
  <img class="amo-logo" src="assets/logo/logo_orange.png" />
  <div class="title bold-text">
    Success!
  </div>
  <div [innerHTML]='message'>
  </div>
  <div>
    <fa-icon class="icon-size" slot="start" [icon]="faCheckCircle"></fa-icon>
  </div>
  <div class="confirm-button">
    <ion-button color="primary" (click)="close()" class="half-button">
      Close
    </ion-button>
  </div>
</div>