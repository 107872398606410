import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { MenuController } from "@ionic/angular";

@Injectable({
  providedIn: "root"
})
export class LogOffGuard  {

  constructor(
    private menuCtrl: MenuController,
    public authService: AuthService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    //console.log("executing logoff");
    try{
      this.authService.logout();
      this.menuCtrl.enable(false);
    }catch(e){
      console.log(e);
    }
    return true;
  }
}