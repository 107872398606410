import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ListsService {
  constructor(private http: HttpClient) {}
  // **************************   Recruitment   **************************

  // recruitment visitors list *

  // *** depreciated ***
  managerrecruitmentvisitorlist(query): Observable<any> {
    let safeQuery;
    if (typeof query !== 'string') {
      safeQuery = encodeURI(`query=${JSON.stringify(query)}`);
    } else {
      safeQuery = `query=${query}`;
    }
    return this.http.get(`${environment.api}/managements/managerrecruitmentvisitorlist?${safeQuery}`);
  }

  managertraineelist(query): Observable<any> {
    let safeQuery;
    if (typeof query !== 'string') {
      safeQuery = encodeURI(`query=${JSON.stringify(query)}`);
    } else {
      safeQuery = `query=${query}`;
    }
    return this.http.get(`${environment.api}/managements/managertraineelist?${safeQuery}`);
  }

  // // recruitment program list *
  // experiencelist(id): Observable<any> {
  //   return this.http.get(`${environment.api}/managements/experiencelist`, id);
  // }
  /**
   * @description load experience list, called by program list component
   */
  experiencelist(id: string, query: any = null): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/managements/experiencelist?id=${id}&${encodedQuery}`);
  }

  // recruitment applications list *
  applicationslist(query): Observable<any> {
    return this.http.get(`${environment.api}/managements/applicationslist?expired=${query.expired}&all=${query.all}&paid=${query.paid}`);
  }

  // **************************   Hosting   **************************

  // hosting dashboard calendar and grid list *
  managerhostingcalenderlist(id: any, onlypaid: boolean, activeOnly: boolean): Observable<any> {
    return this.http.get(`${environment.api}/managements/managerhostingcalenderlist?paidapplicants=${onlypaid}&activeapplications=${activeOnly}`, id);
  }

  // hosting dashboard calendar and grid list *
  managerhostingcalenderlistdaterange(id: any, onlypaid: boolean, activeOnly: boolean, dateRange: any): Observable<any> {
    return this.http.get(
      `${environment.api}/managements/managerhostingcalenderlist?paidapplicants=${onlypaid}&activeapplications=${activeOnly}&start=${dateRange.start}&end=${dateRange.end}`,
      id,
    );
  }

  // hosting visitors list *
  managerhostingvisitorlist(query): Observable<any> {
    let safeQuery;
    if (typeof query !== 'string') {
      safeQuery = encodeURI(`query=${JSON.stringify(query)}`);
    } else {
      safeQuery = `query=${query}`;
    }
    return this.http.get(`${environment.api}/managements/managerhostingvisitorlist?${safeQuery}`);
  }
  /**
   * @description load manager hosting programs list, called by program list component
   */
  managerhostingprogramslist(id: string, query: any = null): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/managements/managerhostingprogramslist?id=${id}&${encodedQuery}`);
  }

  // hosting active applications list
  managerhostingactiveapplicationslist(query: any): Observable<any> {
    return this.http.get(`${environment.api}/managements/managerhostingactiveapplicationslist?expired=${query.expired}&all=${query.all}&paid=${query.paid}`);
  }

  // hosting completed applications list *
  managerhostingcompletedapplicationslist(id): Observable<any> {
    return this.http.get(`${environment.api}/managements/managerhostingcompletedapplicationslist`, id);
  }

  // hosting coordinator list for their department *
  managerhostingcoordinatorlist(id): Observable<any> {
    return this.http.get(`${environment.api}/managements/managerhostingcoordinatorlist`, id);
  }

  findAdminList(id): Observable<any> {
    return this.http.get(`${environment.api}/users/${id}/findadminusers?ngsw-bypass=true`);
  }

  /**
   * @description load host list, called by host list component
   */
  managerhostinghostslist(query: any = null): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/managements/managerhostinghostslist?${encodedQuery}`);
  }

  departmentslist(query: any): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/departments?${encodedQuery}`);
  }

  managerslist(query: any): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/managements?${encodedQuery}`);
  }

  /**
   * @description load program list, called by program list component
   */
  amoemployeeprogramlist(query: any = null): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/programs/fullindex?${encodedQuery}`);
  }

  /**
   * @description load program availability list, called by program-availability list component
   */

  amoemployeeprogramavailabilitylist(query: any = null): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/program-availability/fullindex?${encodedQuery}`);
  }

  /**
   * @description load hosts programs list
   */
  HostsProgramList(hostId: string): Observable<any> {
    // eg: hosts/4tb60ea5-4980-4f58-a1fc-996395a5327d/listhostprograms
    const ret: Observable<any> = this.http.get(`${environment.api}/hosts/${hostId}/listhostprograms`);
    return ret;
  }

  /**
   * @description load program bundles list, called by program bundles list component
   */
  amoemployeeprogrambundleslist(query: any = null): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.post(`${environment.api}/programbundles/list`, {
      encodedQuery,
    });
  }

  amomanagervisitorlist(query: any) {
    let safeQuery;
    if (typeof query !== 'string') {
      safeQuery = encodeURI(`query=${JSON.stringify(query)}`);
    } else {
      safeQuery = `query=${query}`;
    }

    return this.http.get(`${environment.api}/users?${safeQuery}`);
  }

  amoCouponlist(query: any) {
    let safeQuery;
    if (typeof query !== 'string') {
      safeQuery = encodeURI(`query=${JSON.stringify(query)}`);
    } else {
      safeQuery = `query=${query}`;
    }

    return this.http.get(`${environment.api}/coupons?${safeQuery}`);
  }

  /**
   * @description load host list, called by host list component
   */
  amomanagerhostslist(query: any = null) {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/hosts?${encodedQuery}`);
  }

  /**
   * @description load application list , called by application list component
   */
  amomanagerapplicationlist(query: any) {
    // const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    const encodedQuery = `query=${encodeURIComponent(JSON.stringify(query))}`;
    return this.http.get(`${environment.api}/applications/fullindex?${encodedQuery}`);
  }

  /**
   * @description load application chain list , called by application chain list component
   */
  amomanagerapplicationchainlist(query: any) {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/applicationchains/adminlistapplicationchains?${encodedQuery}`);
  }

  paymentslist(query: any) {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/payments?${encodedQuery}`);
  }

  reviewslist(query: any) {
    const encodedQuery = `query=${encodeURIComponent(JSON.stringify(query))}`;
    return this.http.get(`${environment.api}/reviews?${encodedQuery}`);
  }

  fieldofStudiesList(query: any) {
    const encodedQuery = `query=${encodeURIComponent(JSON.stringify(query))}`;
    return this.http.get(`${environment.api}/reviews?${encodedQuery}`);
  }

  /**
   * @description load documents list
   */
  amoemployeedocumentsbundleslist(query: any = null): Observable<any> {
    const data = { where: query };
    return this.http.post(`${environment.api}/document/list`, data);
  }

  /**
   * @description load application requirement list
   */
  amoemployeRequirementslist(query: any = null): Observable<any> {
    const data = { where: query }; //{"where":{}}
    return this.http.post(`${environment.api}/applicationrequirements/list`, data);
  }

  /**
   * @description list Review Templates
   */
  amoemployeReviewTemplateslist(): Observable<any> {
    return this.http.get(`${environment.api}/reviewtemplate`);
  }

  /**
   * @description load application requirements map list
   */
  applicationRequirementsMapList(query: any = null): Observable<any> {
    const data = { where: query }; //{"where":{}}
    return this.http.post(`${environment.api}/applicationrequirementmaps/listapplicationrequirements`, data);
  }

  itinerariesList(query: any = null): Observable<any> {
    const data = { where: query }; //{"where":{}}
    return this.http.post(`${environment.api}/itineraries/adminlist`, data);
  }

  /**
   *
   * @param traineeid string, trainee/user id
   * @returns object with fields array rows and number count
   */
  traineeApplicationsList(trainee: { id: string }): Observable<any> {
    return this.http.get(`${environment.api}/users/${trainee.id}/usersapplicationlist`);
  }

  traineeApplicationsListWithQuery(query?: any): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/users/${query.id}/usersapplicationlist?${encodedQuery}`);
  }

  orderslist(query: any): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/orders/fullindex?${encodedQuery}`);
  }

  applicationOrderTypesList(query?: any): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/applicationordertypes/list?${encodedQuery}`);
  }

  orderProgramRotationsSeatsList(query?: any): Observable<any> {
    const encodedQuery = encodeURI(`query=${JSON.stringify(query)}`);
    return this.http.get(`${environment.api}/orders/orderseatslist?${encodedQuery}`);
  }
}
// * = switched to cognito auth guard
