import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"]
})
export class ImageUploadComponent implements OnInit {
  imgSrc: any = [];
  options = {
    fileSize: 2048, // in Bytes (by default 2048 Bytes = 2 MB)
    minWidth: 0, // minimum width of image that can be uploaded (by default 0, signifies any width)
    maxWidth: 0, // maximum width of image that can be uploaded (by default 0, signifies any width)
    minHeight: 0, // minimum height of image that can be uploaded (by default 0, signifies any height)
    maxHeight: 0, // maximum height of image that can be uploaded (by default 0, signifies any height)
    fileType: ["image/gif", "image/jpeg", "image/png", "image/PNG"], // mime type of files accepted
    height: 400, // height of cropper
    quality: 0.8, // quaity of image after compression
    crop: [
      // array of objects for mulitple image crop instances (by default null, signifies no cropping)
      {
        ratio: 1.5, // ratio in which image needed to be cropped (by default null, signifies ratio to be free of any restrictions)
        minWidth: 0, // minimum width of image to be exported (by default 0, signifies any width)
        maxWidth: 0, // maximum width of image to be exported (by default 0, signifies any width)
        minHeight: 0, // minimum height of image to be exported (by default 0, signifies any height)
        maxHeight: 0, // maximum height of image to be exported (by default 0, signifies any height)
        width: 0, // width of image to be exported (by default 0, signifies any width)
        height: 0 // height of image to be exported (by default 0, signifies any height)
      }
    ]
  };
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  onSelect($event: any) {
    this.imgSrc = [];
    switch (typeof $event) {
      case "string":
        this.imgSrc = [$event];
        break;
      case "object":
        this.imgSrc = $event;
        break;
      default:
    }
  }

  reset() {
    this.imgSrc = [];
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: "image/jpg"
    });
  }

  upload() {
    const date = new Date().valueOf();
    let text = "";
    const possibleText =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type
    const imageName = date + "." + text + ".jpeg";
    // call method that creates a blob from dataUri
    const imageBlob = this.dataURItoBlob(this.imgSrc[0]);
    const imageFile = new File([imageBlob], imageName, { type: "image/jpeg" });
    this.modalCtrl.dismiss(imageFile);
  }
}
