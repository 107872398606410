import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class FieldOfStudiesService {
  constructor(private http: HttpClient) {}

  getFieldOfStudiesList(): Observable<any> {
    return this.http.get(`${environment.api}/fieldofstudies/list`);
  }

  getFieldOfStudiesDetails(id: string): Observable<any> {
    return this.http.get(`${environment.api}/fieldofstudies/${id}/show`);
  }

  saveFieldOfStudiesDetails(form: any): Observable<any> {
    return this.http.patch(
      `${environment.api}/fieldofstudies/${form.id}/update`,
      form
    );
  }

  createFieldOfStudiesDetails(form: any): Observable<any> {
    return this.http.post(`${environment.api}/fieldofstudies/create`, form);
  }

  deleteFieldOfStudiesDetails(id: string): Observable<any> {
    return this.http.delete(`${environment.api}/fieldofstudies/${id}/delete`);
  }

  // list of field of studies > dropdown
  listOfFieldOfStudies = (
    hasDepartments: boolean,
    showProfileBlock: boolean,
    userid?: string
  ): Observable<any> => {
    return this.http.get(
      `${environment.api}/fieldofstudies/fieldofstudieslist?hasdepartments=${hasDepartments}&allowprofileblock=${showProfileBlock}&userid=${userid}`
    );
  };
}
