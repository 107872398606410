<ion-list>
  <ion-item lines="none">
    <ion-label>Have questions?</ion-label>
  </ion-item>
  <ion-item>
    <a href="mailto:{{ (toolService.content | async)?.basic?.email?.support }}">{{ (toolService.content | async)?.basic?.email?.support }}</a>
  </ion-item>
  <ion-item>
    <a href="tel:{{ (toolService.content | async)?.basic?.phone?.main }}">{{ (toolService.content | async)?.basic?.phone?.main }} </a>
  </ion-item>
</ion-list>
