<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-list lines="none">
          <ion-list-header class="ion-text-center ion-no-padding">
            <ion-label
              class="card-title-verify"
              *ngIf="type === 'email' || type === 'confirm_signup'"
              >Verify your email</ion-label
            >
            <ion-label class="card-title-verify" *ngIf="type === 'phone'"
              >Verify your mobile phone number</ion-label
            >
          </ion-list-header>
          <div class="ion-text-center icon-container">
            <fa-icon
              [icon]="faEnvelope"
              class="icon-lg"
              *ngIf="type === 'email' || type === 'confirm_signup'"
            ></fa-icon>
            <fa-icon
              [icon]="faMobile"
              class="icon-lg"
              *ngIf="type === 'phone'"
            ></fa-icon>
          </div>
          <div class="ion-text-center">
            <p class="small-text">Please enter the 6 digit code sent to</p>
            <p
              class="bold ion-no-margin small-text"
              *ngIf="type === 'email' || type === 'confirm_signup'"
            >
              {{ email }}
            </p>
            <p class="bold ion-no-margin small-text" *ngIf="type === 'phone'">
              {{ phone }}
            </p>
          </div>
          <div class="ion-text-center">
            <ion-button
              fill="clear"
              shape="round"
              color="secondary"
              size="sm"
              class="secondary-action-btn"
              (click)="resendCode()"
            >
              Resend Code
            </ion-button>
          </div>
        </ion-list>
      </ion-col>
      <ion-col size="12">
        <section class="code-input-container ion-margin-bottom">
          <div class="ion-text-center">
            <amo-input
              class="input-code"
              placeholder="123456"
              type="text"
              [(ngModel)]="code"
              inputname="code"
              name="code"
              ngDefaultControl
            ></amo-input>
          </div>
        </section>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <div class="ion-text-center">
          <ion-button
            (click)="configureVerifyFunction()"
            fill="solid"
            color="secondary"
            class="default-btn"
          >
            Verify
          </ion-button>
        </div>
      </ion-col>
      <ion-col size="12">
        <div class="ion-text-center">
          <ion-button
            fill="clear"
            shape="round"
            color="secondary"
            size="sm"
            class="secondary-action-btn"
            (click)="changeEmail()"
          >
            {{
              type === "email"
                ? "Change Email"
                : type === "confirm_signup"
                ? "Did not get your code?"
                : "Change mobile phone number"
            }}
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
