import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { GridAnchorRenderer } from './components/grid-anchor';
import { GridLinkRenderer } from './components/grid-link';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GridSelectionRenderer } from './components/grid-selection';
import { GridJSONRenderer } from './components/grid-json';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [GridAnchorRenderer, GridLinkRenderer, GridSelectionRenderer, GridJSONRenderer],
  imports: [IonicModule, FontAwesomeModule, RouterModule],
  exports: [GridAnchorRenderer, GridLinkRenderer, GridSelectionRenderer, GridJSONRenderer],
})
export class GridComponentsModule {}
