import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import { PopoverController } from '@ionic/angular';

/** @title Datepicker with custom calendar header */
@Component({
  selector: 'app-range-calendar',
  templateUrl: `./range-calendar.component.html`,
  styleUrls: ['./range-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // need provider to change inline calendar to daterange
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    {
      //dont want to use moment.js
      provide: DateAdapter,
      useClass: NativeDateAdapter,
    },
  ],
})
export class RangeCalendarComponent implements AfterViewInit {
  selected: Date | null;
  customHeader = CustomHeaderComponent;
  @Input() range: any;

  start: Date;
  startType: string = 'text';
  end: Date;
  endType: string = 'text';
  selectedRangeValue: DateRange<Date> = new DateRange(null, null);

  @Output() dateEvent = new EventEmitter<any>();

  // this is to switch the typing of the inputs above the calendar
  @ViewChildren(NgModel) inputRefs: QueryList<NgModel>;

  // to switch calendar month on input
  @ViewChild('calendar') calendar: MatCalendar<Date>;

  constructor(private dateAdapter: DateAdapter<Date>, private popoverCtrl: PopoverController) {}

  // need after view init because want the inputs to resolve first
  ngAfterViewInit(): void {
    this.inputRefs.forEach(ref => {
      ref.valueChanges.subscribe(change => {
        // changes based on initial values
        if (change !== undefined) ref.name === 'startDate' ? (this.startType = 'date') : (this.endType = 'date');

        // changes type when clear is hit
        if (change === null) {
          this.startType = 'text';
          this.endType = 'text';
        }
      });
    });

    if (this.range) {
      this.selectedRangeValue = new DateRange(this.range.start, this.range.end);
      this.start = this.range.start.toISOString().split('T')[0];
      this.end = this.range.end.toISOString().split('T')[0];
    }
  }

  // on the selected date change we are going one day in the future, to allow for 
  selectedChange(date: any) {
    // anytime we change a selection, clear it including the start and end variables
    if (this.selectedRangeValue && this.selectedRangeValue.start && date > this.selectedRangeValue.start && !this.selectedRangeValue.end) {
      this.end = date.toISOString().split('T')[0] || null;
      // ensure that the range is populated with 24 hour range
      this.selectedRangeValue = new DateRange(this.selectedRangeValue.start, new Date(date.setHours(23, 59, 0, 0)));
    } else {
      this.start = date.toISOString().split('T')[0];
      // ensure date is populated with midnight to allow for all values to be shown
      this.selectedRangeValue = new DateRange(new Date(date.setHours(0,0,0,0)), null);
    }
  }

  inputChange(event: any, mode: 'start' | 'end') {
    // make date object CDT day so type in works
    const timestamp = Date.parse(event.target.valueAsDate);
    // keep date from going to high or low, crazy values can change
    if (isNaN(timestamp) === false && event.target.valueAsDate.getFullYear() > 1970 && event.target.valueAsDate.getFullYear() < 9999) {
      const date = new Date(event.target.valueAsDate.setHours(24));
      this.selectedRangeValue =
        mode === 'start' ? new DateRange(date, this.selectedRangeValue.end || null) : new DateRange(this.selectedRangeValue.start || null, date);

      this.calendar.activeDate = this.dateAdapter.addCalendarMonths(
        this.calendar.activeDate,
        date.getMonth() - this.calendar.activeDate.getMonth() + (date.getFullYear() - this.calendar.activeDate.getFullYear()) * 12,
      );
    }
  }

  clearSelection() {
    // for now if we clear set to defaults
    this.selectedRangeValue = null;
    this.end = null;
    this.start = null;
    this.applySelection();
  }

  applySelection() {
    if (this.selectedRangeValue) {
      const data = {
        start: this.selectedRangeValue.start,
        end: this.selectedRangeValue.end,
      };
      this.dateEvent.emit(data);
      // setTimeout(() => this.popoverCtrl.dismiss(data), 1500);
    } else {
      // sent only when clear is hit
      this.dateEvent.emit(null);
    }
  }
}

/** Custom header component for calendar. */ 0;
@Component({
  selector: 'custom-header',
  styles: [
    `
      .header {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        border-block: 1px solid lightgrey;
      }

      .header-label {
        flex: 1;
        font-weight: 500;
        text-align: center;
        color: var(--ion-color-secondary);
      }
    `,
  ],
  template: `
    <div class="header">
      <button mat-icon-button (click)="previousClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="header-label">{{ monthLabel }}</span>
      <button mat-icon-button (click)="nextClicked()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
  providers: [
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomHeaderComponent implements OnDestroy {
  private destroyed = new Subject<void>();

  constructor(private calendar: MatCalendar<Date>, private dateAdapter: DateAdapter<Date>, ref: ChangeDetectorRef) {
    // allows us to adjust header while component is not destroyed, if there is a change in the state,
    // change this component, specifically rerendering monthLabel
    calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => ref.markForCheck());
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get monthLabel() {
    return `${this.calendar.activeDate.toLocaleString('default', { month: 'long' })} ${this.calendar.activeDate.getFullYear()}`;
    // return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel).toLocaleUpperCase();
  }

  previousClicked() {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
  }

  nextClicked() {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
  }
}
