<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <div>
          <ion-list>
            <ion-list-header class="ion-text-center">
              <ion-label>Update Email</ion-label>
            </ion-list-header>
            <div class="ion-margin-start ion-margin-end form-input">
              <amo-input
                [(ngModel)]="email"
                ngDefaultControl
                type="email"
                name="email"
                label="Email"
                labelcolor="labelgreen"
                size="inputwidth-100"
                inputname="email"
                disabled="true"
              >
              </amo-input>
            </div>
            <div class="ion-margin-start ion-margin-end form-input">
              <amo-input
                [(ngModel)]="newEmail"
                ngDefaultControl
                type="email"
                name="newemail"
                label="New email"
                labelcolor="labelgreen"
                size="inputwidth-100"
                inputname="newemail"
              >
              </amo-input>
            </div>
          </ion-list>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar>
    <div class="btn-flex">
      <div>
        <ion-button
          fill="outline"
          color="secondary"
          size="medium"
          type="button"
          class="default-btn"
          (click)="dismiss(false)"
        >
          Cancel
        </ion-button>
      </div>
      <div>
        <ion-button
          (click)="openConfirmationModal()"
          [disabled]="!newEmail"
          fill="solid"
          color="secondary"
          size="medium"
          type="button"
          class="default-btn"
        >
          Submit
        </ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>
