<ion-list lines="none">
  <ion-item
    *ngIf="(managerType$ | async) === 'AMOEMPLOYEE'"
    button
    (click)="AddPayment()"
  >
    <fa-icon slot="start" [icon]="faDollarSign"></fa-icon>
    <ion-label>Add Payment</ion-label>
  </ion-item>
  <ion-item button (click)="preApproveApplication()">
    <fa-icon slot="start" [icon]="faCheckCircle"></fa-icon>
    <ion-label>Pre-Approve Application</ion-label>
  </ion-item>
  <ion-item button (click)="acceptApplication()">
    <fa-icon slot="start" [icon]="faCheckCircle"></fa-icon>
    <ion-label>Accept Application</ion-label>
  </ion-item>
  <ion-item button (click)="reserveApplication()">
    <fa-icon slot="start" [icon]="faCheckCircle"></fa-icon>
    <ion-label>Reserve Application</ion-label>
  </ion-item>
  <ion-item button (click)="enrollApplication()">
    <fa-icon slot="start" [icon]="faCheckDouble"></fa-icon>
    <ion-label>Enroll Application</ion-label>
  </ion-item>
  <ion-item button (click)="postponeApplication()">
    <fa-icon slot="start" [icon]="faTimesCircle"></fa-icon>
    <ion-label>Postpone Application</ion-label>
  </ion-item>
  <ion-item button (click)="refundApplication()">
    <fa-icon slot="start" [icon]="faTimesCircle"></fa-icon>
    <ion-label>Refund Application</ion-label>
  </ion-item>
  <ion-item button (click)="rejectApplication()">
    <fa-icon slot="start" [icon]="faTimesCircle"></fa-icon>
    <ion-label>Reject Application</ion-label>
  </ion-item>
  <ion-item button (click)="cancelApplication()">
    <fa-icon slot="start" [icon]="faTimesCircle"></fa-icon>
    <ion-label>Cancel Application</ion-label>
  </ion-item>
  <div class="line"></div>
  <ion-item
    *ngIf="(managerType$ | async) === 'AMOEMPLOYEE'"
    button
    (click)="assignCoach()"
  >
    <ion-label>Assign Coach</ion-label>
  </ion-item>
  <ion-item
    *ngIf="(managerType$ | async) === 'AMOEMPLOYEE'"
    button
    (click)="assignCoordinator()"
  >
    <ion-label>Assign Coordinator</ion-label>
  </ion-item>
  <div class="line"></div>
  <ion-item
    *ngIf="(managerType$ | async) === 'AMOEMPLOYEE'"
    button
    (click)="searchMatchingApps()"
  >
    <!-- <fa-icon slot="start" [icon]="faThumbtack"></fa-icon> -->
    <ion-label>Matching Apps</ion-label>
  </ion-item>
  <ion-item
    *ngIf="(managerType$ | async) === 'AMOEMPLOYEE'"
    button
    (click)="searchMatchingCancelledApps()"
  >
    <!-- <fa-icon slot="start" [icon]="faThumbtack"></fa-icon> -->
    <ion-label>Matching Cancelled Apps</ion-label>
  </ion-item>
  <div class="line"></div>

  <ion-item
    *ngIf="(managerType$ | async) === 'AMOEMPLOYEE'"
    button
    (click)="switchApplication()"
  >
    <fa-icon slot="start" [icon]="faThumbtack"></fa-icon>
    <ion-label>Switch Application</ion-label>
  </ion-item>
  <ion-item
    *ngIf="(managerType$ | async) === 'AMOEMPLOYEE'"
    button
    (click)="swapApplication()"
  >
    <fa-icon slot="start" [icon]="faExchange"></fa-icon>
    <ion-label>Swap Application</ion-label>
  </ion-item>
  <ion-item
    *ngIf="(managerType$ | async) === 'AMOEMPLOYEE'"
    button
    (click)="deleteApplication()"
  >
    <fa-icon slot="start" [icon]="faTrash"></fa-icon>
    <ion-label>Delete Application</ion-label>
  </ion-item>
</ion-list>
