import { Component, Input, OnInit } from "@angular/core";
import {
  faCheckCircle,
  faCheckDouble,
  faTrash,
  faTimesCircle,
  faBan
} from "@fortawesome/pro-regular-svg-icons";
import { NavController, PopoverController } from "@ionic/angular";
import { ApplicationService } from "src/app/core/services/application/application.service";
import { NavigationService } from "src/app/core/services/navigation/navigation.service";
import { Warning } from "../../services";

@Component({
  selector: "app-application-hold-actions",
  templateUrl: "./application-hold-actions.component.html",
  styleUrls: ["./application-hold-actions.component.scss"]
})
export class ApplicationHoldActionsComponent implements OnInit {
  @Input() applicationid: string;

  faCheckCircle = faCheckCircle;
  faCheckDouble = faCheckDouble;
  faTrash = faTrash;
  faTimesCircle = faTimesCircle;
  faBan = faBan;

  constructor(
    private popCtrl: PopoverController,
    private navCtrl: NavController,
    private applicationService: ApplicationService,
    private waring: Warning,
    private navService: NavigationService
  ) {}

  ngOnInit() {}

  processSwitch() {
    this.popCtrl.dismiss();
    this.navCtrl.navigateForward([
      `/new-application/details/${this.applicationid}/process-hold`
    ]);
  }

  cancelSwitch() {
    if (this.applicationid) {
      this.waring
        .confirmation(
          "Are you sure you want to cancel the hold for Application: " +
            this.applicationid
        )
        .then((confirmed) => {
          if (confirmed) {
            this.applicationService
              .cancelApplicationHold(this.applicationid)
              .subscribe((response) => {
                if (response) {
                  location.reload();
                }
              });
          }
        });
    }
  }

  removeSwitch() {
    if (this.applicationid) {
      this.waring
        .confirmation(
          "Are you sure you want to remove the hold for Application: " +
            this.applicationid
        )
        .then((confirmed) => {
          if (confirmed) {
            this.popCtrl.dismiss();
            this.applicationService
              .removeApplicationHold(this.applicationid)
              .subscribe(() => {
                this.navService.main("application.list");
              });
          }
        });
    }
  }
}
