import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { Angulartics2Module } from 'angulartics2';
import { datadogRum } from '@datadog/browser-rum';

// Services
import { FormsService } from './core/services/form/forms.service';
import { GridsService } from './core/services/grids/grids.service';
import { ListsService } from './core/services/lists/lists.service';
import { AuthService } from './core/services/auth/auth.service';
import { UploadService } from './core/services/upload/upload.service';
import { LoadingService } from './core/services/loading/loading.service';
import { HostService } from './core/services/host/host.service';
import { AdminService } from './core/services/admin/admin.service';
import { NavigationService } from './core/services/navigation/navigation.service';
import { PaymentService } from './core/services/payment/payment.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CookieService } from 'ngx-cookie-service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from './shared/angular.material.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SharedComponentsModule } from './shared/components/shared.module';
import { httpInterceptors } from './core/interceptors/index';
import { PipesComponentsModule } from './pipes/pipes.modules';
import { Warning, ModalService } from './shared/services/index';
import { GridComponentsModule } from './core/services/grids/grid-component.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { metaReducers, ROOT_REDUCERS } from './core/ngrx/reducers';
import { QuillModule } from 'ngx-quill';
import { WorkerService } from './core/services/worker/worker.service';
import { GoogleMapsModule } from '@angular/google-maps';

// DevTools
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DocumentComponentsModule } from './modules/document/document.module';
// import { PermissionsPickerComponentsModule } from "./shared/components/permissions-picker.module";
import { SharedDropdownComponentsModule } from './shared/components/shared-dropdown.module';

// list page components
import { FilterActionsDropdownComponent } from './shared/components/filter-actions-dropdown/filter-actions-dropdown.component';
import { FormDirective } from './core/directives/app-accessor/app-accessor.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReloadComponent } from './reload/reload.component';

@NgModule({
  declarations: [AppComponent, FilterActionsDropdownComponent, FormDirective, ReloadComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    HttpClientModule,
    PipesComponentsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    AngularMaterialModule,
    MatTableModule,
    MatSortModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    SharedComponentsModule,
    GridComponentsModule,
    Angulartics2Module.forRoot(),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only
    }),
    DocumentComponentsModule,
    SharedDropdownComponentsModule,
    NgbModule,
    GoogleMapsModule,
  ],
  providers: [
    FormsService,
    GridsService,
    UploadService,
    HostService,
    LoadingService,
    ListsService,
    AdminService,
    httpInterceptors,
    Warning,
    ModalService,
    AuthService,
    // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    CookieService,
    WorkerService,
    NavigationService,
  ],
  exports: [FilterActionsDropdownComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    datadogRum.init({
      applicationId: '7d37c176-3ce8-4d08-807e-d5c7b7c614f7',
      clientToken: 'pub79d96ed4c0103a84c0d0e6320603143c',
      site: 'datadoghq.com',
      service: 'admin.amopportunities.org',
      env: environment.env,
      version: environment.ADMIN_VERSION,
      sampleRate: 100,
      allowedTracingOrigins: ['https://api.amopportunities.org', /https:\/\/.*\-api.amopportunities\.org/],
      trackInteractions: true,
    });
  }
}
