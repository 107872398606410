import { Injectable } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular';
import { SuccessfulActionComponent } from '../components/successful-action/successful-action.component';
import { ListsHelpComponent } from '../components/lists-help/lists-help.component';
import { ApplicationActionsComponent } from '../components/application-actions/application-actions.component';
import { ApplicationPostponeReasonsComponent } from '@shared/components/application-postpone-reasons/application-postpone-reasons.component';
import { InstitutionActionsComponent } from '../components/institution-actions/institution-actions.component';
import { ProgramActionsComponent } from '../components/program-actions/program-actions.component';
import { ImageUploadComponent } from '../components/image-upload/image-upload.component';
import { ApplicationDocumentReviewListComponent } from '../components/application-document-review-list/application-document-review-list.component';
import { DocumentViewerModalComponent } from '../components/document-viewer-modal/document-viewer-modal.component';
import { ChartsModalComponent } from '../components/charts-modal/charts-modal.component';
import { ApplicationHoldActionsComponent } from '../components/application-hold-actions/application-hold-actions.component';
import { AuthUpdateModalComponent } from '@shared/components/auth-update-modal/auth-update-modal.component';
import { AuthPsUpdateModalComponent } from '@shared/components/auth-ps-update-modal/auth-ps-update-modal.component';
import { AuthUpdatePhoneModalComponent } from '@shared/components/auth-update-phone-modal/auth-update-phone-modal.component';
import { AuthVerifyCredsModalComponent } from '@shared/components/auth-verify-creds-modal/auth-verify-creds-modal.component';
import { ApplicationChainActionsComponent } from '@shared/components/application-chain-actions/application-chain-actions.component';
import { RequirementsStatusLegendComponent } from '@shared/components/requirements-status-legend/requirements-status-legend.component';
import { RequirementStatusActionsComponent } from '@shared/components/requirement-status-actions/requirement-status-actions.component';
import { ProvisionalBlockerModalComponent } from '@shared/components/provisional-blocker-modal/provisional-blocker-modal.component';

@Injectable()
export class ModalService {
  constructor(private modalCtrl: ModalController, private popCtrl: PopoverController) {}

  /**
   * *********************  MODALS MODALS MODALS  ****************************
   */

  /**
   * @description present user with successful action modal
   * takes an object with message as the key and a string value
   */
  successfulAction = async (data: any) => {
    const modal = await this.modalCtrl.create({
      component: SuccessfulActionComponent,
      cssClass: data.class,
      componentProps: {
        message: `${data.message}`,
      },
      backdropDismiss: false,
    });

    await modal.present();

    try {
      setTimeout(() => {
        modal.dismiss();
      }, 2500);
    } catch (e) {
      // ignore err happened from user dismissing
    }

    return modal.onDidDismiss();
  };

  /**
   *  @description upload and process uploaded program images
   */
  imageUpload = async (programid: any) => {
    const modal = await this.modalCtrl.create({
      component: ImageUploadComponent,
      componentProps: {
        application: programid,
      },
      backdropDismiss: true,
    });

    await modal.present();
    return modal.onDidDismiss();
  };

  documentViewer = async (providedURL: string | any[], userid?: any, openImmuneList?: boolean, applicationid?: string) => {
    const modal = await this.modalCtrl.create({
      component: DocumentViewerModalComponent,
      componentProps: {
        url: providedURL,
        userid: userid,
        isImmunization: openImmuneList,
        applicationid: applicationid,
      },
      backdropDismiss: true,
      cssClass: Array.isArray(providedURL) == false ? 'document-viewer-modal' : 'multi-document-viewer-modal',
    });

    await modal.present();
    //
    let response = false;
    //
    await modal.onDidDismiss().then(fetch => {
      if (fetch.data) response = fetch.data;
    });
    return response;
  };

  agChartsModal = async (data: any) => {
    const modal = await this.modalCtrl.create({
      component: ChartsModalComponent,
      componentProps: {
        type: data.type,
        chartOptions: data.data,
      },
      backdropDismiss: true,
      cssClass: 'ag-charts-modal',
    });

    await modal.present();
    return modal.onDidDismiss();
  };

  authUpdateModal = async (data: any) => {
    const modal = await this.modalCtrl.create({
      component: data.type === 'phone' ? AuthUpdatePhoneModalComponent : AuthUpdateModalComponent,
      componentProps: {
        type: data.type,
        user: data.user,
      },
      backdropDismiss: false,
      cssClass: data.type === 'phone' ? 'auth-update-phone-modal' : 'auth-update-modal',
    });

    // present da modal
    await modal.present();
    let response = false;
    // return the promise from onDidDismiss()
    await modal.onDidDismiss().then(fetch => {
      if (fetch.data) response = fetch.data;
    });
    return response;
  };

  ApplicationPostponeReasonsModal = async (data: any) => {
    const modal = await this.modalCtrl.create({
      component: ApplicationPostponeReasonsComponent,
      componentProps: {
        reason: data.reason,
        date: data.date,
      },
      backdropDismiss: false,
      cssClass: 'auth-update-modal',
    });

    // appear modlz
    await modal.present();
    let response = false;
    // return data from promise
    await modal.onDidDismiss().then(fetch => {
      if (fetch.data) response = fetch.data;
    });
    return response;
  };

  authPSUpdateModal = async (data: any) => {
    const modal = await this.modalCtrl.create({
      component: AuthPsUpdateModalComponent,
      componentProps: {
        type: data.type,
        user: data.user,
      },
      backdropDismiss: true,
      cssClass: 'auth-ps-update-modal',
    });

    await modal.present();
    return modal.onDidDismiss();
  };

  authConfirmEmailModal = async (data: any) => {
    const modal = await this.modalCtrl.create({
      component: AuthVerifyCredsModalComponent,
      componentProps: {
        email: data.email,
        user: data.user,
        phone: data.phone,
        oldPhone: data.oldPhone,
        type: data.type,
      },
      backdropDismiss: false,
      cssClass: 'auth-update-modal',
    });

    await modal.present();
    let response = false;
    // return the promise from onDidDismiss()
    await modal.onDidDismiss().then(fetch => {
      if (fetch.data) response = fetch.data;
    });
    return response;
  };

  provisionalManagerBlocker = async () => {
    const modal = await this.modalCtrl.create({
      component: ProvisionalBlockerModalComponent,
      backdropDismiss: false,
      cssClass: 'provisional-blocker-modal',
    });

    await modal.present();
  };

  /**
   * *********************   POPOVERS POPOVERS POPOVERS  ****************************
   */

  /**
   *  @description drop-down for grid help button
   */
  listHelp = async (ev: Event) => {
    const popover = await this.popCtrl.create({
      component: ListsHelpComponent,
      event: ev,
      cssClass: 'help-lists',
    });
    return popover.present();
  };

  /**
   *  @description drop-down for host application actions
   */
  applicationActions = async (ev: Event, id: string) => {
    const popover = await this.popCtrl.create({
      component: ApplicationActionsComponent,
      componentProps: {
        applicationid: id,
      },
      event: ev,
      cssClass: 'application-actions-lists',
    });
    popover.present();
    const result = await popover.onDidDismiss();
  };

  applicationChainActions = async (ev: Event, id: string, selectedApplications: string[]) => {
    const popover = await this.popCtrl.create({
      component: ApplicationChainActionsComponent,
      componentProps: {
        applicationchainid: id,
        selectedApplications: selectedApplications,
      },
      event: ev,
      cssClass: 'application-actions-lists',
    });
    //popover.present();

    await popover.present();
    let response = false;
    // return the promise from onDidDismiss()
    await popover.onDidDismiss().then(fetch => {
      if (fetch.data) response = fetch.data;
    });
    return response;
  };

  /**
   *  @description drop-down for program actions
   */
  programActions = async (ev: Event, id: string) => {
    const popover = await this.popCtrl.create({
      component: ProgramActionsComponent,
      componentProps: {
        programid: id,
      },
      event: ev,
      cssClass: 'application-actions-lists',
    });
    return popover.present();
  };

  /**
   *  @description drop-down for host institution actions
   */
  institutionActions = async (ev: Event, id: string, type: string) => {
    const popover = await this.popCtrl.create({
      component: InstitutionActionsComponent,
      componentProps: {
        institutionid: id,
        type: type,
      },
      event: ev,
      cssClass: 'institution-action-lists',
    });
    return popover.present();
  };

  /**
   *  @description drop-down for application document review
   *  @param e click event
   *  @param id application id
   */
  applicationDocumentReview = async (ev: Event, id: string) => {
    const popover = await this.popCtrl.create({
      component: ApplicationDocumentReviewListComponent,
      showBackdrop: false,
      componentProps: {
        applicationid: id,
      },
      event: ev,
      cssClass: 'application-document-list',
    });
    return popover.present();
  };

  /**
   *  @description drop-down for host application actions
   */
  applicationHoldActions = async (ev: Event, id: string) => {
    const popover = await this.popCtrl.create({
      component: ApplicationHoldActionsComponent,
      componentProps: {
        applicationid: id,
      },
      event: ev,
      cssClass: 'application-actions-lists',
    });
    return popover.present();
  };

  /**
   *  @description drop-down for requirements status info
   */
  requirementsStatusLegend = async (ev: Event) => {
    const popover = await this.popCtrl.create({
      component: RequirementsStatusLegendComponent,
      event: ev,
      cssClass: 'requirements-status-list',
      showBackdrop: false,
    });
    popover.present();
    const result = await popover.onDidDismiss();
  };

  /**
   *  @description drop-down for requirements status update
   */
  requirementsStatusActions = async (ev: Event) => {
    const popover = await this.popCtrl.create({
      component: RequirementStatusActionsComponent,
      event: ev,
      cssClass: 'requirements-status-list',
      showBackdrop: false,
    });
    popover.present();
    const result = await popover.onDidDismiss();
    return result;
  };

  private modelPermissionsInfo = {};
  setModelPermissionsInfo(value) {
    this.modelPermissionsInfo = value;
  }
  getModelPermissionsInfo() {
    return this.modelPermissionsInfo;
  }
}
