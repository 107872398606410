import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class HostService {
  constructor(private http: HttpClient) {}

  // AMO Employee ability to create a new host *
  amoCreateHost(hostForm: any) {
    return this.http.post(`${environment.api}/hosts/admincreate`, hostForm);
  }

  // Hosting Managers ability to create a new host *
  hostManagerCreateHost(hostForm: any) {
    return this.http.post(
      `${environment.api}/managements/managerhostinghostadd`,
      hostForm
    );
  }

  // show host details *
  managerhostinghostshow(hostid: string) {
    return this.http.get(
      `${environment.api}/managements/${hostid}/managerhostinghostshow`
    );
  }

  // show host details *
  amohostshow(hostid: string) {
    return this.http.get(`${environment.api}/hosts/${hostid}/show`);
  }

  // update host details with new info *
  managerhostinghostupdate(hostid: string, hostForm: any) {
    return this.http.put(
      `${environment.api}/managements/${hostid}/managerhostinghostupdate`,
      hostForm
    );
  }

  // update host details with new info *
  amohostupdate(hostid: string, hostForm: any) {
    return this.http.put(`${environment.api}/hosts/${hostid}/update`, hostForm);
  }

  // update host details with new info *
  departmenthostlist(departmentid: string) {
    return this.http.get(
      `${environment.api}/departments/${departmentid}/hosts/list`
    );
  }

  // AMO Employee delete a host
  amohostdelete(hostid: string) {
    return this.http.delete(`${environment.api}/hosts/${hostid}/destroy`);
  }

  // Query for hosts using id, name, or email
  hostSelectQuery(body: any): Observable<any> {
    return this.http.post(`${environment.api}/hosts/hostselectquery`, body);
  }

  /**
   * Export the Grid to CSV
   * @param queryObject
   * @param columnHeaders
   */
  exportGridToCsv(queryObject: any, columnHeaders: any): Observable<any> {
    return this.http.post(`${environment.api}/hosts/exporthostscsv`, {
      query: JSON.stringify(queryObject),
      columns: JSON.stringify(columnHeaders)
    });
  }
  
  validateHubspotHost(hostid: string, hubspotcontactid: string): Observable<any> {
    return this.http.post(`${environment.api}/hosts/${hostid}/validateHubspotHost?ngsw-bypass=true`, { hubspotcontactid });
  }
}
// * = switched to cognito auth guard
