import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';
import { State } from '../../ngrx/reducers';
import { AuthActions } from '../../ngrx/actions';
import { Auth } from '@aws-amplify/auth';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoginSubject: any = new BehaviorSubject<boolean>(false);
  isHostTypeSubject: any = new BehaviorSubject<boolean>(this.hasHostToken());
  isRecruitmentTypeSubject: any = new BehaviorSubject<boolean>(this.hasRecruitmentToken());
  // this subject needs to be a string at the start else the dom won't render the manager name
  setMgmtNameSubject: any = new BehaviorSubject<string>('Hosting');

  getManagementName = (): Observable<string> => this.setMgmtNameSubject.asObservable();

  public userCache$: Observable<any>;

  constructor(private http: HttpClient, private cookie: CookieService, private store: Store<State>, private router: Router) {}

  // COGNITO AUTH FUNCTIONS *******************************************************

  /**
   *
   * @param code cognito code in url
   * @param email email in url to activate cognito
   * @param pwd password set by user
   */
  cognitoActivate(code: string, email: string, pwd: string) {
    return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/confirm-signup`, { code, email, pwd });
  }

  /**
   *
   * @param email email to send reset code to user
   */
  cognitoResendCode(email: string) {
    return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/confirm-signup`, { action: 'resend', email });
  }

  /**
   *
   * @param email email of manager to reset password to cognito
   */
  resendForgotPasswordVerificationCode(email: string) {
    return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/forgot-password`, { email: email });
  }

  /**
   *
   * @param data email and mfa code
   */
  cognitoMFA(data: any) {
    return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/verify-mfa-code`, data);
  }

  /**
   *
   * @param data login email and password
   */
  private CognitoLogin(data: object) {
    data['clientId'] = environment.COGNTIO_APP_CLIENT_ID;
    return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/login`, data);
  }

  /**
   *
   * @param data login email and password
   *
   */
  amoCognitoLogin(data: object) {
    return this.CognitoLogin(data);
  }

  cognitoLogout(data: any): Observable<any> {
    return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/logout?r=${this.cookie.get('amoactkn')}`, data);
  }

  /**
   *
   * @param email lookup for user to update password and send email link
   *
   * sends a reset link via email to start update password page
   */
  cognitoForgotPassword(email: string) {
    const clientId = environment.COGNTIO_APP_CLIENT_ID;
    return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/forgot-password`, { email, clientId });
  }

  /**
   *
   * @param email email of user that is having the password changed
   * @param code code for cognito to auth password change
   * @param password updated password
   *
   * Updates password for userl
   */
  cognitoResetPassword(email: string, code: string, password: string) {
    const clientId = environment.COGNTIO_APP_CLIENT_ID;
    return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/change-password`, { email, code, password, clientId });
  }

  // ***********************************************************************

  // get user's details *
  getUser(): Observable<any> {
    return this.http.get(`${environment.api}/users/me`);
  }

  // checks on cache of user and returns the cache
  getUserCache(reset: boolean): Observable<any> {
    if (!this.userCache$ || reset) {
      this.userCache$ = this.getUser();
    }
    return this.userCache$;
  }

  /** returns the behavior subject monitoring is a user is logged in */
  loginUIUpdate(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }

  isHost(): Observable<boolean> {
    return this.isHostTypeSubject.asObservable();
  }

  isRecruitment(): Observable<boolean> {
    return this.isRecruitmentTypeSubject.asObservable();
  }

  createCookie(name: string, token: string) {
    this.cookie.set(name, token, 0, '/', 'amopportunities.org', true, 'Strict');
  }

  resetpasswordrequest(submittedEmail) {
    return this.http.post(`${environment.api}/managements/resetpasswordrequest`, { email: submittedEmail });
  }

  findCookie(name: any) {
    return this.cookie.get(name);
  }

  // view manager's profile details *
  managerhostingshowprofile(): Observable<any> {
    return this.http.get(`${environment.api}/managements/managerhostingshowprofile`);
  }

  // allows to update manager's profile *
  updatemanagerhostingprofileform(managerid: string, profileform: any): Observable<any> {
    return this.http.put(`${environment.api}/managements/${managerid}/updatemanagerhostingprofileform`, profileform);
  }

  /**
   * @param email email of manager
   * @description sets manager as activated in db
   */
  validateManagerActivation(email: string): Observable<any> {
    return this.http.get(`${environment.api}/managements/activate?email=${email}`);
  }

  /**
   * @param email admins email
   * @description sets manager as activated in db
   */
  // validateAdminActivation(email: string): Observable<any> {
  //   return this.http.get(`${environment.api}/managements/adminactivate?email=${email}`);
  // }

  async logout() {
    // remove cookie and clear jwt
    this.cookie.delete('mgmtlogin', '/', 'amopportunities.org');
    this.cookie.delete('amoactkn', '/', 'amopportunities.org');
    this.cookie.delete('mgmt_token', '/', 'amopportunities.org');
    this.cookie.delete('XSRF-TOKEN', '/', 'amopportunities.org');
    this.cookie.delete('amosess.sid', '/', 'amopportunities.org');
    this.cookie.delete('visitorList', '/', 'amopportunities.org');
    this.cookie.delete('host', '/', 'amopportunities.org');
    this.cookie.delete('visitor-recruitment', '/', 'amopportunities.org');
    this.store.dispatch(AuthActions.setLogout({ is_authenticated: false }));
    try {
      await Auth.signOut().then(() => {
        this.router.navigate(['/login']);
      });
    } catch (error) {
      console.log(error);
      this.router.navigate(['/login']);
    }
  }

  async updateCognitoAttr(profile: any) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, {
        name: profile.name,
        phone_number: profile.mobilephone,
      });
      return { status: result, error: null };
    } catch (error) {
      console.log(error);
      return { status: 'Error', error: error };
    }
  }

  private hasToken(): boolean {
    return this.cookie.check('mgmtlogin');
  }

  private hasHostToken(): boolean {
    return this.cookie.check('host');
  }

  private hasRecruitmentToken(): boolean {
    return this.cookie.check('visitor-recruitment');
  }

  identifyAuthenticatedUser(): Observable<any> {
    return this.http.get(`${environment.api}/users/isauthenticated`);
  }

  /**
   *
   * @deprecated
   */
  login2fa(loginData: object): Observable<any> {
    return this.http.post(`${environment.api}/auth/local/validate2fa`, loginData);
  }

  submitActivationForm(managerid: string, hashid: string, activationForm: any) {
    const completeRegisterPayload = {
      id: managerid,
      hashid: hashid,
      password: activationForm.newPassword,
      passwordConfirm: activationForm.newPasswordConfirm,
      name: activationForm.name,
      mobilephone: activationForm.mobilephone,
    };
    return this.http.post(`${environment.api}/managements/${managerid}/register`, completeRegisterPayload);
  }

  // check to see if manager is already in user pool and resend onboarding email.
  confirmActivation(body: object): Observable<any> {
    return this.http.post(`${environment.api}/managements/confirmactivation`, body);
  }

  updateAdminAttributes(body: object): Observable<any> {
    return this.http.post(`${environment.api}/managements/updateauthattributes`, body);
  }

  // update manager to be activated and update name and phone
  activateManager(body: object): Observable<any> {
    return this.http.post(`${environment.api}/managements/activatemanager`, body);
  }

  // find manager to be activated
  findManager(id: string): Observable<any> {
    return this.http.get(`${environment.api}/managements/${id}/findmanager`);
  }

  // requestPasswordReset(email: string, account: string) {
  //   return this.http.get(`${environment.api}/managements/${email}/resetrequest?account=${account}`);
  // }

  // verifyRequestPasswordReset(userid: string, hashid: string) {
  //   return this.http.get(`${environment.api}/managements/resetrequest/youremail/${userid}/${hashid}`);
  // }

  // setNewPassword(userid: string, hashid: string, newPassword: string, newPasswordConfirm: string) {
  //   return this.http.put(`${environment.api}/managements/${hashid}/setpassword`, {userid, newPassword, newPasswordConfirm});
  // }

  // loginManager(user: object): Observable<any> {
  //   return this.http.post(`${environment.api}/auth/local`, user);
  // }

  /**
   *
   * @deprecated
   */
  method2fa(loginData: object): Observable<any> {
    return this.http.post(`${environment.api}/auth/local/method2fa`, loginData);
  }
}

// * = switched to cognito auth guard
