import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { PipesComponentsModule } from 'src/app/pipes/pipes.modules';

import { SharedDropdownComponent } from './shared-dropdown/shared-dropdown.component';
import { ProgramBundleActionsDropdownComponent } from './program-bundle-actions-dropdown/program-bundle-actions-dropdown.component';

ProgramBundleActionsDropdownComponent;
@NgModule({
  declarations: [SharedDropdownComponent, ProgramBundleActionsDropdownComponent],
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule, FontAwesomeModule, PipesComponentsModule],
  exports: [SharedDropdownComponent, ProgramBundleActionsDropdownComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedDropdownComponentsModule {}
