/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { regexTools } from '@shared/utils/regex';

import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { isNil } from 'src/app/shared/utils';

export interface ImanagersCsvImportForm {
  file: FormControl;
  permissions: FormControl;
  key: FormControl;
}
@Injectable({
  providedIn: 'root',
})
export class FormsService {
  public static matchValues(
    matchTo: string, // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value ? null : { isMatching: false };
    };
  }

  // if they input one, must input the other, only constraint of provisional manager clinical pref
  priceRangePairValidator = (control: FormControl) => {
    const priceRange = control.value;

    if (!priceRange) {
      return null;
    }

    if (priceRange.includes('null')) {
      return { priceRangeValidator: false };
    } else {
      // ensure min is less than max
      let [min, max] = priceRange.split('-');
      if (min < max && min > 0 && max > 0) {
        return null;
      }
      return { priceRangeValidator: false };
    }
  };

  fullNameValidator = (control: FormControl) => {
    const fullname = control.value;
    if (fullname && fullname.trim().indexOf(' ') === -1) {
      return {
        fullNameValidator: {
          valid: false,
        },
      };
    } else {
      return null;
    }
  };

  passwordValidator = (control: FormControl) => {
    const password = control.value;
    const validator: any = {};
    // we want no non-english pw so we switch the !

    // handles all regex
    const passwordOptions: any = {
      numberCaseValidation: 'numberCaseRegex',
      upperCaseValidation: 'upperCaseRegex',
      lowerCaseValidation: 'lowerCaseRegex',
      specialCaseValidation: 'specialCaseRegex',
    };

    const conditions = Object.keys(passwordOptions);
    // if its null or if for some reason doesn't go as null but is nothing, add it to error
    for (let i = 0; i <= conditions.length; i++) {
      const regexOption = passwordOptions[conditions[i]];
      if (isNil(password.match(regexTools[regexOption]))) {
        validator[conditions[i]] = { valid: false };
      } else {
        if (password.match(regexTools[regexOption]).length > 0) {
        } else {
          validator[conditions[i]] = { valid: false };
        }
      }

      // length
      if (password.length < 8) {
        validator.minLength = { valid: false };
      }

      if (password.length > 30) {
        validator.maxLength = { valid: false };
      }

      if (password.match(regexTools.nonEnglishRegex)) {
        validator.nonEnglishValidation = { valid: false };
      }
    }

    // : password.match(regexTools.nonEnglishRegex)
    // ? 'nonEnglishValidator'
    // : false;

    if (JSON.stringify(validator) !== '{}') {
      return validator;
    } else {
      return null;
    }
  };

  phoneValidator = (control: FormControl) => {
    const phone = control.value;
    if (!phone.match('^([+]?[0-9-()]{10,})$')) {
      if (phone !== '') {
        return {
          phoneValidator: {
            valid: false,
          },
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  // used for formatting error responses to keys

  //setting as observable so we cannot just change this directly from other forms
  private activeStep$: any = new BehaviorSubject<number>(1);

  get activeStep() {
    return this.activeStep$.asObservable();
  }

  formInputResponses = {
    password: 'Password',
    email: 'Email',
    hostid: 'Host ID',
    physicianemail: 'Host Email',
    physician: 'Host Name',
    name: 'Program Name',
    specialty: 'Program Specialty',
    specialties: 'Program Specialty(s)',
    type: 'Program Type',
    permitted: 'Program Permitted',
    price: 'Program Price',
    deposit: 'Program Deposit',
    dealid: 'Deal ID',
    programlocation: 'Program Location',
    address: 'Program Address',
    city: 'Program City',
    state: 'Program State',
    postalcode: 'Program Postal Code',
    startdate: 'Program Start Date',
    offered: 'Program Seats Offered',
  };

  constructor(private formBuilder: FormBuilder) {}

  // SIGNUP FUNCTIONS *******************************************************

  goToNextStep(activeStep: number) {
    this.activeStep$.next(activeStep + 1);
  }

  goToPreviousStep(activeStep: number) {
    this.activeStep$.next(activeStep - 1);
  }

  setProgramFields: any = new BehaviorSubject<any>({});

  invalidFields = (myForm: FormGroup): any[] => {
    const result: any = [];
    Object.keys(myForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = myForm.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            control: this.formInputResponses[key],
            error: keyError,
            value: controlErrors[keyError],
          });
        });
      }
    });
    return result.filter((r: any) => !isNil(r.control));
  };

  getProgramFields = (): Observable<any> => this.setProgramFields.asObservable();

  unsubscribeForm = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
        newsLetter: new FormControl('', Validators.compose([Validators.required])),
        weeklySuggestions: new FormControl('', Validators.compose([Validators.required])),
        applicationNotifications: new FormControl('', Validators.compose([Validators.required])),
        allSubscriptions: new FormControl('', Validators.compose([Validators.required])),
      }),
    );
  };

  signupForm(): FormGroup {
    return this.formBuilder.group({
      signup: this.formBuilder.group({
        organizationname: new FormControl(null, Validators.compose([Validators.required])),
        contactname: new FormControl(null, Validators.compose([Validators.required, this.fullNameValidator])),
        contactmobilephone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(10), this.phoneValidator])),
        password: new FormControl('', Validators.compose([Validators.required, this.passwordValidator])),
        passwordconfirm: new FormControl('', Validators.compose([Validators.required, FormsService.matchValues('password')])),
        contactemail: new FormControl(null, Validators.compose([Validators.email, Validators.required])),
        title: new FormControl(null),
        contactofficephone: new FormControl('', Validators.compose([Validators.minLength(10), this.phoneValidator])),
        termsagreed: new FormControl(false, Validators.compose([Validators.required, Validators.requiredTrue])),
        updatesagreed: new FormControl(false),
      }),
      clinicalPreferences: this.formBuilder.group({
        pricerange: new FormControl(null),
        pricerangeunit: new FormControl(null),
        studenttype: new FormControl(null),
        neededspecialties: new FormControl(null),
        interestedstates: new FormControl(null),
        prohibitedstates: new FormControl(null),
        requiredinpatientspecialties: new FormControl(null),
      }),
    });
  }

  inviteForm = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        inviteEmail: new FormControl('', Validators.compose([Validators.email, Validators.required])),
        visitorEmail: new FormControl('', Validators.compose([Validators.email, Validators.required])),
      }),
    );
  };

  loginForm = (): Observable<FormGroup> => {
    return of(this.createLoginForm());
  };

  // create the login form
  private createLoginForm = (): FormGroup => {
    return this.formBuilder.group({
      // account: new FormControl(
      //   '',
      //   Validators.compose([Validators.required])
      // ),
      email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
    });
  };

  clinicalPreferencesEdit = () => {
    return this.formBuilder.group({
      pricerange: new FormControl(''),
      pricerangeunit: new FormControl('hour'),
      studenttype: new FormControl(''),
      neededspecialties: new FormControl(''),
      interestedstates: new FormControl(''),
      prohibitedstates: new FormControl(''),
      requiredinpatientspecialties: new FormControl(''),
      requiredhours: new FormControl(''),
      requiredhoursunit: new FormControl('week'),
      rotationduration: new FormControl(''),
      rotationdurationunit: new FormControl('hour'),
    });
  };

  preceptorRequirementEdit = () => {
    return this.formBuilder.group({
      endofrotationevaluation: new FormControl(''),
      proctoredexams: new FormControl(''),
      requiredboardcertified: new FormControl(''),
      requiredpreceptorcv: new FormControl(''),
      additionalinformation: new FormControl(''),
    });
  };

  hostProgramEditForm = () => {
    return this.formBuilder.group({
      id: new FormControl(null, Validators.compose([Validators.required])),
      name: new FormControl(null, Validators.compose([Validators.required])),
      city: new FormControl(null, Validators.compose([Validators.required])),
      state: new FormControl(null, Validators.compose([Validators.required])),
      programtags: new FormControl(null),
      website: new FormControl(null),
      specialty: new FormControl(null, Validators.compose([Validators.required])),
      affiliatedsites: new FormControl(null),
      schedule: new FormControl('{}'),
      internaltags: new FormControl(null),
      specialties: new FormControl(null),
      type: new FormControl(null, Validators.compose([Validators.required])),
      months: new FormControl(null),
      startyear: new FormControl(null),
      offered: new FormControl(null),
      hubspotdealid: new FormControl(null),
      image: new FormControl(),
      programlocation: new FormControl(null),
      live: new FormControl(Validators.compose([Validators.required])),
      featured: new FormControl(),
      price: new FormControl(null),
      pricechange: new FormControl(null, Validators.compose([Validators.required])),
      hourlyprice: new FormControl(null),
      unavailable: new FormControl(null),
      deposit: new FormControl(999),
      address: new FormControl(null),
      geoloc: new FormControl(null),
      physician: new FormControl(null),
      physicianemail: new FormControl(null),
      notes: new FormControl(null),
      activities: new FormControl(null),
      permitted: new FormControl(null),
      lastminute: new FormControl(),
      stipendcost: new FormControl(null),
      contactemail: new FormControl(null),
      pricedropstartdate: new FormControl(null),
      pricedropenddate: new FormControl(null),
      pricedropamount: new FormControl(Number),
      contactname: new FormControl(null),
      phone: new FormControl(null),
      reportingtime: new FormControl(null),
      additionalrequirements: new FormControl(),
      inpatientexposure: new FormControl(),
      faxnumber: new FormControl(null),
      hostid: new FormControl(null, Validators.compose([Validators.required])),
      postalcode: new FormControl(null),
      preapprovalrequired: new FormControl(),
      customavailability: new FormControl(null),
      hidden: new FormControl(),
      content: new FormControl(),
      coordinator: new FormControl(null),
      auditdate: new FormControl(null),
      trellourl: new FormControl(null),
      inpatientexposurenotes: new FormControl(null),
      description: new FormControl(null),
      printabledocuments: new FormControl(null),
      exposuretype: new FormControl(null),
      tags: new FormControl(null),
      deadlinedays: new FormControl(null),
      preapprovalnotes: new FormControl(null),
      coach: new FormControl(null),
      closeddate: new FormControl(null),
      programinternalname: new FormControl(null),
      weekly_hours: new FormControl(null),
    });
  };

  amoEmployeeApplicationEditFrom = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        id: new FormControl('', Validators.compose([Validators.required])),
        name: new FormControl(''),
        email: new FormControl(''),
        altemail: new FormControl(''),
        gender: new FormControl(''),
        status: new FormControl(''),
        usmle: new FormControl(),
        prefmonth: new FormControl(''),
        prefspecialty: new FormControl(''),
        preflocation: new FormControl(''),
        university: new FormControl(),
        country: new FormControl(''),
        countryofbirth: new FormControl(''),
        passportissuecountry: new FormControl(''),
        hasvisa: new FormControl(),
        passportnumber: new FormControl(''),
        passportissuedate: new FormControl(''),
        passportexpiredate: new FormControl(''),
        price: new FormControl(''),
        deposit: new FormControl(''),
        madedeposit: new FormControl(''),
        applicationChainId: new FormControl(''),
        depositid: new FormControl(''),
        madepayment: new FormControl(false),
        fieldofstudy: new FormControl(null),
        paymentid: new FormControl(''),
        userid: new FormControl(''),
        fullname: new FormControl(''),
        coupons: new FormControl([]),
        visitorstatus: new FormControl(''),
        type: new FormControl(''),
        // host: new FormControl(""),
        // hostemail: new FormControl(""),
        states: new FormControl(''),
        // preapprovalrequired: new FormControl(""),
        enrollable: new FormControl(false),
        contracturl: new FormControl(''),
        month: new FormControl(''),
        startyear: new FormControl(''),
        mergedocuments: new FormControl(false),
        startdate: new FormControl(''),
        programlocation: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        specialty: new FormControl(''),
        malpracticepaid: new FormControl(false),
        malpracticeid: new FormControl(''),
        housingaddress: new FormControl(''),
        housingtype: new FormControl(''),
        enddate: new FormControl(''),
        expires: new FormControl(''),
        accepteddate: new FormControl(''),
        signedcontract: new FormControl(''),
        reserveddate: new FormControl(''),
        enrolleddate: new FormControl(''),
        programid: new FormControl('', Validators.compose([Validators.required])),
        visameetingdate: new FormControl(''),
        limbo: new FormControl(false),
        hostid: new FormControl(''),
        hostname: new FormControl(''),
        visastatus: new FormControl(''),
        preacceptancereviewable: new FormControl(false),
        contractapitoken: new FormControl(''),
        availabilityid: new FormControl(''),
        pricechange: new FormControl(0),
        nolatefee: new FormControl(false),
        userdocuments: new FormControl(''),
        couponid: new FormControl(''),
        immigrationservice: new FormControl(''),
        immigrationid: new FormControl(''),
        hostpaid: new FormControl(''),
        visitorevaluation: new FormControl(false),
        hostevaluation: new FormControl(false),
        hashousing: new FormControl(false),
        airbnblink: new FormControl(''),
        hasrequestedinvitation: new FormControl(false),
        applicationtags: new FormControl(''),
        trellourl: new FormControl(''),
        hubspotdealid: new FormControl(''),
        preenrollmentorientation: new FormControl(null),
        predepartureorientation: new FormControl(null),
        studentobservershipinvitation: new FormControl(false),
        studentelectiveinvitation: new FormControl(false),
        graduateobservershipinvitation: new FormControl(false),
        switchprotectionpaid: new FormControl(false),
        switchprotectionid: new FormControl(''),
        migrationdocumentdate: new FormControl(''),
        stripetransfers: new FormControl(null),
        stipendcost: new FormControl(''),
        referredhostpaid: new FormControl(false),
        applicationordertype: new FormControl(''),
        nursing_hours: new FormControl(null),
        hourlyprice: new FormControl(null),
        postponedtoreserveddate: new FormControl(null),
      }),
    );
  };

  applicationChainEditForm = (): FormGroup => {
    return this.formBuilder.group({
      programid: new FormControl(null),
      userid: new FormControl(null),
      applicationid: new FormControl(null),
    });
  };

  applicationEditForm = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        id: new FormControl('', Validators.compose([Validators.required])),
        name: new FormControl('', Validators.compose([Validators.required])),
        email: new FormControl('', Validators.compose([Validators.required])),
        altemail: new FormControl('', Validators.compose([Validators.required])),
        gender: new FormControl('', Validators.compose([Validators.required])),
        status: new FormControl('', Validators.compose([Validators.required])),
        usmle: new FormControl(Validators.compose([Validators.required])),
        prefmonth: new FormControl('', Validators.compose([Validators.required])),
        prefspecialty: new FormControl('', Validators.compose([Validators.required])),
        preflocation: new FormControl('', Validators.compose([Validators.required])),
        university: new FormControl(Validators.compose([Validators.required])),
        country: new FormControl('', Validators.compose([Validators.required])),
        countryofbirth: new FormControl('', Validators.compose([Validators.required])),
        passportissuecountry: new FormControl('', Validators.compose([Validators.required])),
        hasvisa: new FormControl(Validators.compose([Validators.required])),
        passportnumber: new FormControl('', Validators.compose([Validators.required])),
        passportissuedate: new FormControl('', Validators.compose([Validators.required])),
        passportexpiredate: new FormControl('', Validators.compose([Validators.required])),
        price: new FormControl('', Validators.compose([Validators.required])),
        deposit: new FormControl('', Validators.compose([Validators.required])),
        madedeposit: new FormControl(false, Validators.compose([Validators.required])),

        depositid: new FormControl('', Validators.compose([Validators.required])),
        madepayment: new FormControl('', Validators.compose([Validators.required])),
        fieldofstudy: new FormControl(null, Validators.compose([Validators.required])),
        paymentid: new FormControl('', Validators.compose([Validators.required])),
        userid: new FormControl('', Validators.compose([Validators.required])),
        fullname: new FormControl('', Validators.compose([Validators.required])),
        visitorstatus: new FormControl('', Validators.compose([Validators.required])),
        type: new FormControl('', Validators.compose([Validators.required])),
        physician: new FormControl('', Validators.compose([Validators.required])),
        physicianemail: new FormControl('', Validators.compose([Validators.required])),
        states: new FormControl('', Validators.compose([Validators.required])),
        preapprovalrequired: new FormControl(false, Validators.compose([Validators.required])),
        enrollable: new FormControl(Validators.compose([Validators.required])),
        contracturl: new FormControl(Validators.compose([Validators.required])),
        month: new FormControl('', Validators.compose([Validators.required])),
        startyear: new FormControl('', Validators.compose([Validators.required])),
        startdate: new FormControl('', Validators.compose([Validators.required])),
        programlocation: new FormControl('', Validators.compose([Validators.required])),
        city: new FormControl('', Validators.compose([Validators.required])),
        state: new FormControl('', Validators.compose([Validators.required])),
        specialty: new FormControl('', Validators.compose([Validators.required])),
        malpracticepaid: new FormControl('', Validators.compose([Validators.required])),
        malpracticeid: new FormControl('', Validators.compose([Validators.required])),
        housingaddress: new FormControl('', Validators.compose([Validators.required])),
        housingtype: new FormControl('', Validators.compose([Validators.required])),
        enddate: new FormControl('', Validators.compose([Validators.required])),
        expires: new FormControl('', Validators.compose([Validators.required])),
        accepteddate: new FormControl('', Validators.compose([Validators.required])),
        signedcontract: new FormControl('', Validators.compose([Validators.required])),
        reserveddate: new FormControl('', Validators.compose([Validators.required])),
        enrolleddate: new FormControl('', Validators.compose([Validators.required])),
        programid: new FormControl('', Validators.compose([Validators.required])),
        visameetingdate: new FormControl('', Validators.compose([Validators.required])),
        switchprotectionpaid: new FormControl(false),
      }),
    );
  };

  hostEditForm = () => {
    return this.formBuilder.group({
      id: new FormControl('', Validators.compose([Validators.required])),
      name: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
      activated: new FormControl(false),
      address: new FormControl(''),
      altphone: new FormControl(''),
      aquisition: new FormControl(''),
      birthday: new FormControl(''),
      cellphone: new FormControl(''),
      city: new FormControl(''),
      clinicaddress: new FormControl(''),
      cliniccity: new FormControl(''),
      clinicfaxnumber: new FormControl(''),
      clinicinfo: new FormControl(''),
      clinicname: new FormControl(''),
      clinicphone: new FormControl(''),
      clinicphoneext: new FormControl(''),
      clinicpostalcode: new FormControl(''),
      clinicstate: new FormControl(''),
      clinicwebsite: new FormControl(''),
      coach: new FormControl(''),
      contactemail: new FormControl(''),
      contactname: new FormControl(''),
      contactphone: new FormControl(''),
      contactphoneext: new FormControl(''),
      coordinator: new FormControl(''),
      departmentid: new FormControl(''),
      experiencetypes: new FormControl([]),
      faxnumber: new FormControl(''),
      gender: new FormControl(''),
      internalnotes: new FormControl(''),
      internaltags: new FormControl(''),
      isprofessor: new FormControl(false),
      offersinpatient: new FormControl(false),
      optout: new FormControl(false),
      phone: new FormControl(''),
      postalcode: new FormControl(''),
      specialty: new FormControl([]),
      state: new FormControl(''),
      statement: new FormControl(''),
      subcribed: new FormControl(false),
      subspecialties: new FormControl(''),
      unavailable: new FormControl(''),
      updatedAt: new FormControl(''),
      weeklyhours: new FormControl(''),
      workphone: new FormControl(''),
      workphoneext: new FormControl(''),
      resumecv: new FormControl(''),
      proofoflicensure: new FormControl(''),
      proofofboardcertification: new FormControl(''),
      npi: new FormControl(''),
      degreetype: new FormControl(''),
      // licensedstates: new FormControl([]),
      // licenseexpiration: new FormControl(""),
      licenses: new FormArray([]),
      boardcertifications: new FormControl([]),
      specialties: new FormControl([]),
      hubspotcontactid: new FormControl(null, Validators.compose([Validators.required])),
      referredhostid: new FormControl(''),
      emaildigestsetting: new FormControl(''),
      stipendcost: new FormControl(0),
      offered: new FormControl(null),
      usehostavailability: new FormControl(false),
    });
  };

  userProfileEditForm = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        visameetingdate: new FormControl('', Validators.compose([Validators.required])),
      }),
    );
  };
  getUSAStatesArray = (): string[] => {
    return [
      'Alaska',
      'Alabama',
      'Arkansas',
      'American Samoa',
      'Arizona',
      'California',
      'Colorado',
      'Connecticut',
      'District of Columbia',
      'Delaware',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Iowa',
      'Idaho',
      'Illinois',
      'Indiana',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Massachusetts',
      'Maryland',
      'Maine',
      'Michigan',
      'Minnesota',
      'Missouri',
      'Mississippi',
      'Montana',
      'North Carolina',
      'North Dakota',
      'Nebraska',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'Nevada',
      'New York',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Virginia',
      'Virgin Islands',
      'Vermont',
      'Washington',
      'Wisconsin',
      'West Virginia',
      'Wyoming',
    ];
  };

  amoEmployeeApplicationCreateFrom = (price: number | undefined = undefined): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        price: new FormControl(price),

        duration: new FormControl(null),

        userid: new FormControl(null, Validators.compose([Validators.required])),

        type: new FormControl(null, Validators.compose([Validators.required])),

        startdate: new FormControl(null, Validators.compose([Validators.required])),

        programid: new FormControl(null, Validators.compose([Validators.required])),

        applicationtags: new FormControl(null),
        customstartdate: new FormControl(null),
        applicationordertype: new FormControl('B2C'),
      }),
    );
  };

  amoTraineeCreateFrom = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        email: new FormControl(null, Validators.compose([Validators.required])),
        name: new FormControl(null, Validators.compose([Validators.required])),
        status: new FormControl(null),

        university: new FormControl(null),

        departmentid: new FormControl(null),
        hasvisa: new FormControl(true),
      }),
    );
  };

  amoEmployeeApplicationEligibilityDisplayFrom = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        contracturl: new FormControl(''),

        contractdate: new FormControl(''),

        contractapitoken: new FormControl(''),
      }),
    );
  };

  amoEmployeeApplicationCreateHoldFrom = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        expires: new FormControl(''),

        userid: new FormControl('', Validators.compose([Validators.required])),

        type: new FormControl('', Validators.compose([Validators.required])),

        startdate: new FormControl('', Validators.compose([Validators.required])),

        programid: new FormControl('', Validators.compose([Validators.required])),

        price: new FormControl(''),

        notes: new FormControl('', Validators.compose([Validators.required])),
        customstartdate: new FormControl(''),
        applicationordertype: new FormControl('B2C'),
      }),
    );
  };

  amoEmployeeApplicationProcessHoldFrom = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        type: new FormControl('', Validators.compose([Validators.required])),

        applicationid: new FormControl('', Validators.compose([Validators.required])),

        holdid: new FormControl('', Validators.compose([Validators.required])),

        notifyhost: new FormControl(true),
        assumenewprice: new FormControl(false),
        contracturl: new FormControl(''),
      }),
    );
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to create new manager
   */
  amoNewManagerForm = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        name: new FormControl('', Validators.compose([Validators.required])),
        email: new FormControl('', Validators.compose([Validators.required])),
        phone: new FormControl(null, Validators.compose([Validators.required])),
        permissions: new FormControl('', Validators.compose([Validators.required])),
        hubspotcontactid: new FormControl('', Validators.compose([Validators.required])),
      }),
    );
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to edit a manager
   */
  amoManagerForm = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        id: new FormControl('', Validators.compose([Validators.required])),
        activated: new FormControl(false),
        name: new FormControl('', Validators.compose([Validators.required])),
        email: new FormControl('', Validators.compose([Validators.required])),
        phone: new FormControl(''),
        mobilephone: new FormControl(''),
        type: new FormControl(null, Validators.compose([Validators.required])),
        address: new FormControl(''),
        hubspotcontactid: new FormControl('', Validators.compose([Validators.required])),
        departmentid: new FormControl(''),
        invitecode: new FormControl(''),
        emaildigestsetting: new FormControl(null),
        stripe_connect_status: new FormControl(null),
        stripeconnectid: new FormControl(null),
        hostid: new FormControl(null),
      }),
    );
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to edit program bundle
   */
  programBundleForm = (): FormGroup => {
    return this.formBuilder.group({
      id: new FormControl('', Validators.compose([Validators.required])),
      live: new FormControl(false),
      hidden: new FormControl(false),
      title: new FormControl('', Validators.compose([Validators.required])),
      images: new FormControl([]),
      bundlediscount: new FormControl([]),
      description: new FormControl('', Validators.compose([Validators.required])),
      programs: new FormControl([]),
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to create new program bundle
   */
  createProgramBundleForm = (): FormGroup => {
    return this.formBuilder.group({
      title: new FormControl('', Validators.compose([Validators.required])),
      bundlediscount: new FormControl([]),
      description: new FormControl('', Validators.compose([Validators.required])),
      programs: new FormControl([], Validators.compose([Validators.required, Validators.minLength(1)])),
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to create new institute
   */
  amoNewInstituteForm = () => {
    // account: $scope.department.account
    //         , urlslug: $scope.department.urlslug
    //         , name: $scope.department.name
    //         , email: $scope.department.email
    //         , phone: $scope.department.phone
    //         , type: $scope.department.type
    //         , address: $scope.department.address
    //         , amoservices: $scope.department.amoservices
    //         , nocrm: $scope.department.nocrm

    return this.formBuilder.group({
      account: new FormControl(null, Validators.compose([Validators.required])),
      name: new FormControl(null, Validators.compose([Validators.required])),
      email: new FormControl(null),
      phone: new FormControl(null),
      type: new FormControl(null, Validators.compose([Validators.required])),
      amoservices: new FormControl(null, Validators.compose([Validators.required])),
      nocrm: new FormControl(false),
      notes: new FormControl(null),
      urlslug: new FormControl(null, Validators.compose([Validators.required])),
      address: new FormControl(null),
      city: new FormControl(null),
      state: new FormControl(null),
      postalcode: new FormControl(null),
      hubspotcompanyid: new FormControl(null, Validators.compose([Validators.required])),
      stripe_connect_status: new FormControl(null),
      splitpayments: new FormControl(false),
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to create new institute
   */
  amoNewPaymentForm(applicationid: string = '', userId: string = ''): Observable<FormGroup> {
    return of(
      this.formBuilder.group({
        applicationid: new FormControl(applicationid, Validators.compose([Validators.required])),
        userid: new FormControl(userId, Validators.compose([Validators.required])),
        total: new FormControl('', Validators.compose([Validators.required])),
        type: new FormControl('', Validators.compose([Validators.required])),
        states: new FormControl('', Validators.compose([Validators.required])),
        payment_received_date: new FormControl(null),
        payment_created_date: new FormControl(null),
        ispayment: new FormControl(false),
        isdeposit: new FormControl(false),
        isfullpayment: new FormControl(false),
        isswitchprotection: new FormControl(false),
        studentmalpractice: new FormControl(false),
        immigrationservice: new FormControl(false),
        isvoucher: new FormControl(false),
        payer_id: new FormControl('', Validators.compose([Validators.required])),
        depositid: new FormControl(null),
        paymentid: new FormControl(null),
        wire: new FormControl(null),
        advisors: new FormControl(null),
        name: new FormControl(null),
        email: new FormControl(null),
      }),
    );
  }

  /**
   *
   * @returns FormGroup
   * @description get the form to create new program bundle
   */
  requirementsCreateForm = (): FormGroup => {
    return this.formBuilder.group({
      // enabled: new FormControl(""),
      usersubmission: new FormControl(false, Validators.compose([Validators.required])),
      name: new FormControl('', Validators.compose([Validators.required])),
      // preapproval: new FormControl(""),
      // postreservation: new FormControl(""), //clubed in wireframe as dropdown
      preapproval_postreservation: new FormControl('', Validators.compose([Validators.required])),

      // not connected to formcontrol

      // programtype: new FormControl([], Validators.compose([Validators.required])),
      // academicstatus: new FormControl([], Validators.compose([Validators.required])),

      datatype: new FormControl(''), //document selector
      // document: new FormControl(""),//mandate if datatype not none

      type: new FormControl('', Validators.compose([Validators.required])), //resume

      level: new FormControl('none'), //amo cmp
      // levelIdSelections: new FormControl(""),//mandate if level not ""

      // description: new FormControl(
      //   "",
      //   Validators.compose([Validators.required])
      // ),
      // internalnotes: new FormControl("")
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to create new / update coupon
   */
  CouponsCreateForm = (): FormGroup => {
    return this.formBuilder.group({
      type: new FormControl('', Validators.compose([Validators.required])),
      discount: new FormControl('', Validators.compose([Validators.required, Validators.min(0)])),
      code: new FormControl(''),
      expires: new FormControl(''),
      limit: new FormControl('', Validators.min(0)),
      userId: new FormControl(''),
      programId: new FormControl(''),
      inviteId: new FormControl(''),
      departmentId: new FormControl(''),
      notes: new FormControl(''),
      usedby: new FormControl(''), //additional ctrls for edit
      affiliate: new FormControl(''), //additional ctrls for edit
      programTypes: new FormControl(''),
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to edit an institute
   */
  InstitutionEditForm = () => {
    return this.formBuilder.group({
      id: new FormControl(null, Validators.compose([Validators.required])),
      account: new FormControl(null, Validators.compose([Validators.required])),
      name: new FormControl(null, Validators.compose([Validators.required])),
      // email: new FormControl(
      //   "",
      //   Validators.compose([Validators.required, Validators.email])
      // ),
      // phone: new FormControl(null, Validators.compose([Validators.required])),
      activated: new FormControl(false),
      isexclusivemarket: new FormControl(false),
      email: new FormControl(null),
      phone: new FormControl(null),
      permissions: new FormControl(null),
      discount: new FormControl(null),
      commission: new FormControl(null),
      altphone: new FormControl(null),
      stripeaccountstatus: new FormControl(null),
      stipendcost: new FormControl(null),
      coach: new FormControl(null),
      coordinator: new FormControl(null),
      stripeconnectid: new FormControl(null),
      invitecode: new FormControl(null),
      type: new FormControl(null, Validators.compose([Validators.required])),
      amoservices: new FormControl(null, Validators.compose([Validators.required])),
      nocrm: new FormControl(false),
      notes: new FormControl(null),
      urlslug: new FormControl(null, Validators.compose([Validators.required])),
      // address: new FormControl("", Validators.compose([Validators.required])),
      address: new FormControl(null),
      city: new FormControl(null),
      state: new FormControl(null),
      postalcode: new FormControl(null),
      hubspotcompanyid: new FormControl(null),
      stripe_connect_status: new FormControl(null),
      splitpayments: new FormControl(false),
    });
  };

  programCreateForm = (): FormGroup => {
    return this.formBuilder.group({
      hostid: new FormControl(null, Validators.compose([Validators.required])),
      hubspotdealid: new FormControl(null, Validators.compose([Validators.required])),
      name: new FormControl(null, Validators.compose([Validators.required])),
      specialty: new FormControl(null, Validators.compose([Validators.required])),
      specialties: new FormControl(null),
      type: new FormControl(null),
      permitted: new FormControl(null),
      price: new FormControl(null),
      deposit: new FormControl(999),
      city: new FormControl('', Validators.compose([Validators.required])),
      state: new FormControl(null, Validators.compose([Validators.required])),
      offered: new FormControl(1),
      months: new FormControl('January,February,March,April,May,June,July,August,September,October,November,December'),
      live: new FormControl(false),
      featured: new FormControl(false),
      lastminute: new FormControl(false),
      inpatientexposure: new FormControl(false),
      preapprovalrequired: new FormControl(false),
      pricechange: new FormControl(200),
      notes: new FormControl(null),
      exposuretype: new FormControl(null),
    });
  };

  hostProgramCreateForm = (): FormGroup => {
    return this.formBuilder.group({
      hostid: new FormControl(null, Validators.compose([Validators.required])),
      hubspotdealid: new FormControl(null),
      name: new FormControl(null, Validators.compose([Validators.required])),
      specialty: new FormControl(null, Validators.compose([Validators.required])),
      specialties: new FormControl(null),
      type: new FormControl(null),
      permitted: new FormControl(null),
      price: new FormControl(null),
      deposit: new FormControl(999),
      city: new FormControl('', Validators.compose([Validators.required])),
      state: new FormControl(null, Validators.compose([Validators.required])),
      offered: new FormControl(1),
      months: new FormControl('January,February,March,April,May,June,July,August,September,October,November,December'),
      live: new FormControl(false),
      featured: new FormControl(false),
      lastminute: new FormControl(false),
      inpatientexposure: new FormControl(false),
      preapprovalrequired: new FormControl(false),
      pricechange: new FormControl(200),
      notes: new FormControl(null),
      exposuretype: new FormControl(null),
    });
  };
  /**
   *
   * @returns FormGroup
   * @description get the form to shared add coupon
   */
  AddCouponsSharedForm = (): FormGroup => {
    return this.formBuilder.group({
      type: new FormControl('', Validators.compose([Validators.required])),
      discount: new FormControl('', Validators.compose([Validators.required, Validators.min(0)])),
      code: new FormControl(''),
      expires: new FormControl(''),
      limit: new FormControl('', Validators.min(0)),
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to create new review
   */
  ReviewCreateForm = (): FormGroup => {
    return this.formBuilder.group({
      live: new FormControl(false),

      type: new FormControl('', Validators.compose([Validators.required])),
      template: new FormControl('', Validators.compose([Validators.required])),

      name: new FormControl('', Validators.compose([Validators.required])),
      userid: new FormControl('', Validators.compose([Validators.required])),

      rating: new FormControl('', [Validators.required, Validators.min(1), Validators.max(5)]), //Validators.min(0)),
      satisfaction: new FormControl('', [Validators.required, Validators.min(1), Validators.max(5)]), //Validators.min(0)),

      applicationid: new FormControl('', Validators.compose([Validators.required])),
      programid: new FormControl('', Validators.compose([Validators.required])),

      programlocation: new FormControl(''),

      orientation: new FormControl(false),
      recommend: new FormControl(false),
      usercontent: new FormControl(false),
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to edit review with additionl ctrls
   */
  ReviewEditForm = (): FormGroup => {
    return this.formBuilder.group({
      live: new FormControl(false),

      type: new FormControl('', Validators.compose([Validators.required])),
      //template: new FormControl(""), not required

      date: new FormControl(''),
      //rotationStartDate: //not required

      name: new FormControl('', Validators.compose([Validators.required])),
      userid: new FormControl('', Validators.compose([Validators.required])),

      rating: new FormControl('', [Validators.required, Validators.min(1), Validators.max(5)]), //Validators.min(0)),
      satisfaction: new FormControl('', [Validators.required, Validators.min(1), Validators.max(5)]), //Validators.min(0)),

      applicationid: new FormControl('', Validators.compose([Validators.required])),
      programid: new FormControl('', Validators.compose([Validators.required])),

      programlocation: new FormControl(''),

      orientation: new FormControl(false), //featured, but not in review-edit jade
      recommend: new FormControl(false),
      usercontent: new FormControl(false),

      hostname: new FormControl(''),
    });
  };

  managersCsvImportForm = (): Observable<FormGroup> => {
    return of(
      this.formBuilder.group({
        permissions: new FormControl('', Validators.compose([Validators.required])),
        key: new FormControl('', Validators.compose([Validators.required])),
      }),
    );
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to create new review template
   */
  ReviewTemplateCreateForm = (): FormGroup => {
    return this.formBuilder.group({
      title: new FormControl(null, Validators.compose([Validators.required])),
      type: new FormControl(null, Validators.compose([Validators.required])),
      level: new FormControl(null, Validators.compose([Validators.required])),
      departmentid: new FormControl(null), // Validators.compose([Validators.required])),
      hostid: new FormControl(null), //Validators.compose([Validators.required])),
      programid: new FormControl(null), //Validators.compose([Validators.required])),
      programtype: new FormControl(null),
      includedefault: new FormControl(true),
      includedepartment: new FormControl(true),
      includehost: new FormControl(true),
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to edit review template
   */
  ReviewTemplateEditForm = (): FormGroup => {
    return this.formBuilder.group({
      id: new FormControl(null, Validators.compose([Validators.required])),
      title: new FormControl(null, Validators.compose([Validators.required])),
      type: new FormControl(null, Validators.compose([Validators.required])),
      level: new FormControl(null, Validators.compose([Validators.required])),
      departmentid: new FormControl(null), // Validators.compose([Validators.required])),
      hostid: new FormControl(null), //Validators.compose([Validators.required])),
      programid: new FormControl(null), //Validators.compose([Validators.required])),
      programtype: new FormControl(null),
      includedefault: new FormControl(false),
      defaultid: new FormControl(null),
      includedepartment: new FormControl(false),
      includehost: new FormControl(false),
      questions: new FormControl(null),
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to create new Field Of Study
   */
  FieldOfStudyCreateForm = (): FormGroup => {
    return this.formBuilder.group({
      name: new FormControl(null, Validators.compose([Validators.required])),
      description: new FormControl(null),
      graduatetype: new FormControl(null),
      studenttype: new FormControl(null),
      hasdepartments: new FormControl(false),
      allowprofileblock: new FormControl(false),
      addusonly: new FormControl(false),
      institutionid: new FormControl(null), //("", Validators.compose([Validators.required])),
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to create new Field Of Study
   */
  FieldOfStudyEditForm = (): FormGroup => {
    return this.formBuilder.group({
      id: new FormControl(null, Validators.compose([Validators.required])),
      name: new FormControl(null, Validators.compose([Validators.required])),
      description: new FormControl(null),
      graduatetype: new FormControl(null),
      studenttype: new FormControl(null),
      hasdepartments: new FormControl(false),
      allowprofileblock: new FormControl(false),
      addusonly: new FormControl(false),
      institutionid: new FormControl(null), //("", Validators.compose([Validators.required])),
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to create new Application Order Type
   */
  createApplicationOrderTypeForm = (): FormGroup => {
    return this.formBuilder.group({
      type: new FormControl(null, Validators.compose([Validators.required])),
      relationship: new FormControl(null, Validators.compose([Validators.required])),
      notes: new FormControl(null),
      iscustom: new FormControl(false),
      departmentid: new FormControl(null),
    });
  };

  /**
   *
   * @returns FormGroup
   * @description get the form to create new Application Order Type
   */
  applicationOrderTypeEditForm = (): FormGroup => {
    return this.formBuilder.group({
      id: new FormControl(null, Validators.compose([Validators.required])),
      type: new FormControl(null, Validators.compose([Validators.required])),
      relationship: new FormControl(null, Validators.compose([Validators.required])),
      notes: new FormControl(null),
      iscustom: new FormControl(false),
      departmentid: new FormControl(null),
    });
  };
}
