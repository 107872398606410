import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, forkJoin, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { flatMap, map, shareReplay, tap } from 'rxjs/operators';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  private text$: any;

  private visitorsSubject = new BehaviorSubject([]);
  private programsSubject = new BehaviorSubject([]);

  // get list of visitors
  getVisitorsList = this.visitorsSubject.asObservable();

  // get list of selected programs
  getProgramList = this.programsSubject.asObservable();

  private hostSaveClinic = new BehaviorSubject(false);
  // trigger host save function from department page
  getHostSaveClinic = this.hostSaveClinic.asObservable();

  constructor(private http: HttpClient, private toastController: ToastController, private loadingController: LoadingController) {}

  get content(): Observable<any> {
    if (!this.text$) {
      this.text$ = this.requestAllContent().pipe(shareReplay(1));
    }
    return this.text$;
  }

  private requestAllContent(): Observable<any> {
    const contents = this.http.get(`${environment.api}/programs/content`);
    const text = this.http.get('./assets/content/text.json');
    return forkJoin(contents, text).pipe(
      map((res: any) => {
        const data = { ...res[1], ...res[0] };
        return data;
      }),
    );
  }

  // verifies jwt token with visitor's data to use with creating new application in visitor app *
  processVisitorsURL(visitors: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/processvisitordata`, {
      data: visitors,
    });
  }

  // creates jwt token with visitor's data to use with creating new application in visitor app *
  hostProcessVisitorURL(visitors: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/hostingprocessvisitordata`, { data: visitors });
  }

  // create list of visitors for applications
  setVisitorList(event) {
    return this.visitorsSubject.next(event);
  }

  // create list of programs
  setProgramList(event) {
    return this.programsSubject.next(event);
  }

  /**
   *
   * @param event boolean value of emitted action
   * @returns Observable boolean
   */
  setHostSaveClinic(event) {
    return this.hostSaveClinic.next(event);
  }

  // send email invites to visitor *
  sendvisitorinvites(invites: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/sendvisitorinvites`, invites);
  }

  // manager's ability to set application to accpet status *
  acceptApplication(id: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/application/${id.id}/accept`, id);
  }

  // manager's ability to set application to preapproved status *
  preApproveApplication(applicationid: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/application/${applicationid.id}/preapprove`, applicationid);
  }

  // manager's ability to set application to enroll status *
  enrollApplication(applicationid: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/application/${applicationid.id}/enroll`, applicationid);
  }

  // manager's ability to set application to refund status *
  refundApplication(applicationid: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/application/${applicationid.id}/refund`, applicationid);
  }

  // manager's ability to set application to refund status *
  postponeApplication(application: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/application/${application.id}/postpone`, application);
  }

  // manager's ability to set application to cancel status *
  cancelApplication(applicationid: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/application/${applicationid.id}/cancel`, applicationid);
  }

  // manager's ability to set application to reject status *
  rejectApplication(applicationid: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/application/${applicationid.id}/reject`, applicationid);
  }

  // manager's ability to set application to reserve status *
  reserveApplication(application: any): Observable<any> {
    return this.http.post(`${environment.api}/managements/application/${application.id}/reserve`, application);
  }

  // update application with new info *
  managerHostingUpdateApplication = (application: any) => {
    return this.http.put(`${environment.api}/managements/${application.id}/managerhostingapplicationupdate/`, application);
  };

  saveAgGrid = (grid: any, gridType: string) => {
    return this.http.put(`${environment.api}/managements/savegrid`, {
      outgoingGrid: grid,
      type: gridType,
    });
  };

  saveSettings = (settingsForm: any) => {
    return this.http.put(`${environment.api}/managements/savesettings`, {
      form: settingsForm,
    });
  };

  // Auto Complete for Application Requirements
  searchApplicationRequirements(submitted: string): Observable<any> {
    const url = `${environment.api}/applicationrequirements/applicationrequirementselectquery`;
    return this.http
      .post<any>(url, {
        query: submitted,
      })
      .pipe(
        map((res: any) => {
          return res.selectionOptionArray;
        }),
      );
  }

  applicationrequirementselectquery(query: any): Observable<any> {
    return this.http.post(`${environment.api}/applicationrequirements/applicationrequirementselectquery`, query);
  }

  /**
   * @description Auto Complete for Coupon Codes
   * @param submitted query to search by coupon code
   * @returns string
   */
  searchCouponCodes(submitted: string): Observable<any> {
    const url = `${environment.api}/coupons/couponselectquery`;
    return this.http
      .post<any>(url, {
        query: submitted,
      })
      .pipe(
        map((res: any) => {
          const optionItems = res.list.map(coupon => {
            return { optionText: coupon.code, optionValue: coupon.code };
          });

          const stringifyList = JSON.stringify({ optionItems });

          return stringifyList;
        }),
      );
  }

  /**
   * @description returns coupon object from db by coupon code
   * @param submitted query complete coupon by query
   * @returns object
   */
  couponSingleSelect(submitted: string): Observable<any> {
    return this.http.post(`${environment.api}/coupons/couponsingleselect`, {
      query: submitted,
    });
  }

  //update add coupon to ptogram edit
  updateCoupon(data: any): Observable<any> {
    return this.http.put(`${environment.api}/coupons/${data.id}/update`, data);
  }

  /**
   * @description returns board cert list
   */
  boardcertificationSelect(): Observable<any> {
    return this.http.get(`${environment.api}/hosts/boardcertificationselections`);
  }

  duplicateProgram(programId: any): Observable<any> {
    console.log(programId);
    return this.http.get(`${environment.api}/programs/${programId}/adminduplicateprogram`);
  }

  /**
   *
   * @param message - toast message, default "Success"
   * @param color - toast color, default "primary"
   * @param duration - toast duration, default 10s "10000"
   */
  async createAmoToast(message?: string, color?: string, duration?: number): Promise<void> {
    // define toast element
    let toast: HTMLIonToastElement;
    // new toast
    try {
      // create toast
      toast = await this.toastController.create({
        message: message || 'Success!',
        color: color || 'primary',
        buttons: [
          {
            text: 'Close',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            },
          },
        ],
        position: 'top',
        duration: duration || 10000,
      });
      // display toast
      //
    } catch (e) {
      console.log(e);
      toast = await this.toastController.create({
        message: 'Oops!',
        color: 'danger',
        buttons: [
          {
            text: 'Close',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            },
          },
        ],
        position: 'top',
        duration: duration || 10000,
      });
      // display toast
    }
    // present toast
    await toast.present();
    // return not required, fnc marked as promise void
  }

  /**
   *
   * @param duration
   * @param message
   * @description simple loading pause to force wait default 4 seconds and message Loading
   */
  async createAmoPause(duration?: number, message?: string): Promise<void> {
    let loader: HTMLIonLoadingElement;
    //
    try {
      // create loader
      loader = await this.loadingController.create({
        duration: 4000,
        message: 'Loading',
      });
      // loader present
      await loader.present();
    } catch (err) {
      console.log(err);
    }
  }
}

// * = switched to cognito auth guard
